.single-tour {

	h1, h2 {
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 20px;
	}

	.tour-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-top: 100px;
		margin-bottom: 50px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			margin-top: 40px;
		}

		p {
			margin-bottom: 5px;
		}

		.tour-sponsor {
			margin-bottom: 30px;
		}

		.details {
			flex-basis: 55%;
		}

		.location {
			padding-left: 35px;
			position: relative;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 25px;
				height: 25px;
			}
		}

		.neighbourhood {
			font-size: 24px;
			font-weight: 500;
			line-height: 34px;
		}

		.tour-link {
			background: #F4F4F4;
			text-align: center;
			width: 100%;
			padding: 35px 40px 50px 40px;
			border-top: 5px solid black;
			flex-basis: 40%;

			@include media-breakpoint-down(md) {
				margin-top: 45px;
			}

			a {
				margin: 0 5px;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		strong {
			font-family: $headings-font-family;
			font-size: 21px;
		}
	}

	.about-tour {
		display: flex;
		margin-bottom: 60px;
		align-items: center;
		justify-content: space-between;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			margin-bottom: 30px;
		}

		> div {
			flex-basis: 50%;
			flex-shrink: 1;

			img {
				max-width: 100%;
			}
		}

		.tour-description {
			padding: 40px;
			@include media-breakpoint-down(md) {
				padding: 20px 10px;
			}
		}
	}

	.social-share {
		margin-top: 50px;
		border-top: 4px solid black;
		padding-top: 40px;
	}
}