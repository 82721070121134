.tour-footer {
	padding-top: 60px;
	padding-bottom: 60px;
	margin-top: 0;
	background: #f4f4f4;

	h2 {
		padding-left: 0;
	}

	.download-buttons {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		margin-bottom: 50px;
		margin-top: 50px;
		grid-gap: 60px;

		@include media-breakpoint-down(md) {
			display: flex;
			grid-gap: 20px;
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		a {
			display: inline-block;
			overflow: auto;

			img {
				max-width: 100%;
				width: 100%;
				height: auto;

			}

			@include media-breakpoint-down(md) {
				flex-basis: 33%;
			}

			@include media-breakpoint-down(sm) {
				text-align: center;
				img {
					max-width: 50%;
				}
			}

		}
	}

	p {
		a {
			text-decoration: underline;
		}
	}
}