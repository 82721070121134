.flex-content {
	.press-feed {
		background: #F4F4F4;
		padding: 40px 0;

		h2 {
			padding: 0;
			margin-bottom: 20px;
		}

		.intro-container {
			margin-bottom: 40px;
		}

		.press-link {
			padding: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.see-all-link {
		margin-top: 30px;
		text-decoration: underline;
		display: flex;
		font-size: 18px;
		align-items: center;
		justify-content: center;

		&:hover {
			text-decoration: none;
		}
	}
}
