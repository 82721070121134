.art-for-sale-card {
	display: flex;
	margin-bottom: 20px;
	padding: 25px;
	position: relative;
	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}
	.store-card-image {
		width: 200px;
		height: 200px;
		margin-right: 25px;
		flex-shrink: 0;
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
			width: 100%;
			height: auto;
		}
		img {
			width: 100%;
		}
	}
	.details {
		padding-right: 110px;
		@include media-breakpoint-down(sm) {
			padding-right: 0;
		}
		h3 {
			font-size: 28px;
			line-height: 33px;
			margin-bottom: 5px;
		}
		h4 {
			font-size: 18px;
			line-height: 24px;
		}
		p {
			font-size: 16px;
			line-height: 22px;
		}
		a {
			position: absolute;
			right: 25px;
			top: 45%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			@include media-breakpoint-down(sm) {
				position: relative;
				align-items: center;
				justify-content: flex-start;
				right: unset;
				top: unset;
				margin-top: 20px;
			}
			img {
				width: 13px;
				height: 13px;
				margin-left: 5px;
			}
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;
			li {
				margin-right: 5px;
				margin-bottom: 5px;
				font-size: 15px;
				font-weight: bold;
				line-height: 20px;
				padding: 5px 10px;
				background: #E2E2E2;
			}
		}
	}
	&:nth-child(2n) {
		background: #F4F4F4;
	}
}