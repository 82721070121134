.single-artwork {

	.past-artwork-single {
		padding: 10px 20px;
		background: #F4F4F4;
		position: relative;
		margin-top: 50px;
		p {
			font-size: 21px;
			font-style: italic;
			line-height: 32px;
			text-align: center;
			color: black;
			margin: 0;
		}
		@include media-breakpoint-down(md) {
			padding: 5px 20px;
			margin-top: 20px;
			p {
				font-size: 16px;
			}
		}
	}
	
	h1 {
		padding: 0;
		margin: 0;
	}
	
	.artist-intro {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 50px;
		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
			margin-top: 20px;
		}
		.content {
			flex-basis: 70%;
			@include media-breakpoint-down(lg) {
				flex-basis: 60%;
			}
			@include media-breakpoint-down(sm) {
				flex-basis: 100%;
			}
		}
		.favourite-button-container {
			background-color: #f1f1f1;
			padding: 10px 15px 15px 15px;
		}
	}
	
	.artist-name {
		font-size: 28px;
		line-height: 33px;
		font-family: $headings-font-family;
	}
	
	.favourite-button-container {
		padding: 20px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}
		
	}
	
	.artwork-location {
		position: relative;
		margin: 30px 0;
		
		.address {
			position: relative;
			padding: 0 30px;
			img {
				width: 25px;
				position: absolute;
				left: 0;
				top: 2px;
			}
		}
		
		.links {
			padding-left: 38px;
			
			.bar {
				margin: 0 5px;
				display: inline-block;
				@include media-breakpoint-down(lg) {
					display: block;
					height: 0;
					opacity: 0;
				}
			}
			
			a {
				position: relative;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			
			.show-on-google {
				&:after {
					position: absolute;
					right: -15px;
					display: block;
					bottom: -2px;
					width: 8px;
					content: url(/images/right-arrow.svg);
					transition: right 0.2s linear;
				}
			}
			
			.show-on-map {
				&:before {
					position: absolute;
					left: -15px;
					display: block;
					top: 50%;
					width: 8px;
					transform: translateY(-45%);
					content: url(/images/left-arrow.svg);
					transition: right .2s linear;
				}
			}
		}
	}
	
	.artwork-image {
		background-color: black;

		a {
			height: 550px;
			width: 100%;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		img {
			max-width: 100%;
			max-height: 100%;
		}
		@include media-breakpoint-down(sm) {
			a {
				height: 450px;
			}
		}
	}
	
	.description {
		margin: 70px auto 50px auto;
		padding: 40px;
		background-color: #F4f4f4;
		max-width: 950px;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.7;
		@include media-breakpoint-down(sm) {
			margin: 30px auto;
			padding: 20px;
		}
		h2 {
			font-size: 42px;
			line-height: 50px;
			padding: 0;
		}
		h3, h4 {
			margin-top: 25px;
		}
		p {
			font-size: 18px;
			font-weight: 300;
			line-height: 1.7;
		 }
		.shop-button {
			font-size: 18px;
			font-weight: 900;
			letter-spacing: 0.2px;
			line-height: 28px;
			text-align: center;
			margin: 60px auto 0 auto;
			display: block;
			width: 200px;
		}
	}
	
	.additional-info {
		margin: 50px auto;
		max-width: 830px;
		h2 {
			//text-transform: uppercase;
			font-size: 36px;
			letter-spacing: 0;
			line-height: 44px;
			padding: 0;
		}
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			columns: 2;
			@include media-breakpoint-down(sm) {
				columns: 1;
			}
			li {
				border-left: 2px solid black;
				margin: 0;
				padding: 5px 30px;
				font-size: 18px;
				font-weight: 300;
				line-height: 27px;
				@include media-breakpoint-down(sm) {
					padding: 5px 15px;
				}
				a {
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
				}
				span {
					font-size: 21px;
					line-height: 26px;
					font-family: $headings-font-family;
					//text-transform: uppercase;
				}
			}
		}
	}
	
	.social-share {
		border-top: 2px solid black;
		padding-top: 40px;
		margin-top: 100px;
		margin-bottom: 30px;
	}
	
	.hub-artworks {
		background:none;
		@include media-breakpoint-down(sm) {
			padding-top: 20px;
			.favourite-button-container {
				background: none;
				margin-bottom: 0;
			}
		}
	}
	
	.load-more {
		background:none;
		@include media-breakpoint-down(sm) {
			padding: 0;
		}
	}
	
	.associated-hub {
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}
	}
	
}