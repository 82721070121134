.blog-filter-message-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 40px;
}

.sort-buttons {
	margin-left: 30px;
	flex-basis: 40%;
	p {
		font-size: 1.8rem;
		margin-bottom: 10px;
		font-weight: 500;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		order: 2;
		margin-left: 0;
		margin-bottom: 15px;
		flex-basis: 100%;
	}
}
