.art-for-sale-pagination, .pagination {
	position: relative;
	margin-bottom: 50px;
	padding-bottom: 10px;
	padding-top: 10px;
	border-top: 3px solid black;
	border-bottom: 3px solid black;
	width: 100%;
	height: 56px;
	.pagination-button {
		position: absolute;
		text-decoration: underline;
		background: none;
		border: none;
		padding: 0 20px;
		&:hover {
			text-decoration: none;
		}
	}
	.prev-button {
		left: 0;
		&:before {
			content: url("/images/left-arrow.svg");
			position: absolute;
			width: 10px;
			height: 10px;
			left: 0;
			top: 2px;
		}
	}
	.next-button {
		right: 0;
		&:after {
			content: url("/images/right-arrow.svg");
			width: 10px;
			height: 10px;
			position: absolute;
			right: 0;
			top: 2px;
		}
	}
	ul {
		list-style-type: none;
		position: absolute;
		inset: auto;
		margin: 0 auto;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		left: 0;
		right: 0;
		width: fit-content;
		li {
			button, a {
				background: transparent;
				border: none;
				width: 30px;
				height: 30px;
				line-height: 1;
				text-align: center;
				padding: 0 10px;
				font-size: 18px;
				font-weight: 500;
				letter-spacing: 0;
				text-decoration: none;
				display: flex;
				align-items: center;
				justify-content: center;
				&.active {
					border: 2px solid black;
				}
			}
		}
	}
}