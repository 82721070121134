.accordions {
  margin: 50px auto;
  .accordion-item {
    margin-bottom: 20px;
  }
  .accordion-header {
    margin-bottom: 15px;
    h3 {
      font-size: 36px;
      line-height: 44px;
      text-align: left;
      width: 100%;
      border-bottom: 2px solid black;
      @include media-breakpoint-down(md) {
        font-size: 28px;
        line-height: 1.3;
      }
    }
    button {
      border: 0;
      background: none;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      text-align: left;
      span {
        display: block;
        font-size: 40px;
        padding-left: 30px;
      }
    }
  }
  .accordion-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    div {
      padding-right: 10%
    }
    .close-button {
      align-self: flex-end;
      margin-left: auto;
      margin-top: 25px;
      width: auto;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      a {
        font-size: inherit;
        font-weight: 500;
        line-height: inherit;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}