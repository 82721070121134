.events-page {
  .info-block {
    @include media-breakpoint-up(sm) {
      padding-left: 30px;
    }
    .content {
      padding-left: 30px;
      p {
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.featured-event, .this-week-events {
  margin-bottom: 60px;
  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}

.no-items {
  margin-bottom: 40px;
  padding-left: 30px;
  p {
    font-size: 21px;
  }
}

