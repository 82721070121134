.curator-section {
  margin: 100px auto 70px auto;
  border-bottom: 1px solid black;
  padding-bottom: 70px;
  .inner-section {
    max-width: 100%;
    margin: 0 auto;
  }
}

.curator-statement {
  h2 {
    padding: 0;
  }
  p {
    font-size: 21px;
    font-weight: 300;
    line-height: 34px;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 29px;
    }
  }
}

.curator {
  display: flex;
  align-items: flex-start;
  @include media-breakpoint-down(lg) {
    align-items: flex-start;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  .image-container {
    flex-basis: 35%;
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      margin-bottom: 20px;
      
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  h3 {
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 30px;
  }
  .curator-details {
    padding: 0 40px;
    flex-basis: 65%;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    .tag {
      font-size: 28px;
      line-height: 33px;
      letter-spacing: 0;
    }
    .bio {
      p {
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        margin-bottom: 25px;
      }
    }

  }
}

