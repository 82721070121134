html {
	font-size: 62.5%;
}

body {
	font-family: $body-font-family;
	font-size: $font-size-base;
	padding-top: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	b, strong {
		font-weight: 500;
	}
}

.styled-button, .button {
	position: relative;
	padding: 10px 30px 13px 30px;
	line-height: 1;
	color: black;
	background-color: white;
	border: 2px solid black;
	//text-transform: uppercase;
	font-size: 2.8rem;
	font-weight: $bolder;
	margin-bottom: 0;
	text-align: center;
	border-radius: 0;

	&:after {
		content: " ";
		width: 100%;
		height: 100%;
		border: 2px solid white;
		position: absolute;
		bottom: -5px;
		left: -5px;
		transition: all .15s linear;
	}

	&:hover {
		text-decoration: none;

		&:after {
			bottom: -8px;
			left: -8px;
		}
	}

	&:active {
		&:after {
			bottom: -2px;
			left: -2px;
		}
	}

	@include media-breakpoint-down(sm) {
		font-size: 2.1rem;
		line-height: 3.2rem;
	}

	&.reversed {
		color: white;
		background-color: black;
		border: 2px solid white;

		&:after {
			border: 2px solid black;
		}
	}

	&[disabled] {
		background-color: #6B6B6B;
		color: #C7C7C7;
		border: #6B6B6B;
		padding: 14px 30px 18px 30px;
		font-size: 20px;
		font-weight: $bolder;

		&:after {
			border: none;
		}
	}

	&.small-button {
		font-size: 1.8rem;
	}
}

.no-style {
	background: transparent;
	border: none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.caption {
	margin-bottom: 25px;
	margin-top: 15px;
	width: 100%;
	padding: 0 10px;

	p {
		border-left: 3px solid black;
		padding: 0 15px;
		font-size: 15px;
		line-height: 1.3;
		font-weight: 300;
	}

	@include media-breakpoint-down(md) {
		padding: 0;
	}
}

a.no-style {
	padding-left: 40px;
}

.load-more {
	text-align: center;
	padding: 50px 20px;
	background-color: #F4F4F4;

	.styled-button {
		padding: 18px 40px 20px;
	}

	&.no-bg {
		background-color: transparent;
	}
}

h1 {
	font-size: 7.2rem;
	line-height: 1.1;
	//text-transform: uppercase;
	margin-bottom: 40px;
	margin-top: 80px;
	padding-left: 30px;
	@include media-breakpoint-down(md) {
		font-size: 4.7rem;
		line-height: 1;
		margin-bottom: 30px;
		margin-top: 60px;
		padding-left: 0;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 50px;
	}
}

h2 {
	font-size: 4.8rem;
	line-height: 5.8rem;
	padding-left: 30px;
	margin-bottom: 30px;
	@include media-breakpoint-down(md) {
		font-size: 3.5rem;
		line-height: 1.2;
		padding-left: 0;
		margin-bottom: 20px;
	}
}

h3, .h3 {
  font-size: 2.8rem;
  @include media-breakpoint-down(md) {
    font-size: 2.4rem;
  }
}

p {
	font-size: 18px;
	font-weight: 300;
	margin-bottom: 15px;
}

.back-link {
	font-size: 21px;
	font-weight: 500;
	line-height: 28px;
	position: relative;
	text-decoration: underline;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: 20px;
	display: flex;
	width: fit-content;

	&:hover {
		text-decoration: none;
	}

	&:before {
		position: absolute;
		left: -20px;
		display: block;
		top: 50%;
		transform: translateY(-33%) rotate(180deg);
		content: url("/images/back-arrow.svg");
	}
}

.error {
	font-size: 16px;
	color: $red;
	font-weight: 500;
}

a, button, input, select {
	&:focus {
		outline: dodgerblue auto 1px;
		outline-offset: 1px;
		box-shadow: 0 0 2px white;
	}
}