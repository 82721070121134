.blog {
	.back-link {
		margin-top: 50px;
	}
	.blog-details {
		background-color: black;
		color: white;
		transform: translateY(-80px);
		width: 80%;
		margin: 0 auto -80px auto;
		p {
			margin-bottom: 10px;
		}
		h1 {
			padding: 0;
			margin: 0 0 30px 0;
		}
		.blog-details-text {
			padding: 25px 50px;
		}
		.blog-details-flex {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			div {
				flex-basis: 50%;
			}
		}
		.author, .date {
			font-size: 18px;
			font-weight: 300;
			line-height: 1;
			span {
				font-size: 21px;
				letter-spacing: 0.25px;
			}
		}
		.credit {
			font-size: 15px;
			line-height: 23px;
			text-align: right;
		}
	}
	.image-container {
		width: 100%;
		img {
			width: 100%;
		}
	}
	
	.author-section {
		width: 80%;
		margin: 0 auto 50px auto;
		.curator {
			margin-top: 0;
		}
	}
	
	.flex-content {
		width: 75%;
		margin: 0 auto 80px auto;
		.wysiwyg {
			margin: 50px auto;
			p, ol, ul, h2, h3, h4, h5, h6 {
				width: 95%;
				padding: 0;
				margin: 0 auto 30px auto;
			}
			h2, h3, h4, h5, h6 {
				margin-top: 50px;
			}
			blockquote {
				border-left: 4px solid black;
				p {
					font-weight: 500;
					font-size: 21px;
					letter-spacing: 0;
					line-height: 1.7;
				}
			}
		}
		p, li {
			font-size: 18px;
			line-height: 1.8;
		}
	}
	
}