.press-link {
  padding: 30px 30px;
  list-style-type: none;
  @include media-breakpoint-down(sm) {
    padding: 20px 15px;
  }
  .press-title {
    padding: 0;
  }
  div {
    padding-left: 70px;
    position: relative;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      border-radius: 500px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      display: block;
      content: " ";
    }
    &.video {
      &:before {
        background-image: url("/images/video.svg");
      }
    }
    &.document, &.article {
      &:before {
        background-image: url("/images/document.svg");
      }
    }
    &.audio {
      &:before {
        background-image: url("/images/audio.svg");
      }
    }
    &.press-release {
      &:before {
        background-image: url("/images/press-release.svg");
      }
    }
    .press-date {
      margin-bottom: 5px;
    }
  }
}

.flex-content, .press-links {
  .press-link:nth-child(even) {
    background-color: #F4F4F4;
  }
  .press-link:last-child {
    margin-bottom: 80px;
  }
}

.press-link-container {
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}