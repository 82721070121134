.read-only-route {
	.route-map {
		height: 70vh;
		max-height: 600px;
		width: 100%;
	}
	.directions-heading {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 0 30px;
		margin-top: 30px;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 0;
		}
		a {
			margin-bottom: 5px;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	h2 {
		margin-top: 30px;
		margin-bottom: 0;
		line-height: 1;
		padding-left: 0;
	}
	.route-details-heading {
		border: 2px solid black;
		border-bottom: none;
		margin-top: 20px;
	}
	.route-details {
		border: 2px solid black;
		margin-bottom: 30px;
		border-top: none;
	}
	.route-directions {
		max-height: fit-content;
	}
}