.art-for-sale-filter-message-container {
	display: flex;
	margin-top: 25px;
	justify-content: space-between;
	align-items: flex-start;
}

.sort-select {
	display: flex;
	align-items: center ;
	margin-left: 40px;
	flex-shrink: 0;
	select {
		padding: 5px 35px 5px 10px;
		border: 2px solid black;
		border-radius: 0;
		appearance: none;
		position: relative;
		background: transparent;
	}
	.select-wrapper {
		position: relative;
		&:after {
			content: url("/images/down-arrow.svg");
			width: 10px;
			height: 10px;
			position: absolute;
			right: 15px;
			top: 12%;
		}
	}
	label {
		margin-right: 15px;
	}
}