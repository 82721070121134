.show-for-mobile {
	display: block;
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.hide-for-mobile {
	display: none;
	@include media-breakpoint-up(md) {
		display: block;
	}
}