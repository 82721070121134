.whats-on {
  background: #F4F4F4;
  padding: 50px 20px;
  margin: 20px auto;
  h2:first-child {
    text-align: center;
    margin-bottom: 10px;
  }
  .item-carousel {
    padding-bottom: 25px;
    padding-top: 20px;
    h2 {
      font-size: 36px;
      line-height: 44px;
    }
  }
}