.resource-library {
  margin: 50px auto 80px auto;
  padding: 0 30px;
  @include media-breakpoint-down(md) {
    padding: 0;
    margin: 30px auto 40px auto;
  }


  .styled-button {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 14px 30px 16px 30px;
  }
  .no-items {
    padding-left: 0;
  }
  .filters-section {
    border: none;
    padding: 0;
    margin: 0 0 30px 0;
    width: 100%;
    background: transparent;
  }
  .on-demand-filter-message-container {
    margin-top: 20px;
  }
  .filters-container {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    .checkbox-radio-filter {
      flex-basis: 50%;
      @include media-breakpoint-down(sm) {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
}

.single-resource {
  .credit {
    font-style: italic;
    color: #6c6c6c;
    padding-left: 30px;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }
  .description {
    padding-left: 30px;
    max-width: 1000px;
    h2, h3, h4 {
      padding: 0;
    }
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }
  .self-hosted-document {
    a {
      display: inline-flex;
      margin-left: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
  }
}