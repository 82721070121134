.resource-listing-container {
  width: 100%;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  align-items: flex-start;
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.resource-carousel {
  background-color: #F1F1F1;
  .resource-card {
    margin: 0 10px;
  }
}

.resource-card {
  border: 3px solid black;
  border-top: 12px solid black;
  padding-bottom: 20px;
  margin-bottom: 30px;
  background-color: white;
  h3 {
    font-size: 2rem;
  }

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .credit {
    font-style: italic;
    padding: 0 20px;
    color: grey;
    margin: 10px 0 0 0;
    font-size: 15px;
  }
  
  .embed-container {
    margin: 0;
  }
  
  .self-hosted-audio {
    padding: 0 20px;
    margin-top: 20px;
    audio {
      width: 100%;
    }
  }
  

  .type {
    font-family: $headings-font-family;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 22px;
    margin-bottom: 10px;
    display: block;
    span {
      background-color: #F4F4F4;
      padding: 7px 20px;
      display: inline-block;
    }
  }
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    background: black;
    height: 255px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  h2 {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 34px;
    padding: 0 20px;
    margin: 0 0 10px 0;
  }
  .excerpt {
    font-size: 18px;
    font-weight: $light-weight;
    line-height: 28px;
    padding: 0 20px;
    margin-bottom: 0;
  }
  &.without-image {
    .type {
      transform: none;
      margin-top: 15px;
    }
  }
}

.external-link {
  position: relative;
  &:after {
    position: absolute;
    content: url("/images/external-alt.svg");
    right: -28px;
    width: 18px;
    height: 18px;
  }
}

.download-link {
  position: relative;
  &:after {
    position: absolute;
    content: url("/images/download-document.svg");
    right: -28px;
    width: 18px;
    height: 18px;
  }
}