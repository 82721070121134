.hub-info {
  margin: 50px auto;

  .location-image {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .details {
    display: grid;
    padding: 30px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
      padding: 20px 0;
    }
    aside {
      padding: 20px;
      background: #F4F4F4;
      p {
        font-size: 15px;
        line-height: 22px;
      }
    }
    .location {
      div {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
      }
      a {
        display: block;
        margin-top: 10px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      img {
        display: inline-block;
        margin-right: 8px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
        font-weight: $bold;
      }
    }
    .hours {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          span {
            display: inline-block;
            width: 30%;
            font-weight: $bold;
          }
          font-size: 15px;
          line-height: 26px;
        }
      }
    }
  }
}