.hero {
	width: 100%;
	margin: 0 auto 70px;
	max-width: 2000px;
	position: relative;
	video, img {
		width: 100%;
		max-width: 100%;
	}

	&.hub-hero {
		img {
			height: 437px;
			object-fit: cover;
			object-position: center;
			@include media-breakpoint-down(sm) {
				height: 250px;
			}
		}
	}
	.hero-video {
		position: relative;
		width: 100%;
		padding-top: 42.9%; // video should be 1920 x 825
		background: rgba(grey, 0.5);
		overflow: hidden;
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
		.video-controls {
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 1;
			button {
				background: none;
				border: none;
				padding: 0;
				margin: 0;
			}
			@media screen and (max-width: 650px) {
				top: 15px;
				right: 15px;
				button {
					width: 30px;
				}
			}
		}
	}
	.hero-title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 100%;
		.on-placement {
			transform: translateY(20px);
			h1 {
				margin: 0;
				color: white;
				font-weight: 500;
				line-height: 1.1;
				letter-spacing: 1px;
				display: inline;
				white-space:pre-wrap;
				padding: 5px 10px 5px 0;
				background-color: black;
				box-shadow: -16px 0 0 black;
				@include media-breakpoint-down(sm) {
					font-size: 30px;
				}
			}
		}
	}
}



.hub-breadcrumb {
	position: absolute;
	margin: 0 auto;
	top: 20px;
	left: 0;
	right: 0;
	padding: 0 20px;
	@include media-breakpoint-down(md) {
		padding: 0 10px;
		top: 10px;
	}
	.inner-breadcrumb {
		background: black;
		width: fit-content;
		padding: 3px 10px 8px 10px;
		color: white;
		img {
			width: 7px;
			height: 7px;
			display: inline-block;
			margin: 0 3px;
		}
		a {
			color: white;
			font-size: 14px;
			line-height: 1;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}