.plan-a-route-intro {
	margin-bottom: 80px;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			display: flex;
			text-align: left;
			margin-bottom: 30px;
			padding: 25px;
			justify-content: space-between;
			align-items: flex-start;
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				div {
					margin-bottom: 10px;
				}
			}
			> div:first-child {
				display: flex;
				padding-right: 30px;
				@include media-breakpoint-down(sm) {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}
			p, h3 {
				text-align: left;

			}
			.step-count {
				width: 40px;
				height: 40px;
				margin-right: 20px;
				color: white;
				background: black;
				border-radius: 500px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				font-size: 21px;
				flex-shrink: 0;
				line-height: 1;
				span {
					transform: translateY(-2px);
				}
			}
			&:nth-child(even) {
				background: $light-grey;
			}
		}
	}
}