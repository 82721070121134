footer {
  background-color: $primary;
  color: white;
  padding: 60px 30px 50px 30px;
  @include media-breakpoint-down(md) {
    padding: 30px 30px 20px 30px;
  }
  .footer-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    nav {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      a {
        display: block;
        color: white;
        margin-left: 40px;
        font-size: 1.8rem;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      .city-logo {
        text-align: center;
      }
      img {
        margin-bottom: 25px;
      }
      nav {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 15px;
        a {
          text-align: center;
          margin: 0 0 10px 0;
        }
      }
    }
  }
  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        margin-left: 20px;
      }
      .newsletter {
        border-right: 1px solid white;
        a {
          color: white;
          text-decoration: underline;
          margin-right: 20px;
          font-size: 13px;
          line-height: 20px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    p {
      //text-transform: uppercase;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      ul {
        justify-content: center;
        margin-bottom: 20px;
        order: 1;
        flex-wrap: wrap;
        li {
          margin: 0 15px;
        }
        .newsletter {
          border-right: none;
          margin-bottom: 20px;
          flex-basis: 100%;
          text-align: center;
          a {
            text-align: center;
            margin: 0;
          }
        }
      }
      p {
        order: 2;
        text-align: center;
      }
    }
  }

}