.transcript-container {
	margin-top: 50px;
	margin-bottom: 50px;
	@include media-breakpoint-down(md) {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	h2 {
		padding-left: 0;
		margin-bottom: 15px;
	}
}