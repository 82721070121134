.email-favourites {
  background-color: #F4F4F4;
  padding: 60px 20px;
  margin-top: 40px;
  p {
    font-size: 2.1rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }
  .email-favourites-bar {
    width: 100%;
    max-width: 700px;
    margin: 20px auto 0 auto;
    .error {
      font-size: 15px;
      color: red;
      position: absolute;
      bottom: -20px;
      @include media-breakpoint-down(md) {
        position: relative;
      }
    }
    form {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
      }
      .input-group {
        flex-basis: 65%;
        @include media-breakpoint-down(md) {
          flex-basis: 100%;
        }
      }
      label {
        display: block;
        font-size: 2.1rem;
        line-height: 1.3;
      }
      input[type="email"] {
        height: 49px;
        border: 2px solid black;
        background-color: white;
        width: 100%;
        padding: 0 10px;
        font-size: 18px;
        border-radius: 0;
        margin-bottom: 10px;
        &.error-input {
          border-color: red;
        }
      }
      button[type="submit"] {
        font-size: 1.8rem;
        padding: 15px 20px;
        margin-left: 20px;
        height: 49px;
        margin-bottom: 15px;
        @include media-breakpoint-down(md) {
          margin-left: 0;
          margin-top: 10px;
          line-height: 1;
        }
      }
    }
  }
  .send-success {
    max-width: 650px;
    margin: 0 auto;
    h2 {
      //text-transform: uppercase;
      letter-spacing: 0;
      text-align: left;
      margin: 0 0 10px 0;
      padding: 0;
      position: relative;
      &:before {
        background-image: url("/images/Checkmark.svg");
        content: " ";
        width: 50px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: -60px;
        @include media-breakpoint-down(md) {
          background-image: none;
        }
      }

    }
    p {
      text-align: left;
      padding: 0;
      margin: 0;
      font-weight: 300;
      max-width: unset;
      strong {
        font-weight: 500;
      }
    }
  }
}