.page {
  .wysiwyg, .text-with-sidebar > .content {
    margin-bottom: 80px;
    max-width: 100%;
    p {
      padding-left: 30px;
			padding-right: 30px;
      margin-bottom: 30px;
    }
    h2, h3, h4, h5, h6 {
      margin-top: 40px;
      padding-left: 30px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      max-width: 100%;
      p {
        padding-left: 0;
				padding-right: 0;
      }
      h2, h3, h4, h5, h6 {
        padding-left: 0;
      }
    }
  }
}