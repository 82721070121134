.filters-wrapper {
	> .filter-header {
		.filter-toggle-button {
			letter-spacing: 0.4px;
			width: 100%;
			color: white;
			background-color: black;
			border: none;
			text-align: left;
			margin-top: 0;
			line-height: 1;
			position: relative;
			font-family: $body-font-family;
			font-weight: $bold;
			font-size: 1.8rem;
			padding: 14px 50px 14px 20px;
			span {
				font-weight: $light-weight;
				font-size: 1.6rem;
			}
			&:after {
				content: "+";
				position: absolute;
				right: 15px;
				font-size: 30px;
				color: inherit;
				top: 40%;
				line-height: 1;
				transform: translateY(-50%);
			}
			&.open {
				&:after {
					content: "–";
				}
			}
		}
	}
	.main-filter-content {
		border: 3px solid black;
		border-top: 0;
		background-color: white;
		> .filter-controls {
			padding: 10px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			margin-top: 0;
			background: #F4F4F4;
			button {
				padding: 0;
				margin: 0;
				line-height: 1;
			}
			.clear-button {
				font-size: 13px;
				line-height: 20px;
				margin-right: 30px;
			}
			.filter-control-buttons {
				button {
					font-weight: 600;
					font-size: 15px;
				}
			}
			.apply-button {
				border-right: 1px solid black;
				padding-right: 10px;
				margin-right: 8px;
			}
		}
	}
	.filters-container {
		padding: 20px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 30px;
		background: #F4F4F4;
		@include media-breakpoint-down(sm) {
			padding: 15px;
			grid-template-columns: repeat(1, 1fr);
			grid-gap: 20px;
		}
	}
}
