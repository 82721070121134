.item-carousel {
	margin: 0 auto;
	padding-top: 50px;
	padding-bottom: 50px;
	@include media-breakpoint-down(md) {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.container {
		max-width: 1340px;
		@include media-breakpoint-down(lg) {
			max-width: 980px;
		}
		@include media-breakpoint-down(md) {
			max-width: 740px;
		}
		@include media-breakpoint-down(sm) {
			max-width: 560px;
		}
	}

	.intro {
		padding-right: 40px;
	}

	.carousel-control {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 40px;
		margin-bottom: 30px;
		@include media-breakpoint-down(md) {
			padding: 0 20px;

		}
		@include media-breakpoint-down(sm) {
			padding: 0;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 20px;
		}

		.controls {
			display: flex;
			align-items: center;
			flex-shrink: 0;

			button {
				margin-bottom: 0;
			}

			p {
				font-size: 24px;
				line-height: 28px;
				font-family: $headings-font-family;
				margin-right: 10px;
				margin-bottom: 0;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 15px;
				justify-content: center;
			}
		}

		h2 {
			padding: 0;
			margin-bottom: 0;
			@include media-breakpoint-down(sm) {
				margin-bottom: 10px;
			}
		}

		button {
			border: none;
			border-radius: 0;
			margin: 0 0 10px 0;
			padding: 0;

			&:first-child {
				margin-right: 10px;
			}
		}

		.heading-group {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			@include media-breakpoint-down(sm) {
				justify-content: center;
				align-items: center;
			}
			h2 {
				padding: 0;
				margin-right: 20px;
				@include media-breakpoint-down(sm) {
					margin-right: 0;
					font-size: 32px;
				}
			}

			button {
				border: 2px solid black;
				margin-right: 10px;
				margin-bottom: 0;
				padding: 2px 8px;
				font-size: 14px;
				font-weight: 500;
				background: white;

				&.active {
					color: white;
					background: black;
				}
			}
		}
	}

	.artwork-card, .event-card {
		margin: 0 15px 30px 15px;
		background: white;
		@include media-breakpoint-down(sm) {
			margin: 0 0 30px 0;
		}
	}

	.artwork-card {
		.address {
			button {
				display: none;
			}
		}
	}
}

.hide-on-desktop {
	display: flex !important;
	@include media-breakpoint-up(lg) {
		display: none !important;
	}
}