.gallery-lightbox {
	margin: 50px auto;
	.intro {
		h2, h3, h4 {
			padding: 0;
		}
	}
}

.gallery-images-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	margin: 40px auto;

	.image-button {
		padding-bottom: 20%;
		background-repeat: no-repeat;
		background-size: contain;
		background-color: black;
		background-position: center center;
		width: 100%;
		flex-basis: calc(20% - 10px);
		border: 3px solid white;
		transition: all .2s linear;
		&:hover {
			border-color: black;
		}
		@include media-breakpoint-down(md) {
			flex-basis: calc(33% - 10px);
			padding-bottom: 33%;
		}
		@include media-breakpoint-down(sm) {
			flex-basis: calc(50% - 10px);
			padding-bottom: 50%;
		}
	}
}