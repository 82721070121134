.blog-listing {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 50px;
	.blog-card {
		flex-basis: calc(33.333333% - 20px);
		margin: 10px 10px 20px 10px;
		@include media-breakpoint-down(md) {
			flex-basis: calc(50% - 20px);
		}
		@include media-breakpoint-down(sm) {
			flex-basis: calc(100% - 20px);
		}
	}
}