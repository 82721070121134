.programming-partners {
  margin: 50px auto;
  @include media-breakpoint-down(sm) {
    margin: 25px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
    align-items: flex-start;
  }
}

.programming-partners-container {
  max-width: 1100px;
  margin: 50px auto;
  overflow: auto;
  .intro {
    padding: 0 30px;
    max-width: 800px;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    h2 {
      padding: 0;
    }
  }
}

.programming-partner {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin-bottom: 25px;
    align-items: flex-start;
  }
  .image-container {
    flex-basis: 30%;
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    }
    img {
      width: 100%;
    }
  }
  h3 {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 10px;
    @include media-breakpoint-down(sm) {
      font-size: 25px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 22px;
  }
  .dates {
    font-size: 18px;
    line-height: 26px;
    font-weight: $bold;
  }
  .content {
    padding: 0 30px;
    flex-basis: 70%;
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      padding: 15px 0;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}