.filter-bar {
  .tabs {
    list-style-type: none;
    border-bottom: 3px solid black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0 70px 0;
    padding: 0;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0 30px 0;
      padding-bottom: 10px;
    }
    button {
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
      padding: 7px 20px;
      font-family: $headings-font-family;
      position: relative;
      background-color: black;
      color: white;
      margin: 0 15px 20px 15px;
      font-size: 22px;
      transition: all .15s linear;
      border: none;
      min-width: calc(25% - 30px);
      &:before {
        content: " ";
        width: 100%;
        height: 100%;
        border: 3px solid black;
        position: absolute;
        bottom: -7px;
        left: -7px;
        transition: all .15s linear;
      }
      &.active, &:hover {
        color: black;
        background-color: white;
        &:before {
          bottom: 0;
          left: 0;
        }
      }
      @include media-breakpoint-down(lg) {
        min-width: calc(50% - 30px);
      }
      @include media-breakpoint-down(sm) {
        margin: 0 0 15px 0;
        width: 100%;
      }
    }
    
  }
}