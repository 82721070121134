body {
	padding-top: 157px;
	transition: all .15s linear;
	@include media-breakpoint-down(sm) {
		padding-top: 103px;
	}

	@include media-breakpoint-up(md) {
		&.has-scrolled {
			padding-top: 108px;

			.main-nav {
				margin-top: 17px;
			}

			header {
				padding: 10px 0 15px 0;

				.navbar-brand {
					img {
						max-width: 200px;
					}
				}
			}

			.nav-favourites {
				a {
					font-size: 22px;
				}

				.nav-heart {
					img {
						width: 34px;
					}

					.count {
						top: 0;
						right: -11px;
					}
				}
			}

			.top-bar {
				padding-bottom: 13px;

				.city-logo {
					img {
						width: 100px;
					}
				}
			}
		}
	}
}

header {
	background-color: black;
	padding: 16px 0 25px 0;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1000;
	transition: all .15s linear;

	@include media-breakpoint-down(sm) {
		padding: 10px 0 15px 0;
	}

	.translation-selection {
		width: 100%;
		max-width: 650px;
		@include media-breakpoint-down(md) {
			width: 100%;
			max-width: 100%;
			margin-bottom: 50px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}

		label {
			font-size: 16px;
			font-weight: 500;
			letter-spacing: 0.18px;
			line-height: 24px;
			margin-bottom: 10px;
		}

		select {
			border-radius: 0;
			border: 2px solid white;
			background-color: black;
			color: white;
			padding: 8px 40px 10px 13px;
			height: auto;
			display: block;
			-moz-appearance: none; /* Firefox */
			-webkit-appearance: none; /* Safari and Chrome */
			appearance: none;
			background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
			background-repeat: no-repeat;
			background-position-x: 98%;
			background-position-y: 50%;
			width: 100%;

			option {
				background-color: black;
				color: white;
			}
		}
	}

	.container {
		position: relative;
	}

	.navbar-brand {
		display: flex;
		text-align: center;
		//margin: 0 auto;
		overflow: auto;
		width: fit-content;
		* {
			transition: all .15s linear;
		}
		img {
			max-width: 260px;
		}

		@include media-breakpoint-down(sm) {
			display: flex;
			img {
				max-width: 170px;
			}
		}
	}

	.hamburger {
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		@include media-breakpoint-down(sm) {
			right: 10px;
		}
	}
}


//.home {
.main-nav {
	margin-top: 25px;
	@include media-breakpoint-down(sm) {
		margin-top: 15px;
	}
	* {
		transition: all .15s linear;
	}
}

.top-bar {
	display: block;
	width: 100%;
	border-bottom: 1px solid rgba(white, 0.7);
	padding-bottom: 23px;
	@include media-breakpoint-down(sm) {
		padding-bottom: 15px;
	}

	.city-logo {
		img {
			width: 130px;
			height: auto;
			transition: all .15s linear;

			@include media-breakpoint-down(sm) {
				width: 90px;
			}
		}
	}
}

//}

.nav-favourites {
	position: absolute;
	right: 70px;
	top: 50%;
	transform: translateY(-50%);
	border-right: 1px solid white;
	padding-right: 15px;
	* {
		transition: all .15s linear;
	}
	@include media-breakpoint-down(sm) {
		right: 60px;
		padding-right: 10px;
	}

	.nav-heart {
		margin-right: 15px;
		position: relative;
		@include media-breakpoint-down(sm) {
			margin-right: 8px;
			img {
				width: 30px;
				height: auto;
			}
		}

		.count {
			position: absolute;
			top: -5px;
			right: -9px;
			border: 2px solid white;
			background: black;
			border-radius: 500px;
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 11px;
			line-height: 1;
			font-family: $body-font-family;
			@include media-breakpoint-down(sm) {
				top: 0;
				right: -9px;
				width: 18px;
				height: 18px;
				font-size: 10px;
			}

			span {
				display: block;
				margin-top: -2px;
			}
		}
	}

	a {
		color: white;
		display: flex;
		align-items: center;
		font-family: $headings-font-family;
		//text-transform: uppercase;
		font-size: 25px;
		line-height: 33px;

		&:hover {
			text-decoration: underline;
		}

		@include media-breakpoint-down(sm) {
			.icon-label {
				display: none;
			}
		}
	}
}

#NavContainer {
	background-color: black;
	color: white;
	padding: 30px 0;
	overflow-y: scroll;
	@include media-breakpoint-up(md) {
		max-height: calc(100vh - 110px);
	}
	@include media-breakpoint-down(sm) {
		padding: 15px 0;
	}

	nav {
		margin-bottom: 50px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				font-family: $headings-font-family;
				color: white;
				margin-top: 20px;
				transition: all .2s linear;
				border-bottom: 1px solid white;

				> a, > button {
					font-family: $headings-font-family;
					color: white;
					font-size: 4.8rem;
					line-height: 1.4;

					&:hover {
						text-decoration: none;
					}
				}

				> button {
					background: none;
					border: none;
					padding: 0;
					width: 100%;
					text-align: left;
				}

				@include media-breakpoint-down(sm) {
					margin-top: 15px;
					> a, > button {
						font-size: 2.6rem;
						line-height: 1.4;
					}
				}

				&:hover {
					border-bottom: 1px solid grey;
				}

				&.has-items {
					position: relative;
					cursor: pointer;

					ul {
						li {
							a {
								&:hover {
									text-decoration: underline;
								}
							}
						}
					}

					&:before {
						content: "+";
						position: absolute;
						right: 0;
						top: 0;
						pointer-events: none;
						font-size: 4.8rem;
						line-height: 1.4;
						@include media-breakpoint-down(sm) {
							top: -8px;
							font-size: 3.5rem;
						}
					}

					&.open {
						&:before {
							content: "–";
						}
					}
				}

				ul {
					list-style-type: square;
					margin-left: 50px;
					margin-bottom: 30px;
					display: none;
					@include media-breakpoint-down(sm) {
						margin-left: 25px;
						margin-bottom: 20px;
					}

					li {
						font-family: $body-font-family;
						border: none;
						margin-top: 5px;

						> a {
							font-family: $body-font-family;
							font-size: 2.4rem;
							line-height: 1.4;
							text-transform: none;
							@include media-breakpoint-down(sm) {
								font-size: 1.5rem;
							}
						}

						&:hover {
							border-bottom: none;
						}
					}
				}

			}
		}
	}

	.sub-nav {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.styled-button {
			padding: 18px 35px;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
			justify-content: center;
			.styled-button {
				margin-bottom: 30px;
				padding: 10px 20px;
				width: 100%;
				text-align: center;
			}
		}
		@include media-breakpoint-down(sm) {
			.styled-button {
				margin-bottom: 0;
			}
		}

	}
}