.view-route-container {
	border: 2px solid black;
	border-top: 6px solid black;
	display: flex;
	margin-bottom: 60px;
	margin-top: 60px;
	position: relative;
	& > div {
		flex-basis: 50%;
		align-items: stretch;
	}
	@include media-breakpoint-down(lg) {
		flex-direction: column;
		margin-top: 30px;
		margin-bottom: 30px;
		& > div {
			flex-basis: 100%;
			align-items: flex-start;
		}
		.route-map {
			order: 1;
			.vue-map-container {
				height: 40vh;
			}
		}
		.route-details-container {
			order: 2;
		}
	}
}

.route-details-container {
	border-right: 2px solid black;
	@include media-breakpoint-down(lg) {
		border-right: none;
	}
}

.starting-location {
	margin-top: 5px;
	img {
		display: inline-block;
		vertical-align: text-bottom;
		width: 25px;
	}
	a {
		text-decoration: underline;
		font-size: 16px;
		font-weight: 500;
		&:hover {
			text-decoration: none;
		}
	}
}

.route-details-heading {
	background-color: $lightgrey;
	padding: 20px 30px;
	@include media-breakpoint-down(lg) {
		padding: 15px 20px;
	}
	h2 {
		font-size: 42px;
		line-height: 50px;
		text-align: left;
		padding: 0;
		@include media-breakpoint-down(lg) {
			font-size: 30px;
		}
	}
	strong {
		font-family: $headings-font-family;
		font-weight: 500;
		font-size: 21px;
		@include media-breakpoint-down(lg) {
			font-size: 18px;
		}
	}
	.total-route-info {
		display: flex;

		& > div {
			margin-right: 15px;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}
}

.route-details {
	background: white;
	max-height: 70vh;
	overflow: scroll;
	position: relative;
}

.route-map {
	.vue-map-container {
		height: 100%;
	}
}