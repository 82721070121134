.wysiwyg {
  p {
    font-size: 21px;
    font-weight: $light-weight;
    line-height: 34px;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 28px;
    }
    a {
      text-decoration: underline;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
  }
}