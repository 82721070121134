.checkbox-radio-filter, .select-autocomplete-filter, .date-filter {
	flex-grow: 1;
	h3 {
		font-size: 24px;
		letter-spacing: 0.23px;
		line-height: 28px;
		margin-bottom: 10px;
	}
	button {
		margin-top: 10px;
	}
	label {
		font-size: 15px;
		font-weight: 500;
		line-height: 18px;
	}
	
	.filter-container {
		.filter-header {
			button {
				width: 100%;
				position: relative;
				text-align: left;
				border: none;
				border-bottom: 3px solid black;
				padding: 0 0 5px 0;
				background: transparent;
				font-family: $headings-font-family;
				font-size: 2.6rem;
				line-height: 1.2;
				&:after {
					position: absolute;
					content: "+";
					right: 0;
					font-size: 35px;
					color: inherit;
					top: -5px;
				}
				&.open {
					&:after {
						content: "–";
					}
				}
			}
		}
	}
	
	.checkbox, .radio {
		margin-bottom: 0;
		input[type="checkbox"], input[type="radio"] {
			opacity: 0;
			position: absolute;
			& + label::after {
				content: none;
			}
			&:checked + label {
				font-weight: 600;
				background-color: #EFEFEF;
			}
			&:checked + label::after {
				content: "";
			}
			&:focus + label::before {
				outline: grey dashed 1px;
				outline-offset: 2px;
			}
		}
		label {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			padding: 10px 20px 0 30px;
			margin-bottom: 0;
			img {
				margin-right: 5px;
			}
			&:after, &:before {
				position: absolute;
				content: "";
				display: inline-block;
				top: 13px;
			}
			&:before {
				height: 16px;
				width: 16px;
				border: 1px solid;
				background-color: black;
				left: 4px;
			}
			&:after {
				height: 5px;
				width: 9px;
				color: white;
				border-left: 2px solid;
				border-bottom: 2px solid;
				left: 7px;
				top: 17px;
				transform: rotate(-45deg);
				
			}
		}
	}
	
	.radio {
		label {
			&:before {
				height: 16px;
				width: 16px;
				border: 1px solid;
				background-color: black;
				left: 4px;
				border-radius: 50%;
			}
			&:after {
				height: 8px;
				width: 8px;
				color: white;
				background-color: white;
				border-radius: 50%;
				left: 7px;
				top: 17px;
				border: none;
				transform: rotate(0);
			}
		}
	}
	
}

.checkbox-radio-filter {
	@include media-breakpoint-down(md) {
		flex-basis: 100%;
	}
}

.select-autocomplete-filter {
	flex-basis: 100%;
	.form-group {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		margin-top: 18px;
		margin-bottom: 8px;
		.autocomplete-container {
			flex-grow: 1;
			position: relative;
		}
		input[type=text] {
			margin-bottom: 0;
			border: 3px solid black;
			width: 100%;
			border-radius: 0;
			padding: 0 20px;
			height: 60px;
			font-size: 1.8rem;
			line-height: 3.4rem;
		}
		button {
			flex-shrink: 0;
			margin: 0;
			background: black;
			color: white;
			border: 0;
			padding: 8px 20px;
		}
	}
	.autocomplete-options {
		overflow: scroll;
		position: absolute;
		width: 100%;
		max-height:50vh;
		min-height: fit-content;
		background: white;
		border-left: 2px solid black;
		border-bottom: 2px solid black;
		border-right: 2px solid black;
		z-index: 10;
		top: 60px;
		@include media-breakpoint-down(sm) {
			width: calc(100% + 97px);
			max-height:300px;
		}
		ul {
			margin: 0;
			padding: 0;
			button {
				width: 100%;
				text-align: left;
				border-bottom: 1px solid black;
				background: white;
				color: black;
				&:hover, &:focus {
					background: #EFEFEF;
				}
			}
			li:last-child {
				button {
					border-bottom: none;
				}
			}
		}
	}
	.pill-container {
		margin: 10px 0;
		display: flex;
		flex-wrap: wrap;
		.pill {
			background: black;
			color: white;
			border-radius: 500px;
			margin-right: 10px;
			margin-bottom: 10px;
			padding: 3px 10px 5px 15px;
			font-size: 1.4rem;
			
			button {
				color: white;
				background: none;
				border: none;
				margin: 0;
				font-size: inherit;
			}
		}
	}
}

.date-filter {
	fieldset {
		> div {
			width: 100%;
			margin-top: 10px;
			border-radius: 0;
			border: 2px solid black;
			padding: 15px 25px;
			font-family: $body-font-family;
		}
	}
	.vc-header {
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid grey;
		color: black;
	}
	.vc-weekday {
		color: black;
	}
	.date-selected {
		border-radius: 0 !important;
	}
	.vc-day-content {
		&:hover, &:focus {
			background: transparent !important;
		}
	}
}

.filters-section {
	border: 3px solid black;
	padding: 40px 50px 20px 50px;
	background: white;
	margin: 0 auto;
	margin-bottom: 50px;
	margin-top: 50px;
	width: 95%;
	@include media-breakpoint-down(sm) {
		padding: 20px;
	}
	h2 {
		padding: 0;
	}
}