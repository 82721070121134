.blog-categories {
	background: #F4F4F4;
	color: black;
	padding: 5px 15px 3px 15px;
	display: inline-block;
	font-size: 18px;
	letter-spacing: 0.3px;
	font-family: $headings-font-family;
}

.blog-details {
	padding-top: 20px;
	.blog-details-text {
		padding: 20px;
		.excerpt {
			font-size: 18px;
		}
		.author, .date {
			font-size: 16px;
			font-weight: 300;
			span {
				font-family: $headings-font-family;
			}
		}
	}
}

.blog-card {
	background-color: black;
	width: 100%;
	display: flex;
	flex-direction: column;
	color: white;
	margin-bottom: 20px;
	a {
		color: white;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	h2 {
		padding-left: 0;
		font-size: 28px;
		line-height: 1.4;
		letter-spacing: 0;
		margin-bottom: 15px;
	}
	.blog-image {
		border: 5px solid black;
		img {
			width: 100%;
		}
	}
	
	&.featured {
		flex-direction: row;
		width: calc(100% - 20px);
		margin: 40px auto 60px auto;
		.blog-image {
			flex-basis: 60%;
			border: none;
		}
		.blog-details {
			flex-basis: 40%;
			.blog-details-text {
				padding: 35px 50px 40px 50px;
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			.blog-details {
				.blog-details-text {
					padding: 20px;
				}
			}
		}
	}
}