.event-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px;
}

.event-card {
  margin: 0 15px 30px 15px;
  flex-basis: calc(100% / 3 - 30px);
}

@include media-breakpoint-down(md) {
  .event-card {
    flex-basis: calc(100% / 2 - 30px);
  }
}
@include media-breakpoint-down(sm) {
  .event-card {
    flex-basis: calc(100% - 30px);
  }
}
