.covid-cta {
		padding: 30px;
		background-color: black;
		max-width: 1000px;
		margin: 0 auto 80px auto;
	position: relative;
	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;
	}
	&:before {
		content: " ";
		background-image: url("/images/info.svg");
		background-size: contain;
		background-repeat: no-repeat;
		width: 40px;
		height: 40px;
		position: absolute;
		top: -15px;
		left: -15px;
	}
		p {
			color: white;
			margin-bottom: 0;
			a {
				color: white;
				text-decoration: underline;
				font-weight: 500;
				&:hover {
					text-decoration: none;
				}
			}
		}
}