.info-block {
  margin: 50px auto;
  .content {
    padding-left: 40px;
    border-left: 3px solid black;
    width: 100%;
    p {
      font-size: 18px;
      font-weight: $light-weight;
      line-height: 28px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}