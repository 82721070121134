.filter-container {
	margin-right: 0;
	margin-left: 0;
	margin-bottom: 10px;
	@include media-breakpoint-down(md) {
		margin-bottom: 10px;
		margin-right: 0;
	}
	@include media-breakpoint-down(sm) {
		margin-left: 0;
	}

}

.filter-group {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
	@include media-breakpoint-down(md) {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
	}
}

.map-filters {
	width: 100%;
	z-index: 1;
	position: relative;
	margin-top: 10px;
	margin-left: 0;

	.checkbox-radio-filter {
		flex-basis: 33%;
		@include media-breakpoint-down(md) {
			flex-basis: 100%;
		}
	}
}

.map-controls {
	flex-basis: 30%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	@include media-breakpoint-down(md) {
		flex-basis: 50%;
	}
	@include media-breakpoint-down(sm) {
		flex-basis: 100%;
		flex-direction: column;
	}
}

.artwork-view-button {
	margin-left: 30px;
	flex-basis: 40%;

	p {
		font-size: 1.8rem;
		margin-bottom: 10px;
		font-weight: 500;
	}

	@include media-breakpoint-down(md) {
		width: 100%;
		order: 2;
		margin-left: 0;
		margin-bottom: 15px;
		flex-basis: 100%;
	}
}

.states {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	.toggle-button {
		text-align: center;
		border: 2px solid black;
		background-color: white;
		color: black;
		padding: 7px 20px;
		margin: 0 0 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		height: 50px;
		transition: all .1s linear;
		box-sizing: border-box;
		width: 100%;
		flex-basis: 50%;

		img {
			height: 25px;
			width: auto;
			margin-right: 10px;
		}

		&.active, &:hover {
			background-color: #E9E9E9;
			height: 60px;
			font-weight: 600;
			border-width: 4px;
		}

		@include media-breakpoint-down(md) {
			width: 100%;
		}

		&:first-child {
			margin: 0;
		}
	}
}