.error-screen {
	background: black;
	color: white;
	padding: 0;
	#content {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		h1, h2, h3, h4 {
			padding: 0;
			margin-bottom: 20px;
			margin-top: 30px;
		}
	}
}