.event-filters {
	margin-bottom: 50px;

	h2 {
		padding: 0;
	}

	.results-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 30px;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	.show-past-input {
		label {
			position: relative;
			padding-left: 30px;
			font-weight: 600;
			cursor: pointer;
			&:before {
				width: 24px;
				height: 23px;
				border: 3px solid black;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				content: " ";
			}
		}
		input {
			position: absolute;
			left: -10000px;
		}
		input:checked {
			& + label {
				&:after {
					width: 8px;
					height: 13px;
					border-right: 3px solid black;
					border-bottom: 3px solid black;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translate(8px, -56%) rotate(33deg);
					content: " ";
				}
			}
		}
	}

	.results {
		font-size: 18px;
		font-weight: 500;
	}

	.filters-wrapper .main-filter-content {
		border: none;
	}

	.filters-container {
		> div {
			flex-basis: 50%;
			@include media-breakpoint-down(md) {
				flex-basis: 100%;
			}
		}
	}

}