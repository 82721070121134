.select-location {
	max-width: 1024px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 60px;
	
	.start-location {
		margin-bottom: 50px;
		@include media-breakpoint-down(md) {
			margin-bottom: 25px;
		}
		.error {
			margin-top: 10px;
		}
	}
	.location-input-wrapper {
		position: relative;
		width: 100%;
		input {
			margin-bottom: 0;
		}
		&:after {
			content: " ";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 4px;
			right: 4px;
			background: black;
			z-index: -1;
		}
	}
	.select {
		max-width: 350px;
		margin: 0 auto;
		.styled-select {
			width: 100%;
			position: relative;
			&:after {
				content: " ";
				background-image: url(/images/back-arrow.svg);
				right: 20px;
				top: 20px;
				position: absolute;
				background-repeat: no-repeat;
				background-size: contain;
				z-index: 1;
				width: 15px;
				height: 15px;
				transform: rotate(90deg);
			}
		}
		select {
			width: 100%;
			height: 50px;
			cursor: pointer;
			border-radius: 0;
			border: 2px solid black;
			background: white;
			padding: 10px 20px;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
		}
	}
}