.hub-navigation {
  background: #F4F4F4;
  padding: 50px 20px;
  @include media-breakpoint-down(md) {
    padding: 20px;
  }
  h2 {
    text-align: center;
    width: 100%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -20px;
    li {
      position: relative;
      background: white;
      border: 2px solid black;

      flex-basis: calc(33.333% - 40px);
      margin: 0 20px;
      @include media-breakpoint-down(md) {
        flex-basis: calc(100% - 40px);
        margin-bottom: 30px;
      }
      p {
        padding: 0 20px 15px 20px;
        margin: 0;
      }

      h3 {
        background: black;
        color: white;
        border: 2px solid white;
        padding: 5px 5px 8px 5px;
        text-align: center;

        transform: translate(-3px, -8px);
        a {
          color: white;
          font-family: $body-font-family;
          font-size: 18px;
          font-weight: 900;
          letter-spacing: 0.2px;
          line-height: 28px;
          text-align: center;
          img {
            width: 10px;
            height: 10px;
            display: inline-block;
            margin-left: 4px;
            margin-bottom: 2px;
          }
          &:after {
            content: " ";
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}