.embed-container {
  margin-top: 50px;
  margin-bottom: 50px;

  .embed {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.matterport-showcase iframe {
  min-height: 240px;
  margin: 16px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .matterport-showcase iframe {
    min-height: 360px;
  }
}

@media screen and (min-width: 992px) {
  .matterport-showcase iframe {
    height: 600px;
    max-width: 1068px;
    margin: 0 auto 15px;
  }
}