.map-listing {
  .results {
    margin: 40px 0;
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: $bold;
  }

  .map-listing-container {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .item {
      width: 100%;
      flex-basis: 25%;
      padding: 0 10px 10px 10px;
    }

    @include media-breakpoint-down(lg) {
      .item {
        flex-basis: 33.333%;
      }
    }
    @include media-breakpoint-down(md) {
      .item {
        flex-basis: 50%;
      }
    }
    @include media-breakpoint-down(sm) {
      .item {
        flex-basis: 100%;
      }
    }
  }
}

.artwork-card {
  border: 2px solid black;
  border-top: 16px solid black;
  margin-bottom: 30px;
  background: white;

  .past-artwork {
    padding: 10px;

    p {
      font-size: 16px;
      font-style: italic;
      line-height: 24px;
      text-align: center;
      margin: 0;
    }
  }

  .artwork-hub {
    padding: 5px 10px;
    margin-top: -15px;
    background-color: #F4F4F4;
    width: fit-content;
    position: relative;
    z-index: 10;
    p {
      font-size: 13px;
      font-weight: bold;
      line-height: 22px;
      margin: 0;
    }
  }

  &.no-image {
    .artwork-hub {
      margin-top: 15px;
    }
  }

  &.multi-image {
    .artwork-hub {
      margin-top: 0;
    }
  }

  .card-top {
    background: #F4F4F4;
    padding-top: 20px;
  }

  .artwork-name {
    font-size: 2.1rem;
    padding: 0 20px;
    line-height: 1.2;
    margin-bottom: 5px;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .artist {
    font-size: 1.8rem;
    padding: 0 20px;
  }

  .address {
    padding: 15px 0;

    img {
      width: 35px;
      margin-right: 5px;
      position: absolute;
      top: 2px;
      left: 15px;
    }

    p {
      margin-bottom: 0;
      font-weight: $bold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0 15px 0 60px;
      position: relative;
    }

    a.no-style {
      padding-left: 60px;
    }

    button {
      padding: 0 20px 0 60px;
    }
  }

  .artwork-dates {
    padding: 0 20px;
    p {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 0;
      border-bottom: 1px solid black;
      padding: 10px 0;
    }
  }

  .additional-info-card {
    padding-top: 15px;
    padding-bottom: 10px;

    p {
      margin-bottom: 7px;
      padding: 0 20px;
      font-size: 1.6rem;
      line-height: 1.3;

      span {
        font-family: $headings-font-family;
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }

  .image {
    height: 270px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  img {
    max-width: 100%;
  }
}

.blank-card {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding: 0;
  border: 2px solid black;
  background: #e8e8e8;
  margin-bottom: 80px;

  p {
    padding: 0;
    color: #4F4F4F;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 10px 0;
  }

  a {
    font-size: 18px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.learn-more-artwork {
  text-align: center;
  background-color: #F4f4f4;
  padding: 15px 0;

  a {
    text-decoration: underline;
    position: relative;

    &:after {
      position: absolute;
      right: -15px;
      display: block;
      top: 50%;
      width: 8px;
      transform: translateY(-45%);
      content: url(/images/right-arrow.svg);
      transition: right .2s linear;
    }

    &:hover {
      text-decoration: none;

      &:after {
        right: -17px;
      }
    }
  }
}
