.single-hub {

  // Flex component overrides
  .sponsors {
    h2 {
      border: none;
    }
  }

  .resource-carousel {
    background: transparent;
  }

  .embed-container {
    h2 {
      text-align: center;
    }
  }

  .wysiwyg {
    max-width: 900px;
    margin-top: 80px;
    margin-bottom: 70px;
    h2 {
      padding: 0;
    }
  }

  .text-with-sidebar {
    h2 {
      padding-left: 0;
    }
  }

  .self-hosted-video {
    margin-bottom: 50px;
    &:before {
      content: " ";
      height: 25%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #F4F4F4;
      z-index: -1;
    }
  }

  .social-share {
    border-bottom: 4px solid black;
    padding-bottom: 50px;
  }

}

.hubs {
  .wysiwyg {
    margin-bottom: 50px;
    padding-left: 30px;
    max-width: 1000px;
    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
      padding-left: 0;
    }
    h2 {
      padding-left: 0;
    }
  }
  .info-block {
    padding-left: 50px;
    margin-top: 0;
    @include media-breakpoint-down(md) {
      padding-left: 10px;
    }
    .content {
      max-width: 1000px;
      @include media-breakpoint-down(md) {
        padding-left: 20px;
      }
    }
  }
}