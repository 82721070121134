.art-for-sale-listing {
	margin-bottom: 100px;
}

.art-for-sale {
	#activeArtForSaleCategories-container {
		fieldset {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-column-gap: 20px;
			align-items: flex-start;
			@include media-breakpoint-down(md) {
				grid-template-columns: repeat(2, 1fr);
			}
			@include media-breakpoint-down(sm) {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}
}