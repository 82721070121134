.skip-to-content-link {
	&:focus {
		padding: 5px 10px;
		font-size: 18px;
		border-radius: 0;
		border: 2px solid black;
		background-color: white;
		color: black;
	}
}

.sr-only-focusable {
	&:focus {
		top: 0;
		left: 0;
		position: absolute;
		z-index: 2000;
	}
}

.hide {
	display: none;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
	outline: none;
}