.single-event {

  .past-event-single {
    padding: 10px 20px;
    background: #F4F4F4;
    position: relative;
    p {
      font-size: 21px;
      font-style: italic;
      line-height: 32px;
      text-align: center;
      color: black;
    }
    @include media-breakpoint-down(md) {
      padding: 5px 20px;
      p {
        font-size: 16px;
      }
    }
  }

  .event-categories {
    border: none;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      margin-right: 15px;
      margin-bottom: 8px;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
  .heading-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    h1 {
      margin: 0;
      padding: 0;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }
    .program-partner {
      font-size: 21px;
      font-weight: bold;
      line-height: 32px;
    }
    .favourite-button-wrapper {
      flex-shrink: 0;
      margin-left: 30px;
    }
    .favourite-button-container {
      background-color: #f1f1f1;
      padding: 10px 15px 15px 15px;
      margin-top: 20px;
    }

  }
  
  .event-attendance-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .details {
      padding: 30px;
      background-color: #f1f1f1;
      flex-basis: 55%;
      @include media-breakpoint-down(md) {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
      }
      .event-address {
        margin-bottom: 20px;
      }
      .calendar-link, .google-link {
        margin: 0;
        font-size: 14px;
        text-decoration: underline;
        font-weight: 300;
      }
      .calendar-link {
        margin-left: 13px;
      }
    }
    .how-to-attend {
      border-left: 3px solid black;
      padding-left: 30px;
      flex-basis: 40%;
      @include media-breakpoint-down(md) {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 28px;
        letter-spacing: 0;
        line-height: 33px;
        padding: 0;
        margin-bottom: 10px;
      }
    }
  }
  
  .event-image {
    width: 100%;
  }
  
  .event-content {
    width: 100%;
    border-bottom: 4px solid black;
    margin-bottom: 30px;
    .wysiwyg {
      max-width: 890px;
      margin: 50px auto;
      background-color: #F4F4F4;
      padding: 50px;
      .button {
        display: table;
        text-align: center;
        margin: 20px auto;
        font-size: 18px;
        color: white;
        background-color: black;
        border: 1px solid white;
        &:after {
          border: 1px solid black;
        }
      }
      p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      h2 {
        margin-bottom: 20px;
        padding-left: 0;
      }
    }
    .sponsors {
      h2 {
        border: none;
      }
    }
  }
}

.social-share {
  margin-bottom: 60px;
  text-align: center;
  h3 {
    font-size: 2.4rem;
    line-height: 2.9rem;
    text-align: center;
  }
  p {
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
  }
  .social-icons {
    display: flex;
    justify-content: center;
    a {
      margin: 5px;
      img {
        width: 40px;
      }
    }
  }
}

.related-events {
  margin-bottom: 50px;
  h4, h3 {
    margin-bottom: 20px;
  }
}