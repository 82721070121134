.cta-container {
  background-color: #F4F4F4;
  padding: 100px 0;
  @include media-breakpoint-down(sm) {
    padding: 50px 0;
  }
}

.cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  margin: 0 auto;
  width: 95%;
  max-width: 800px;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .image-container {
    border: 5px solid black;
    width: 100%;
    img {
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 60%;
    }
  }
  .cta-text {
    padding: 30px;
    h3 {
      color: white;
    }
    p {
      color: white;
      font-weight: $light-weight;
      font-size: 18px;
      line-height: 28px;
    }
  }
  a {
    color: white;
    text-decoration: underline;
    white-space: nowrap;
    position: relative;
    padding-right: 22px;
    &:after {
      position: absolute;
      content: " ";
      right: 0;
      bottom: 2px;
      width: 13px;
      height: 13px;
      background-image: url("/images/forward-arrow-white.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:hover {
      text-decoration: none;
    }
  }
}