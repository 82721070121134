.email-route-root {
	h1 {
		text-align: center;
		padding-left: 0;
	}
	h2 {
		padding-left: 0;
		margin-bottom: 20px;
	}
	.email-favourites {
		@include media-breakpoint-down(md) {
			padding: 30px 20px;
			margin-top: 0;
		}
	}
	.route-email-instructions {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: 900px;
		margin: 60px auto 0 auto;
		.back-link {
			font-size: 18px;
			font-weight: 500;
			line-height: 34px;
			&:before {
				top: 40%;
			}
		}
		.phone-image {
			margin-left: 40px;
			img {
				width: 150px;
				height: auto;
			}
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}
}