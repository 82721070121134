.sponsors {
  margin-bottom: 60px;
  h2 {
    border-bottom: 5px solid black;
    margin-bottom: 40px;
    padding-bottom: 10px;
  }
  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 10px;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    li {
      flex-basis: 33.333%;
      display: flex;
      align-items: stretch;
      justify-content: center;
      padding: 10px;
      margin-bottom: 25px;
      @include media-breakpoint-down(sm) {
        flex-basis: 50%;
      }
      a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .image-container {
          align-self: stretch;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          padding: 0 40px;
          @include media-breakpoint-down(md) {
            padding: 0 20px;
          }
          @include media-breakpoint-down(sm) {
            padding: 0;
          }
          img {
            max-height: 150px;
            max-width: 100%;
            width: 100%;
          }
        }
        .sponsor-name {
          align-self: center;
          text-align: center;
          margin-top: 10px;
          font-size: 16px;
          font-style: italic;
        }
      }

    }
  }
}