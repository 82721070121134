.create-a-route-container {
	h1, h2, h3 {
		text-align: center;
		margin-bottom: 10px;
	}
	.form-group {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		label {
			font-size: 19px;
			font-weight: 600;
			line-height: 1.4;
			margin-bottom: 10px;
		}
		input, select {
			border: 2px solid black;
			width: 100%;
			height: 60px;
			padding: 10px 20px;
			margin-bottom: 20px;
		}
	}
	.intro {
		text-align: center;
		font-weight: $regular;
		margin-bottom: 60px;
		font-size: 26px;
		letter-spacing: 0;
		line-height: 34px;
		font-family: $body-font-family;
		@include media-breakpoint-down(md) {
			font-size: 20px;
			line-height: 1.4;
			margin-bottom: 30px;
		}
	}
	.button-group {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
			margin-top: 20px;
		}
		.back-link {
			margin: 10px 40px;
		}
	}
	.next-button {
		text-align: center;
		a, button {
			font-size: 18px;
			font-weight: $bold;
			letter-spacing: 0.2px;
			line-height: 28px;
			text-align: center;
			display: inline-block;
			
			span {
				display: inline-block;
				background-image: url(/images/right-arrow-white.svg);
				width: 10px;
				height: 12px;
				margin-left: 5px;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}

}

.create-a-route-container, .email-route-root {
	.back-link {
		font-size: 18px;
		margin-top: 40px;
		&:before {
			top: 40%;
		}
	}
}
