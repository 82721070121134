.favourites-listing {

  .favourites-intro {
    display: flex;
    align-items: flex-start;
    background: #EFEFEF;
    padding: 75px 30px;
    h1 {
      padding: 0;
      margin-top: 0;
    }

    .container {
      .info-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        @include media-breakpoint-down(md) {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 50px;
        }
        img {
          display: block;
          margin-bottom: 10px;
        }
        h2 {
          padding: 0;
          margin-bottom: 10px;
          font-size: 36px;
          line-height: 1;
        }
        p {
          font-size: 18px;
          font-weight: 300;
          line-height: 1.4;
        }
        a {
          font-size: 18px;
          font-weight: 500;
          line-height: 1;
          text-decoration: underline;
          padding-right: 15px;
          margin-right: 15px;
          border-right: 1px solid black;
          &:hover {
            text-decoration: none;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
      .button-container {
        flex-basis: 27%;
        flex-shrink: 0;
        text-align: right;
        @include media-breakpoint-down(sm) {
          flex-basis: 100%;
          margin-top: 40px;
          text-align: center;
        }
      }
      .button {
        display: inline-block;
      }
    }
  }
  
  .favourite-section-intro {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 50px;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    h2 {
      margin-bottom: 20px;
    }
    p {
      margin: 0;
    }
  }
  
  .plan-a-route-link {
    padding: 25px 50px;
    display: flex;
    align-items: center;
    background-color: $lightgrey;
    margin-left: 40px;
    max-width: 350px;
    a {
      font-size: 18px;
    }
    p {
      font-size: 16px;
      line-height: 21px;
      font-weight: $bold;
      margin-bottom: 20px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
			text-align: center;
    }
    @include media-breakpoint-down(md) {
      margin: 30px auto 0 auto;
      padding: 25px;
      width: 100%;
      justify-content: center;
      flex-basis: 40%;
    }
  }
  .results {
    padding-left: 30px;
    font-family: $body-font-family;
    font-size: 2.1rem;
    font-weight: $bold;
    line-height: 1.3;
    margin-bottom: 60px;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
  .no-results {
    font-family: $body-font-family;
    font-size: 2.1rem;
    font-weight: $bold;
    line-height: 1.3;
    margin-bottom: 60px;
  }
  .map-listing-container {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      .item {
        flex-basis: 50%;
      }
    }
    @include media-breakpoint-down(sm) {
      .item {
        flex-basis: 100%;
        padding: 0;
      }
    }
  }
  .artwork-card {
    .address {
      button {
        display: none;
      }
    }
  }
}

.favourite-button-container {
  padding: 5px 20px 13px 20px;
  text-align: left;
  button {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;
    position: relative;
		text-decoration: none;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
		span {
			text-decoration: underline;
      line-height: 1.2;
		}
		img {
			margin-right: 8px;
		}
  }
}

.link-home {
  text-align: center;
  margin: 60px auto;
  p, h2 {
    font-size: 2.1rem;
    font-weight: 300;
    line-height: 1;
    text-align: center;
    margin-bottom: 10px;
    padding: 0;
    font-family: $body-font-family;

    @include media-breakpoint-down(md) {
      padding: 0 60px;
    }
  }
  a {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}