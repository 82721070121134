.tours-page {
	h1 {
		padding: 0;
	}

	.tour-header {
		max-width: 900px;

		p {
			font-size: 21px;
		}
	}

	.tour-filter-message-container {
		margin-bottom: 30px;
	}

	.filters-wrapper {
		margin-bottom: 30px;

		.filters-container {
			grid-template-columns: repeat(1, 1fr);
		}

		.filter-container {
			padding: 20px 40px;
			@include media-breakpoint-down(md) {
				padding: 15px;
			}
		}

		.filter-controls {
			display: none;
		}
	}


}