.tour-card {
	border: 2px solid black;
	border-top: 12px solid black;
	width: 100%;

	img {
		max-width: 100%;
		width: 100%;
	}

	.tour-card-content {
		padding: 20px;
		position: relative;
	}

	.tour-name {
		padding-top: 15px;
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.tour-sponsor {
		font-weight: 500;
		margin-bottom: 0;
		margin-top: 15px;
		font-size: 15px;
		span {
			font-family: $headings-font-family;
			font-size: 17px;
		}
	}

	.neighbourhood {
		background: #f4f4f4;
		position: absolute;
		top: -20px;
		left: 0;
		padding: 5px 20px 7px 20px;

		p {
			margin: 0;
			font-size: 15px;
			line-height: 22px;
			font-weight: 500;
		}
	}
}