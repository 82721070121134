.alternating-layout {
  .alternating-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
		position: relative;
		span[id] {
			position: absolute;
			top: -130px;
		}
    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 40px;
    }
    .image-container {
      flex-basis: 55%;
      @include media-breakpoint-down(md) {
        flex-basis:100%;
      }
      img {
        width: 100%;
      }
    }
    .content {
      flex-basis: 45%;
      padding: 40px;
      @include media-breakpoint-down(md) {
        flex-basis:100%;
        padding: 20px;
        width: 100%;
      }
      h2 {
        padding: 0;
        margin-bottom: 15px;
      }
      .tour-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        p {
          margin-bottom: 10px;
        }
        .ward {
          font-size: 21px;
          font-weight: 500;
          line-height: 1.3;
          margin-right: 13px;
        }
        .sponsor {
          padding: 5px 10px;
          background: #F4F4F4;
          font-size: 18px;
          line-height: 1.3;
          font-weight: 400;
          span {
            font-family: $headings-font-family;
          }
        }
      }
      .curator-name {
        font-size: 18px;
        line-height: 28px;
        font-weight: $bold;
        span {
          font-family: $headings-font-family;
         // text-transform: uppercase;
          font-size: 21px;
          line-height: 26px;
          margin-right: 5px;
        }
      }
      .excerpt {
        font-size: 18px;
        font-weight: $light-weight;
        line-height: 26px;
        margin-bottom: 25px;
      }
      a {
        text-decoration: underline;
        font-size: 18px;
        font-weight: $bold;
        line-height: 28px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &:nth-child(even) {
      .image-container {
        order: 2;
        @include media-breakpoint-down(md) {
          order: 1;
        }
      }
      .content {
        order: 1;
        @include media-breakpoint-down(md) {
          order: 2;
        }
      }
    }
  }
}