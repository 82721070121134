.sub-navigation {
	background: #F4F4F4;
	padding: 50px 30px;
	.container {
		text-align: center;
	}
	h2 {
		padding: 10px 20px;
		text-align: center;
		width: fit-content;
		background: black;
		color: white;
		display: block;
		margin: 0 auto;
	}
	ul {
		display: flex;
		list-style: none;
		margin: 40px 0 0 0;
		padding: 0;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		li {
			padding: 0 20px;
			margin: 0;
			border-right: 1px solid grey;
			a {
				text-decoration: underline;
				font-size: 18px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 1;
				text-align: center;
				&:hover {
					text-decoration: none;
				}
			}
			&:last-child {
				border: none;
			}
		}
	}
}