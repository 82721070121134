.social-accounts {
	margin-bottom: 20px;
	.account {
		margin-bottom: 5px;
		img {
			margin-right: 5px;
			width: 30px;
			height: 30px;
		}
		a {
			text-decoration: underline;
			font-size: 18px;
			line-height: 1;
			&:hover {
				text-decoration: none;
			}
		}
	}
}