.exhibition-callout {
  padding: 50px 20px;
  @include media-breakpoint-down(md) {
    padding: 20px;
  }
  .exhibition-details {
    display: flex;
    background: #F4F4F4;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
    .image-container {
      flex-basis: 55%;
      flex-shrink: 0;
      @include media-breakpoint-down(lg) {
        flex-basis: 100%;
      }
      img {
        width: 100%;
      }
    }
    .details {
      padding: 45px;
      @include media-breakpoint-down(sm) {
        padding: 20px;
      }
      h3 {
        background: black;
        color: white;
        padding: 7px 25px 7px 25px;
        width: fit-content;
        font-size: 48px;
        line-height: 58px;
        transform: translateX(-90px);
        margin-bottom: 30px;
        @include media-breakpoint-down(sm) {
          transform: translate(-40px, -40px);
          margin-bottom: 0;
        }
      }
      .excerpt {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
      }
      .button {
        display: inline-block;
        img {
          width: 10px;
          height: 10px;
          display: inline-block;
          margin-left: 4px;
          margin-bottom: 2px;
        }
      }
    }
  }
}