.flex-cta {
  .cta {
    align-items: flex-end;
    padding: 30px;
    .cta-text {
      padding: 0 30px 0 0;
      p:last-child {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }
}