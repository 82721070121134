.map {
	position: relative;

	.vue-map-hidden {
		display: block;
	}

	.vue-map-container {
		width: 100%;
		height: 80vh;
		@include media-breakpoint-down(sm) {
			height: 400px;
		}
	}

	.geolocation-button {
		position: absolute;
		width: 40px;
		height: 40px;
		padding: 10px;
		border-radius: 2px;
		background-color: white;
		box-shadow: 1px 1px 3px rgba(black, 0.1);
		bottom: 190px;
		right: 10px;
		display: flex;
		@include media-breakpoint-down(sm) {
			top: 170px;
			bottom: auto;
		}

		img {
			width: 100%;
		}

		&:hover {
			background-color: #f1f1f1;
		}
	}

	.borough-button {
		position: absolute;
		width: 170px;
		padding: 10px;
		border-radius: 0;
		background-color: white;
		box-shadow: 1px 1px 3px rgba(black, 0.1);
		bottom: 23px;
		right: 70px;
		border: 2px solid black;
		display: flex;
		flex-direction: column;

		p {
			font-size: 15px;
			font-weight: 500;
			text-align: center;
			line-height: 1;
			margin: 0 0 10px 0;
			padding: 0;
		}

		.states {
			display: flex;

			button {
				flex-basis: 50%;
				text-align: center;
				border: 2px solid black;
				background-color: white;
				color: black;
				padding: 2px 10px;

				&.active, &:hover {
					background-color: black;
					color: white;
				}
			}
		}
	}

	.info-content-container {
		position: relative;
		max-height: 80vh;
		overflow-y: scroll;
		@include media-breakpoint-down(sm) {
			position: relative;
			max-height: none;
		}

		.artwork-card {
			border: none !important;
			margin-bottom: 0 !important;
		}
	}

	#InfoWindow {
		position: absolute;
		top: 10px;
		left: 10px;
		width: 100%;
		max-width: 350px;
		background-color: white;
		border: 3px solid black;
		@include media-breakpoint-down(sm) {
			position: relative;
			height: auto;
			width: 95%;
			margin: 15px auto 0 auto;
			top: auto;
			left: auto;
			max-width: 100%;
		}

		img {
			max-width: 100%;
		}

		.close-bar {
			text-align: right;
			background-color: black;
			padding: 5px 10px;

			button {
				background: transparent;
				border: none;
				border-radius: 0;
				padding: 0;
				margin: 0;
				line-height: 1;
				color: white;
			}
		}

		.inner-window {
			position: relative;
			height: auto;
			width: 100%;
		}
	}

	.gm-style-iw .gm-style-iw-d {
		overflow: auto !important;

		&::-webkit-scrollbar {
			width: 0;
		}
	}

	.gm-style .gm-style-iw-c {
		padding: 0;
		border-radius: 0;
		border: 2px solid black;
		border-top: 8px solid black;
		font-family: $body-font-family;

		.gm-style-iw-d {
			position: relative;
			padding: 0;

			.info-content-container {
				position: relative;
				overflow: hidden;
			}
		}
	}
}

.artwork-cluster-card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid black;
	padding: 5px;
	position: relative;

	&:hover {
		background: #f4f4f4;
	}

	.image {
		background: black;
		width: 110px;
		align-self: stretch;
		flex-shrink: 0;
		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
			max-height: 75px;
		}
	}

	.details-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.details {
		padding: 10px;

		.artwork-name, p {
			margin-bottom: 0;
		}
		.artwork-name {
			@extend h5;
			font-size: 17px;
			text-align: left;
		}
		p {
			font-size: 13px;
		}
	}

	.select-button {
		font-size: 20px;
		color: black;
		margin-right: 20px;
		text-decoration: none;
	}

}

.artworks-container {
	.active-filters {
		margin-top: 30px;
		width: 100%;
		@include media-breakpoint-down(sm) {
			margin-top: 20px;
			padding: 0;
		}
	}
}

.filter-message-container {
	width: 100%;
	flex-basis: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	button {
		flex-shrink: 0;
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}
}

.filter-message {
	padding-right: 10%;
	font-size: 1.8rem;
	font-weight: 500;
	line-height: 30px;
	@include media-breakpoint-down(sm) {
		padding-right: 0;
		font-size: 18px;
		line-height: 1.4;
	}
}

#map-container {
	position: relative;
}

.loading {
	position: absolute;
	background: rgba(black, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	p {
		background: black;
		color: white;
		padding: 3px 12px 5px 12px;
		font-weight: 600;
		margin: 10px 0 0 0;
		letter-spacing: 1px;
	}
}

.map-intro {
	padding: 0 40px;

	@include media-breakpoint-down(md) {
		padding: 0 15px;
	}

	> div {
		display: flex;
		align-items: flex-start;
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
	}

	h2 {
		padding-left: 0;
	}

	h3 {
		margin-bottom: 30px;
	}

	p {
		font-size: 2.1rem;
		@include media-breakpoint-down(md) {
			font-size: 1.8rem;
		}
	}

	.plan-route-cta {
		flex-shrink: 0;
		margin-left: 50px;
		border: 4px solid black;
		padding: 30px 50px;
		max-width: 450px;
		width: 100%;
		text-align: center;
		@include media-breakpoint-down(md) {
			max-width: 100%;
			margin: 20px 0 0 0;
		}

		p {
			font-size: 1.8rem;
			margin-bottom: 20px;
		}

		h3 {
			margin-bottom: 10px;
		}

		.small-button {
			display: block;
		}
	}
}

.home-filters-section {
	background: #F4F4F4;
	margin-top: 50px;
	width: 100%;
	padding: 60px 30px;
	@include media-breakpoint-down(sm) {
		padding: 20px;
	}

	h2 {
		padding: 0;
	}

	.main-filter-content {
		.filters-container {
			grid-template-columns: repeat(1, 1fr);
		}
		.filter-controls {
			display: none;
		}
	}


}