.homepage {
	.accordions {
		padding: 30px 0;
	}

	.wysiwyg {
		max-width: 1000px;
		margin: 100px auto;
		width: 100%;

		* {
			padding-left: 0;
		}

		@include media-breakpoint-down(sm) {
			margin: 50px auto;
		}
	}

	.hero {
		.hero-title {
			bottom: 15%;

			.on-placement {
				transform: none;
				width: 60%;
			}

			@include media-breakpoint-down(sm) {
				bottom: 0;
				.on-placement {
					width: 70%;
				}
			}
		}
	}
}