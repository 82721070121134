.select-card {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	
	.card-content {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
	}
	
	@include media-breakpoint-down(md) {
		.details.show-for-mobile {
			flex-basis: 100%;
			margin-bottom: 5px;
		}
	}
	.details {
		& > * {
			text-align: left;
		}
		.artwork-name {
			font-size: 24px;
			line-height: 1.2;
			margin-bottom: 3px;
			text-align: left;
			@include media-breakpoint-down(md) {
				font-size: 21px;
			}
			a {
				text-decoration: underline;
				font-size: inherit;
				&:hover {
					text-decoration: none;
				}
			}
		}
		.artist {
			font-size: 18px;
			line-height: 1.2;
			@include media-breakpoint-down(md) {
				font-size: 15px;
			}
		}
		.address {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin-top: 10px;
			@include media-breakpoint-down(md) {
				margin-top: 0;
			}
			img {
				margin-right: 5px;
			}
			p {
				margin-bottom: 0;
			}
			.artwork-type {
				font-size: 18px;
				font-weight: $bold;
				line-height: 1.4;
				@include media-breakpoint-down(md) {
					font-size: 15px;
				}
			}
			.artwork-address {
				font-size: 16px;
				font-weight: $regular;
				line-height: 1.4;
				@include media-breakpoint-down(md) {
					font-size: 14px;
				}
			}
		}
	}
	.image {
		margin-right: 20px;
		width: 118px;
		height: 118px;
		background-color: black;
		flex-shrink: 0;
		@include media-breakpoint-down(md) {
			width: 80px;
			height: 80px;
			margin-right: 10px;
		}
		img {
			object-fit: contain;
			object-position: center center;
			width: 100%;
			height: 100%;
		}
	}
}

.select-artworks {
	max-width: 1024px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 60px;
	@include media-breakpoint-down(md) {
		padding-bottom: 30px;
	}
	.checkbox {
		margin-top: 20px;
		position: relative;
		z-index: 1;
		label {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			@include media-breakpoint-down(md) {
				align-items: flex-start;
			}
		}
		.check {
			background: white;
			width: 35px;
			height: 35px;
			border: 2px solid black;
			flex-shrink: 0;
			position: relative;
			margin-right: 15px;
			&:after {
				content: " ";
				background: black;
				width: 35px;
				height: 35px;
				right: 2px;
				top: 2px;
				position: absolute;
				z-index: -1;
			}
		}
		
		input {
			position: absolute;
			top: 0;
			left: 0;
			&:checked {
				& + label {
					background-color: #f9f8f8;
					
					.check {
						&:before {
							z-index: 1;
							content: " ";
							border: 5px solid black;
							border-top: none;
							border-left: none;
							width: 13px;
							height: 22px;
							top: 2px;
							right: 9px;
							position: absolute;
							transform: rotate(45deg);
						}
					}
				}
			}
		}
	}
	
	.artwork-options {
		margin-bottom: 50px;
		margin-top: 30px;
		label {
			border: 2px solid black;
			border-left: 15px solid black;
			padding: 10px 30px 15px 50px;
			width: 100%;
			margin-bottom: 0;
			position: relative;
			z-index: 1;
			background-color: white;
			@include media-breakpoint-down(md) {
				padding: 10px;
			}
			.select-card {
				margin-left: 40px;
				@include media-breakpoint-down(md) {
					margin-left: 4px;
				}
			}
		}
	}
}