.map-search-container, .blog-search-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.search-bar {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-end;
  }
  .reset-button {
    margin: 0 0 0 20px;
    @include media-breakpoint-down(md) {
      margin: 15px 0 0 0;
    }
    &:hover {
      text-decoration: none;
    }
  }
  #ArtworkSearch {
    flex-grow: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    label {
      margin-bottom: 10px;
      font-weight: 500;
      font-family: $headings-font-family;
      font-size: 2.6rem;
      line-height: 1.2;
    }
    input, button[type="submit"] {
      height: 60px;
      border: 3px solid black;
      @include media-breakpoint-down(md) {
        height: 55px;
      }
    }
    input[type="text"] {
      margin: 0;
      border-radius: 0;
      padding: 0 20px;
      position: relative;
      width: 100%;
      font-size: 1.8rem;
      line-height: 3.4rem;
      @include media-breakpoint-down(md) {
        font-size: 1.8rem;
        padding: 0 15px;

      }
    }
    .search-input-group {
      position: relative;
      display: flex;
      flex-grow: 1;
    }
    .search-input-wrapper {
      position: relative;
      flex-grow: 1;
      .clear-search {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        @include media-breakpoint-down(sm) {
          right: 15px;
          transform: translateY(-55%);
        }
      }
    }

    button[type="submit"] {
      width: 150px;
      margin: 0;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 4.6rem;
      padding-right: 30px;
      background-color: black;
      transition: all .2s linear;
      position: relative;
      text-align: right;
      padding-bottom: 5px;
      color: white;
      &:before {
        content: " ";
        background-image: url("/images/icon-search-rev.svg");
        position: absolute;
        width: 20px;
        height: 20px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: white;
        color: black;
        &:before {
          background-image: url("/images/icon-search.svg");
        }
      }
      @include media-breakpoint-down(md) {
        font-size: 2.0rem;
        line-height: 2.0rem;
        padding-right: 15px;
        width: 130px;
  
        &:before {
          width: 20px;
          height: 20px;
          left: 15px;
        }
      }
      @include media-breakpoint-down(sm) {
        width: 150px;
      }
    }
  }
}

.search-container {
  position: relative;
  width: 100%;
}

.search-messages {
  margin-top: 15px;
  p {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
}