.route-directions, .route-order-edit {
	padding: 20px 30px;
	@include media-breakpoint-down(lg) {
		max-height: 100%;
		overflow: auto;
		padding: 20px 20px 20px 10px;
	}
}

.leg {
	display: flex;
	flex-shrink: 0;
	&:last-child {
		.order-number {
			&:after {
				height: calc(100% - 95px);
			}
		}
	}
	.order-number {
		position: relative;
		margin-right: 15px;
		padding-bottom: 95px;
		justify-content: flex-end;
		align-items: center;
		@include media-breakpoint-down(md) {
			margin-right: 5px;
		}
		&:after {
			position: absolute;
			left:0;
			right: 0;
			margin: 0 auto;
			height: 100%;
			width: 1px;
			top: 0;
			border-left: 2px dashed black;
			content: " ";
		}
		span {
			width: 40px;
			height: 40px;
			color: white;
			background: black;
			display: block;
			text-align: center;
			border-radius: 500px;
			line-height: 36px;
			font-size: 21px;
			font-weight: 500;
			z-index: 1;
			@include media-breakpoint-down(md) {
				line-height: 26px;
				font-size: 16px;
				width: 30px;
				height: 30px;
			}
		}
	}
	
	& > div {
		display: flex;
		flex-direction: column;
		&:last-child {
			flex-grow: 1;
		}
		.total-leg {
			font-size: 14px;
			font-weight: $bold;
			line-height: 1;
			padding-bottom: 7px;
			margin-bottom: 20px;
			padding-top: 20px;
			border-bottom: 3px solid black;
		}
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			margin-left: 0;
			margin-bottom: 30px;
			@include media-breakpoint-down(lg) {
				margin-left: 0;
			}
			li {
				position: relative;
				border-bottom: 1px solid lightgrey;
				padding: 14px 0 22px 0;
				p {
					font-size: 16px;
					line-height: 1.4;
					margin-bottom: 0;
				}
				.details {
					font-size: 14px;
					font-weight: $bold;
					line-height: 1;
					position: absolute;
					bottom: -2px;
					span {
						background: white;
						padding-right: 10px;
					}
				}
			}
		}
	}
}


.route-order-edit {
	.leg {
		& > div:last-child {
			flex-direction: row;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid black;
		}
		.order-number {
			padding-bottom: 82px;
		}
	}
	.arrow-buttons {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin-right: 15px;
		button {
			background: none;
			border: none;
			font-size: 25px;
		}
		@include media-breakpoint-down(md) {
			margin-right: 8px;
			button {
				font-size: 18px;
			}
		}
	}
}
