.event-card {
  border: 3px solid black;
  border-top: 10px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
  .event-card-image {
    position: relative;
    img {
      width: 100%;
    }
  }
  .favourite-button-container {
    padding: 10px 0 0 0;
  }
}

.event-card-details, .event-card-intro {
  padding: 15px 20px 20px 20px;
}

.event-program-partner {
  background-color: #F4F4F4;

  p {
    background: white;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    line-height: 22px;
    width: fit-content;
    margin-bottom: -15px;
    transform: translateY(-15px);

  }
}

.past-event {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(white, 0.9);
  padding: 10px 10px 13px 10px;
  p {
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    margin: 0;
  }
}

.event-card-intro {
  background-color: #F4F4F4;
}

.event-card-content {
  .event-name {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 0;
    a {
      text-decoration: underline;
      font-size: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .event-times, .event-address {
    position: relative;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 5px;
    }
  }
  
  .event-description {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.6rem;
  }
  
}

.calendar-link {
  margin-left: 15px;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 20px;
  &:before {
    content: "+";
    position: absolute;
    left: -15px;
  }
}

.event-categories {
  border-top: 1px solid grey;
  padding-top: 10px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 15px;
      line-height: 26px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
    }
  }
}

.diamond-icon {
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  border: 2px solid black;
  background-color: white;
  margin-right: 10px;
}