.image-carousel {
	.hooper-list {
		background: black;
	}
	.hooper-track {
		align-items: center;
	}
	.hooper-slide {
		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}
	.carousel-control {
		.controls {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 20px;
			.item-count {
				margin: 0 auto;
				text-align: center;
				font-size: 15px;
			}
			button {
				width: 35px;
				background: transparent;
				border: none;
				cursor: pointer;
				padding: 0;
			}
		}
	}
}

.artwork-card {
	.hooper-slide {
		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
			max-height: 260px;
		}
	}
}

.single-artwork {
	.artwork-image-carousel {
		.hooper-slide {
			img {
				object-fit: contain;
				height: 100%;
				width: 100%;
				max-height: 600px;
			}
		}
		.controls {
			button {
				img {
					width: 100%;
				}
			}
		}
	}
}