.tour-listing {
	margin: 50px auto;

	.tour-cards {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 25px;
		@include media-breakpoint-down(md) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}