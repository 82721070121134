.hubs-listing {
  .block {
    padding: 50px 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    span[id] {
      position: absolute;
      top: -130px;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 30px 20px;
    }
    .image-container {
      flex-basis: 40%;
      @include media-breakpoint-down(md) {
        flex-basis:100%;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
      }
    }
    .content {
      flex-basis: 60%;
      padding: 0 0 0 50px;
      @include media-breakpoint-down(md) {
        flex-basis:100%;
        padding: 0;
        width: 100%;
      }
      h2 {
        padding: 0;
        margin-bottom: 15px;
        font-size: 36px;
        line-height: 1.2;
      }
      .hub-location {
        img {
          margin-right: 8px;
        }
        font-size: 15px;
        line-height: 22px;
        font-weight: $bold;
      }

      .excerpt {
        font-weight: $light-weight;
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 22px
      }
      .hub-links {
        span {
          padding: 0 10px;
        }
      }
      .popup-dates {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        font-size: 15px;
        line-height: 22px;
        font-weight: $bold;
        .dashed-circle {
          width: 18.5px;
          height: 18.5px;
          border-radius: 500px;
          border: 2px dashed black;
          background: #EDEDED;
          display: inline-block;
          margin-right: 8px;
        }
        span {
          margin-right: 8px;
          font-weight: bold;
        }
      }
      a {
        text-decoration: underline;
        font-size: 18px;
        font-weight: $bold;
        line-height: 28px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &:nth-child(odd) {
      background: #F4F4F4;
    }
  }
}

.curator-name {
  font-size: 18px;
  line-height: 28px;
  font-weight: $bold;
  span {
    font-family: $headings-font-family;
    font-size: 21px;
    line-height: 26px;
    margin-right: 5px;
  }
}

.popups-listing-container {
  margin-top: 50px;
  margin-bottom: 50px;
  .popup-listing-intro {
    margin-bottom: 50px;
    border-top: 2px solid black;
    padding: 50px 30px 0 30px;
    h2 {
      padding: 0;
    }
  }
}
