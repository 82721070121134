.route-instructions {
	background: black;
	color: white;
	padding: 20px 30px;
	position: sticky;
	bottom: 0;
	z-index: 2;
	@include media-breakpoint-down(lg) {
		padding: 10px 20px;
		z-index: 10;
	}
	.finish-instructions, .order-instructions {
		display: flex;
		align-items: center;
		@include media-breakpoint-down(lg) {
			flex-direction: column;
			> div, > p, > button {
				padding-bottom: 20px;
				text-align: center;
			}
		}
		p {
			font-size: 16px;
			font-weight: $light-weight;
			line-height: 24px;
			margin-bottom: 0;
			@include media-breakpoint-down(lg) {
				font-size: 14px;
			}
		}
		strong {
			font-weight: $bolder;
		}
		.buttons {
			flex-shrink: 0;
		}
		button {
			font-size: 18px;
			font-weight: $bold;
			letter-spacing: 0.2px;
			line-height: 28px;
			margin-left: 15px;
		}
		.styled-button {
			padding: 6px 20px;
			font-size: 18px;
			line-height: 28px;
			margin-left: 20px;
			font-weight: $bolder;
			display: inline-block;
		}
		.finished-button {
			padding-left: 38px;
			position: relative;
			text-transform: uppercase;
			&:before {
				content: " ";
				border-right: 4px solid black;
				border-bottom: 4px solid black;
				width: 9px;
				height: 15px;
				position: absolute;
				top: 10px;
				left: 18px;
				background: transparent;
				transform: rotate(37deg);
			}
		}
		.no-style {
			color: white;
		}
	}
}
