.map-legend {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: 2px solid black;
  box-shadow: 0 0 10px rgba(black, 0.2);
  z-index: 10;
  .legend-header {
    background-color: black;
    color:white;
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 15px 30px;
    //text-transform: uppercase;
    font-size: 2.1rem;
    line-height: 2.6rem;
    margin-bottom: 0;
    letter-spacing: 0.9px;
    text-decoration: none;
    font-family: $headings-font-family;
    position: relative;
    &:after {
      content: "+";
      color: inherit;
      font-size: 3rem;
      position: absolute;
      font-family: inherit;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: lighten(black, 10%);
    }
    &.open {
      &:after {
        content: "–";
      }
    }
  }
  .legend-items {

    .legend-item input[type="checkbox"] {
      opacity: 0;
      position: absolute;
    }

    .legend-item label {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 10px 0 10px 40px;
      margin: 0;
    }

    .legend-item label::before,
    .legend-item label::after {
      position: absolute;
      content: "";
      display: inline-block;
    }

    .legend-item label::before{
      height: 20px;
      width: 20px;
      background-color: black;
      border: 1px solid;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    .legend-item label::after {
      height: 6px;
      width: 10px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      top: 48%;
      left: 20px;
      transform: translateY(-50%) rotate(-45deg);
    }

    .legend-item input[type="checkbox"] + label::after {
      content: none;
    }

    .legend-item input[type="checkbox"]:checked + label::after {
      content: "";
    }

    .legend-item input[type="checkbox"]:checked + label {
      background-color: #EFEFEF;
    }

    .legend-item input[type="checkbox"]:focus + label::before {
      outline: black dotted 1px;
    }
  }
  &.open {

  }
}