.announcement {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 40px;
	border-top: 2px solid black;
	border-bottom: 2px solid black;
	@include media-breakpoint-down(md) {
		flex-direction: column;
		align-items: flex-start;
		padding: 30px;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 10px;
	}

	.image-container {
		flex-basis: 33%;
		flex-shrink: 0;
		width: 100%;
		@include media-breakpoint-down(md) {
			margin-bottom: 20px;
			flex-basis: 100%;
			width: 100%;
		}

		img {
			width: 100%;
			max-width: 100%;
		}
	}

	.content {
		padding: 0 30px 0 40px;

		h3 {
			font-size: 36px;
			line-height: 44px;
		}

		p {
			font-size: 18px;
			font-weight: 300;
			line-height: 26px;
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(md) {
			padding: 0;
		}

		a {
			text-decoration: underline;
			font-size: 21px;
			font-weight: 500;
			line-height: 34px;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.publish-date {
		margin-top: 10px;
	}

	.event-sessions {
		margin-bottom: 20px;
		p {
			margin-bottom: 3px;
			font-weight: 400;
		}
	}

	&.press {
		.content {
			position: relative;
			padding-left: 130px;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100px;
				height: 100px;
				border-radius: 500px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
				display: block;
				content: " ";
			}

			@include media-breakpoint-down(md) {
				padding-left: 90px;
				&:before {
					width: 70px;
					height: 70px;
				}
			}

			&.video {
				&:before {
					background-image: url("/images/video.svg");
				}
			}

			&.document, &.article {
				&:before {
					background-image: url("/images/document.svg");
				}
			}

			&.audio {
				&:before {
					background-image: url("/images/audio.svg");
				}
			}

			&.press-release {
				&:before {
					background-image: url("/images/press-release.svg");
				}
			}
		}
	}
}