.gallery {
	background-color: #F4f4f4;
	padding: 100px 0 70px 0;
	margin: 80px auto;
	@include media-breakpoint-down(sm) {
		padding: 30px 0;
		margin: 30px auto;
	}
}

.vue-gallery {
	display: flex;
}

.thumbnail-nav {
	flex-basis: 15%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	@include media-breakpoint-down(sm) {
		display: none;
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		background-color: transparent;
		margin-bottom: 15px;
		border: 3px solid transparent;

		img {
			max-width: 150px;
			max-height: 120px;
			@include media-breakpoint-down(sm) {
				max-width: 15%;
				max-height: 50px;
			}
		}

		&.active {
			border: 3px solid black;
		}
	}
}

.main-image {
	padding: 30px;
	width: 85%;
	@include media-breakpoint-down(sm) {
		width: 100%;
		padding: 0;
	}

	.image-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 600px;
		background-color: black;
		@include media-breakpoint-down(sm) {
			height: 350px;
		}

		img {
			max-height: 100%;
			max-width: 100%;
			object-fit: contain;
		}
	}
}

.image-info {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px 0;

	p {
		margin-bottom: 0;
	}

	.current-count {
		padding: 0 15px;
		font-size: 18px;
		line-height: 22px;
		font-family: $headings-font-family;
		//text-transform: uppercase;
	}

	.left-controls, .right-controls {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			background-color: transparent;
			border: 2px solid transparent;

			.arrow {
				width: 18px;
			}
		}
	}

	.caption {
		padding: 0 20px;
		flex-grow: 1;
		width: auto;
		p {
			margin: 0;
			font-size: 15px;
			font-weight: $light-weight;
			line-height: 20px;
		}
	}
}

.arrow {
	width: 25px;
}