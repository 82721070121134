.back-to-top {
  padding: 15px 15px;
  position: fixed;
  border: 2px solid white;
  bottom: 20px;
  right: -2px;
  background-color: black;
  color: white;
  z-index: 10;
  vertical-align: middle;
  display: flex;
}