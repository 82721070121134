.featured-event {
  background-color: #F4F4F4;
  padding: 40px 30px 80px 30px;
  h2 {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 20px 0;
    h2 {
      padding-left: 15px;
    }
  }
  .event-program-partner {
    margin-bottom: 10px;
    transform: translateX(-10px);

    p {
      transform: none;
      margin: 0;
    }
  }
  .event-categories {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    li {
      background: white;
      display: flex;
      padding: 3px 10px;
      margin-right: 10px;
      margin-bottom: 10px
    }
  }
  .event-categories {
    border-top: 0;
  }
  .event-card {
    flex-direction: row;
    border: none;
    padding: 40px 0 0 0;
    margin-bottom: 0;
    .event-card-image {
      flex-basis: 50%;
    }
    .event-card-intro, .event-card-details {
      padding: 0;
      width: 100%;
    }
    .event-card-intro {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid grey;
    }
    .event-card-content {
      flex-basis: 50%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .event-card-text {
        padding: 0;
      }
      .event-name {
        font-size: 36px;
        line-height: 44px;
      }
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 20px 0;
      flex-basis: 100%;
      .event-card-image {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      .event-card-content {
        flex-basis: 100%;
        padding-left: 0;
      }
    }
  }
}
