@charset "UTF-8";

/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --blue: #3490dc;
  --indigo: #6574cd;
  --purple: #9561e2;
  --pink: #f66d9b;
  --red: #e3342f;
  --orange: #f6993f;
  --yellow: #ffed4a;
  --green: #38c172;
  --teal: #4dc0b5;
  --cyan: #6cb2eb;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: black;
  --secondary: #6c757d;
  --success: #38c172;
  --info: #6cb2eb;
  --warning: #ffed4a;
  --danger: #e3342f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #f8fafc;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
.artwork-cluster-card .details .artwork-name,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: black;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
.artwork-cluster-card .details .artwork-name,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: din-condensed, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 4rem;
}

h2,
.h2 {
  font-size: 3.2rem;
}

h3,
.h3 {
  font-size: 2.8rem;
}

h4,
.h4 {
  font-size: 2.4rem;
}

h5,
.artwork-cluster-card .details .artwork-name,
.h5 {
  font-size: 2rem;
}

h6,
.h6 {
  font-size: 1.6rem;
}

.lead {
  font-size: 2rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #f66d9b;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1300px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8b8b8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-primary:hover {
  background-color: #ababab;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ababab;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7eed8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #98dfb6;
}

.table-hover .table-success:hover {
  background-color: #b3e8ca;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b3e8ca;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d6e9f9;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b3d7f5;
}

.table-hover .table-info:hover {
  background-color: #c0ddf6;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c0ddf6;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fffacc;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fff6a1;
}

.table-hover .table-warning:hover {
  background-color: #fff8b3;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff8b3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7c6c5;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f09593;
}

.table-hover .table-danger:hover {
  background-color: #f4b0af;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4b0af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #404040;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 2rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #38c172;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(56, 193, 114, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #38c172;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #38c172;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #38c172;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #38c172;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5cd08d;
  background-color: #5cd08d;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(227, 52, 47, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e3342f;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e3342f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e3342f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e3342f;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9605c;
  background-color: #e9605c;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-primary:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-success:hover {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2d995b;
  border-color: #2a9055;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-info:hover {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f9ae5;
  border-color: #3495e3;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffe817;
  border-color: #ffe70a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c51f1a;
  border-color: #b91d19;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: black;
  border-color: black;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: black;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #38c172;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-info {
  color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #6cb2eb;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-warning {
  color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffed4a;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-danger {
  color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e3342f;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: black;
  text-decoration: none;
}

.btn-link:hover {
  color: black;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.6rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top],
.dropdown-menu[x-placement^=right],
.dropdown-menu[x-placement^=bottom],
.dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: black;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 2.56rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.78rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: black;
  background-color: black;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #404040;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #595959;
  border-color: #595959;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.78rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.78rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: black;
  background-color: black;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.78rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #404040;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.4rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 2rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #404040;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: black;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #595959;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: black;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #595959;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: black;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #595959;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8fafc;
  border-color: #dee2e6 #dee2e6 #f8fafc;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: black;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.18rem;
  padding-bottom: 0.18rem;
  margin-right: 1rem;
  font-size: 2rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: black;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: black;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: black;
  border-color: black;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 2rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: black;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: black;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #38c172;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #2d995b;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #6cb2eb;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #212529;
  background-color: #3f9ae5;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffed4a;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #ffe817;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e3342f;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #c51f1a;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.9rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-primary hr {
  border-top-color: #ababab;
}

.alert-primary .alert-link {
  color: black;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #c7eed8;
}

.alert-success hr {
  border-top-color: #b3e8ca;
}

.alert-success .alert-link {
  color: #123c24;
}

.alert-info {
  color: #385d7a;
  background-color: #e2f0fb;
  border-color: #d6e9f9;
}

.alert-info hr {
  border-top-color: #c0ddf6;
}

.alert-info .alert-link {
  color: #284257;
}

.alert-warning {
  color: #857b26;
  background-color: #fffbdb;
  border-color: #fffacc;
}

.alert-warning hr {
  border-top-color: #fff8b3;
}

.alert-warning .alert-link {
  color: #5d561b;
}

.alert-danger {
  color: #761b18;
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}

.alert-danger hr {
  border-top-color: #f4b0af;
}

.alert-danger .alert-link {
  color: #4c110f;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 1.2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: black;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #1d643b;
  background-color: #c7eed8;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1d643b;
  background-color: #b3e8ca;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1d643b;
  border-color: #1d643b;
}

.list-group-item-info {
  color: #385d7a;
  background-color: #d6e9f9;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #385d7a;
  background-color: #c0ddf6;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #385d7a;
  border-color: #385d7a;
}

.list-group-item-warning {
  color: #857b26;
  background-color: #fffacc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #857b26;
  background-color: #fff8b3;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857b26;
  border-color: #857b26;
}

.list-group-item-danger {
  color: #761b18;
  background-color: #f7c6c5;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #761b18;
  background-color: #f4b0af;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #761b18;
  border-color: #761b18;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: black !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: black !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #38c172 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2d995b !important;
}

.bg-info {
  background-color: #6cb2eb !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3f9ae5 !important;
}

.bg-warning {
  background-color: #ffed4a !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe817 !important;
}

.bg-danger {
  background-color: #e3342f !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c51f1a !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: black !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #38c172 !important;
}

.border-info {
  border-color: #6cb2eb !important;
}

.border-warning {
  border-color: #ffed4a !important;
}

.border-danger {
  border-color: #e3342f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: black !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: black !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #38c172 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #27864f !important;
}

.text-info {
  color: #6cb2eb !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #298fe2 !important;
}

.text-warning {
  color: #ffed4a !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #fde300 !important;
}

.text-danger {
  color: #e3342f !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ae1c17 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  padding: 5px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #FFF;
}

.hamburger-box {
  width: 35px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 35px;
  height: 3px;
  background-color: #FFF;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
 * Squeeze
 */

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.skip-to-content-link:focus {
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 0;
  border: 2px solid black;
  background-color: white;
  color: black;
}

.sr-only-focusable:focus {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2000;
}

.hide {
  display: none;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

.show-for-mobile {
  display: block;
}

@media (min-width: 768px) {
  .show-for-mobile {
    display: none;
  }
}

.hide-for-mobile {
  display: none;
}

@media (min-width: 768px) {
  .hide-for-mobile {
    display: block;
  }
}

html {
  font-size: 62.5%;
}

body {
  font-family: sofia-pro, sans-serif;
  font-size: 1.6rem;
  padding-top: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 b,
h1 strong,
h2 b,
h2 strong,
h3 b,
h3 strong,
h4 b,
h4 strong,
h5 b,
.artwork-cluster-card .details .artwork-name b,
h5 strong,
.artwork-cluster-card .details .artwork-name strong,
h6 b,
h6 strong {
  font-weight: 500;
}

.styled-button,
.button {
  position: relative;
  padding: 10px 30px 13px 30px;
  line-height: 1;
  color: black;
  background-color: white;
  border: 2px solid black;
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
  border-radius: 0;
}

.styled-button:after,
.button:after {
  content: " ";
  width: 100%;
  height: 100%;
  border: 2px solid white;
  position: absolute;
  bottom: -5px;
  left: -5px;
  transition: all 0.15s linear;
}

.styled-button:hover,
.button:hover {
  text-decoration: none;
}

.styled-button:hover:after,
.button:hover:after {
  bottom: -8px;
  left: -8px;
}

.styled-button:active:after,
.button:active:after {
  bottom: -2px;
  left: -2px;
}

@media (max-width: 767.98px) {
  .styled-button,
  .button {
    font-size: 2.1rem;
    line-height: 3.2rem;
  }
}

.styled-button.reversed,
.button.reversed {
  color: white;
  background-color: black;
  border: 2px solid white;
}

.styled-button.reversed:after,
.button.reversed:after {
  border: 2px solid black;
}

.styled-button[disabled],
.button[disabled] {
  background-color: #6B6B6B;
  color: #C7C7C7;
  border: #6B6B6B;
  padding: 14px 30px 18px 30px;
  font-size: 20px;
  font-weight: 600;
}

.styled-button[disabled]:after,
.button[disabled]:after {
  border: none;
}

.styled-button.small-button,
.button.small-button {
  font-size: 1.8rem;
}

.no-style {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.no-style:hover {
  text-decoration: none;
}

.caption {
  margin-bottom: 25px;
  margin-top: 15px;
  width: 100%;
  padding: 0 10px;
}

.caption p {
  border-left: 3px solid black;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 300;
}

@media (max-width: 991.98px) {
  .caption {
    padding: 0;
  }
}

a.no-style {
  padding-left: 40px;
}

.load-more {
  text-align: center;
  padding: 50px 20px;
  background-color: #F4F4F4;
}

.load-more .styled-button {
  padding: 18px 40px 20px;
}

.load-more.no-bg {
  background-color: transparent;
}

h1 {
  font-size: 7.2rem;
  line-height: 1.1;
  margin-bottom: 40px;
  margin-top: 80px;
  padding-left: 30px;
}

@media (max-width: 991.98px) {
  h1 {
    font-size: 4.7rem;
    line-height: 1;
    margin-bottom: 30px;
    margin-top: 60px;
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  h1 {
    margin-top: 50px;
  }
}

h2 {
  font-size: 4.8rem;
  line-height: 5.8rem;
  padding-left: 30px;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  h2 {
    font-size: 3.5rem;
    line-height: 1.2;
    padding-left: 0;
    margin-bottom: 20px;
  }
}

h3,
.h3 {
  font-size: 2.8rem;
}

@media (max-width: 991.98px) {
  h3,
  .h3 {
    font-size: 2.4rem;
  }
}

p {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
}

.back-link {
  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  position: relative;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 20px;
  display: flex;
  width: fit-content;
}

.back-link:hover {
  text-decoration: none;
}

.back-link:before {
  position: absolute;
  left: -20px;
  display: block;
  top: 50%;
  transform: translateY(-33%) rotate(180deg);
  content: url("/images/back-arrow.svg");
}

.error {
  font-size: 16px;
  color: #e3342f;
  font-weight: 500;
}

a:focus,
button:focus,
input:focus,
select:focus {
  outline: dodgerblue auto 1px;
  outline-offset: 1px;
  box-shadow: 0 0 2px white;
}

body {
  padding-top: 157px;
  transition: all 0.15s linear;
}

@media (max-width: 767.98px) {
  body {
    padding-top: 103px;
  }
}

@media (min-width: 768px) {
  body.has-scrolled {
    padding-top: 108px;
  }

  body.has-scrolled .main-nav {
    margin-top: 17px;
  }

  body.has-scrolled header {
    padding: 10px 0 15px 0;
  }

  body.has-scrolled header .navbar-brand img {
    max-width: 200px;
  }

  body.has-scrolled .nav-favourites a {
    font-size: 22px;
  }

  body.has-scrolled .nav-favourites .nav-heart img {
    width: 34px;
  }

  body.has-scrolled .nav-favourites .nav-heart .count {
    top: 0;
    right: -11px;
  }

  body.has-scrolled .top-bar {
    padding-bottom: 13px;
  }

  body.has-scrolled .top-bar .city-logo img {
    width: 100px;
  }
}

header {
  background-color: black;
  padding: 16px 0 25px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: all 0.15s linear;
}

@media (max-width: 767.98px) {
  header {
    padding: 10px 0 15px 0;
  }
}

header .translation-selection {
  width: 100%;
  max-width: 650px;
}

@media (max-width: 991.98px) {
  header .translation-selection {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  header .translation-selection {
    margin-bottom: 30px;
  }
}

header .translation-selection label {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 24px;
  margin-bottom: 10px;
}

header .translation-selection select {
  border-radius: 0;
  border: 2px solid white;
  background-color: black;
  color: white;
  padding: 8px 40px 10px 13px;
  height: auto;
  display: block;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  width: 100%;
}

header .translation-selection select option {
  background-color: black;
  color: white;
}

header .container {
  position: relative;
}

header .navbar-brand {
  display: flex;
  text-align: center;
  overflow: auto;
  width: fit-content;
}

header .navbar-brand * {
  transition: all 0.15s linear;
}

header .navbar-brand img {
  max-width: 260px;
}

@media (max-width: 767.98px) {
  header .navbar-brand {
    display: flex;
  }

  header .navbar-brand img {
    max-width: 170px;
  }
}

header .hamburger {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  header .hamburger {
    right: 10px;
  }
}

.main-nav {
  margin-top: 25px;
}

@media (max-width: 767.98px) {
  .main-nav {
    margin-top: 15px;
  }
}

.main-nav * {
  transition: all 0.15s linear;
}

.top-bar {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding-bottom: 23px;
}

@media (max-width: 767.98px) {
  .top-bar {
    padding-bottom: 15px;
  }
}

.top-bar .city-logo img {
  width: 130px;
  height: auto;
  transition: all 0.15s linear;
}

@media (max-width: 767.98px) {
  .top-bar .city-logo img {
    width: 90px;
  }
}

.nav-favourites {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
  border-right: 1px solid white;
  padding-right: 15px;
}

.nav-favourites * {
  transition: all 0.15s linear;
}

@media (max-width: 767.98px) {
  .nav-favourites {
    right: 60px;
    padding-right: 10px;
  }
}

.nav-favourites .nav-heart {
  margin-right: 15px;
  position: relative;
}

@media (max-width: 767.98px) {
  .nav-favourites .nav-heart {
    margin-right: 8px;
  }

  .nav-favourites .nav-heart img {
    width: 30px;
    height: auto;
  }
}

.nav-favourites .nav-heart .count {
  position: absolute;
  top: -5px;
  right: -9px;
  border: 2px solid white;
  background: black;
  border-radius: 500px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 1;
  font-family: sofia-pro, sans-serif;
}

@media (max-width: 767.98px) {
  .nav-favourites .nav-heart .count {
    top: 0;
    right: -9px;
    width: 18px;
    height: 18px;
    font-size: 10px;
  }
}

.nav-favourites .nav-heart .count span {
  display: block;
  margin-top: -2px;
}

.nav-favourites a {
  color: white;
  display: flex;
  align-items: center;
  font-family: din-condensed, sans-serif;
  font-size: 25px;
  line-height: 33px;
}

.nav-favourites a:hover {
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .nav-favourites a .icon-label {
    display: none;
  }
}

#NavContainer {
  background-color: black;
  color: white;
  padding: 30px 0;
  overflow-y: scroll;
}

@media (min-width: 768px) {
  #NavContainer {
    max-height: calc(100vh - 110px);
  }
}

@media (max-width: 767.98px) {
  #NavContainer {
    padding: 15px 0;
  }
}

#NavContainer nav {
  margin-bottom: 50px;
}

@media (max-width: 767.98px) {
  #NavContainer nav {
    margin-bottom: 30px;
  }
}

#NavContainer nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#NavContainer nav ul li {
  font-family: din-condensed, sans-serif;
  color: white;
  margin-top: 20px;
  transition: all 0.2s linear;
  border-bottom: 1px solid white;
}

#NavContainer nav ul li > a,
#NavContainer nav ul li > button {
  font-family: din-condensed, sans-serif;
  color: white;
  font-size: 4.8rem;
  line-height: 1.4;
}

#NavContainer nav ul li > a:hover,
#NavContainer nav ul li > button:hover {
  text-decoration: none;
}

#NavContainer nav ul li > button {
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  text-align: left;
}

@media (max-width: 767.98px) {
  #NavContainer nav ul li {
    margin-top: 15px;
  }

  #NavContainer nav ul li > a,
  #NavContainer nav ul li > button {
    font-size: 2.6rem;
    line-height: 1.4;
  }
}

#NavContainer nav ul li:hover {
  border-bottom: 1px solid grey;
}

#NavContainer nav ul li.has-items {
  position: relative;
  cursor: pointer;
}

#NavContainer nav ul li.has-items ul li a:hover {
  text-decoration: underline;
}

#NavContainer nav ul li.has-items:before {
  content: "+";
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
  font-size: 4.8rem;
  line-height: 1.4;
}

@media (max-width: 767.98px) {
  #NavContainer nav ul li.has-items:before {
    top: -8px;
    font-size: 3.5rem;
  }
}

#NavContainer nav ul li.has-items.open:before {
  content: "–";
}

#NavContainer nav ul li ul {
  list-style-type: square;
  margin-left: 50px;
  margin-bottom: 30px;
  display: none;
}

@media (max-width: 767.98px) {
  #NavContainer nav ul li ul {
    margin-left: 25px;
    margin-bottom: 20px;
  }
}

#NavContainer nav ul li ul li {
  font-family: sofia-pro, sans-serif;
  border: none;
  margin-top: 5px;
}

#NavContainer nav ul li ul li > a {
  font-family: sofia-pro, sans-serif;
  font-size: 2.4rem;
  line-height: 1.4;
  text-transform: none;
}

@media (max-width: 767.98px) {
  #NavContainer nav ul li ul li > a {
    font-size: 1.5rem;
  }
}

#NavContainer nav ul li ul li:hover {
  border-bottom: none;
}

#NavContainer .sub-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#NavContainer .sub-nav .styled-button {
  padding: 18px 35px;
}

@media (max-width: 991.98px) {
  #NavContainer .sub-nav {
    flex-direction: column;
    justify-content: center;
  }

  #NavContainer .sub-nav .styled-button {
    margin-bottom: 30px;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  #NavContainer .sub-nav .styled-button {
    margin-bottom: 0;
  }
}

footer {
  background-color: black;
  color: white;
  padding: 60px 30px 50px 30px;
}

@media (max-width: 991.98px) {
  footer {
    padding: 30px 30px 20px 30px;
  }
}

footer .footer-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

footer .footer-nav nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

footer .footer-nav nav a {
  display: block;
  color: white;
  margin-left: 40px;
  font-size: 1.8rem;
}

@media (max-width: 991.98px) {
  footer .footer-nav {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
  }

  footer .footer-nav .city-logo {
    text-align: center;
  }

  footer .footer-nav img {
    margin-bottom: 25px;
  }

  footer .footer-nav nav {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
  }

  footer .footer-nav nav a {
    text-align: center;
    margin: 0 0 10px 0;
  }
}

footer .copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

footer .copyright ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

footer .copyright ul li {
  margin-left: 20px;
}

footer .copyright ul .newsletter {
  border-right: 1px solid white;
}

footer .copyright ul .newsletter a {
  color: white;
  text-decoration: underline;
  margin-right: 20px;
  font-size: 13px;
  line-height: 20px;
}

footer .copyright ul .newsletter a:hover {
  text-decoration: none;
}

footer .copyright p {
  font-size: 1.5rem;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  footer .copyright {
    flex-direction: column;
  }

  footer .copyright ul {
    justify-content: center;
    margin-bottom: 20px;
    order: 1;
    flex-wrap: wrap;
  }

  footer .copyright ul li {
    margin: 0 15px;
  }

  footer .copyright ul .newsletter {
    border-right: none;
    margin-bottom: 20px;
    flex-basis: 100%;
    text-align: center;
  }

  footer .copyright ul .newsletter a {
    text-align: center;
    margin: 0;
  }

  footer .copyright p {
    order: 2;
    text-align: center;
  }
}

.announcement {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

@media (max-width: 991.98px) {
  .announcement {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
  }
}

@media (max-width: 767.98px) {
  .announcement {
    padding: 20px 10px;
  }
}

.announcement .image-container {
  flex-basis: 33%;
  flex-shrink: 0;
  width: 100%;
}

@media (max-width: 991.98px) {
  .announcement .image-container {
    margin-bottom: 20px;
    flex-basis: 100%;
    width: 100%;
  }
}

.announcement .image-container img {
  width: 100%;
  max-width: 100%;
}

.announcement .content {
  padding: 0 30px 0 40px;
}

.announcement .content h3 {
  font-size: 36px;
  line-height: 44px;
}

.announcement .content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .announcement .content {
    padding: 0;
  }
}

.announcement .content a {
  text-decoration: underline;
  font-size: 21px;
  font-weight: 500;
  line-height: 34px;
}

.announcement .content a:hover {
  text-decoration: none;
}

.announcement .publish-date {
  margin-top: 10px;
}

.announcement .event-sessions {
  margin-bottom: 20px;
}

.announcement .event-sessions p {
  margin-bottom: 3px;
  font-weight: 400;
}

.announcement.press .content {
  position: relative;
  padding-left: 130px;
}

.announcement.press .content:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: block;
  content: " ";
}

@media (max-width: 991.98px) {
  .announcement.press .content {
    padding-left: 90px;
  }

  .announcement.press .content:before {
    width: 70px;
    height: 70px;
  }
}

.announcement.press .content.video:before {
  background-image: url("/images/video.svg");
}

.announcement.press .content.document:before,
.announcement.press .content.article:before {
  background-image: url("/images/document.svg");
}

.announcement.press .content.audio:before {
  background-image: url("/images/audio.svg");
}

.announcement.press .content.press-release:before {
  background-image: url("/images/press-release.svg");
}

.filter-bar .tabs {
  list-style-type: none;
  border-bottom: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 70px 0;
  padding: 0;
}

@media (max-width: 767.98px) {
  .filter-bar .tabs {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 30px 0;
    padding-bottom: 10px;
  }
}

.filter-bar .tabs button {
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  padding: 7px 20px;
  font-family: din-condensed, sans-serif;
  position: relative;
  background-color: black;
  color: white;
  margin: 0 15px 20px 15px;
  font-size: 22px;
  transition: all 0.15s linear;
  border: none;
  min-width: calc(25% - 30px);
}

.filter-bar .tabs button:before {
  content: " ";
  width: 100%;
  height: 100%;
  border: 3px solid black;
  position: absolute;
  bottom: -7px;
  left: -7px;
  transition: all 0.15s linear;
}

.filter-bar .tabs button.active,
.filter-bar .tabs button:hover {
  color: black;
  background-color: white;
}

.filter-bar .tabs button.active:before,
.filter-bar .tabs button:hover:before {
  bottom: 0;
  left: 0;
}

@media (max-width: 1199.98px) {
  .filter-bar .tabs button {
    min-width: calc(50% - 30px);
  }
}

@media (max-width: 767.98px) {
  .filter-bar .tabs button {
    margin: 0 0 15px 0;
    width: 100%;
  }
}

.filters-wrapper > .filter-header .filter-toggle-button {
  letter-spacing: 0.4px;
  width: 100%;
  color: white;
  background-color: black;
  border: none;
  text-align: left;
  margin-top: 0;
  line-height: 1;
  position: relative;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  padding: 14px 50px 14px 20px;
}

.filters-wrapper > .filter-header .filter-toggle-button span {
  font-weight: 300;
  font-size: 1.6rem;
}

.filters-wrapper > .filter-header .filter-toggle-button:after {
  content: "+";
  position: absolute;
  right: 15px;
  font-size: 30px;
  color: inherit;
  top: 40%;
  line-height: 1;
  transform: translateY(-50%);
}

.filters-wrapper > .filter-header .filter-toggle-button.open:after {
  content: "–";
}

.filters-wrapper .main-filter-content {
  border: 3px solid black;
  border-top: 0;
  background-color: white;
}

.filters-wrapper .main-filter-content > .filter-controls {
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 0;
  background: #F4F4F4;
}

.filters-wrapper .main-filter-content > .filter-controls button {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.filters-wrapper .main-filter-content > .filter-controls .clear-button {
  font-size: 13px;
  line-height: 20px;
  margin-right: 30px;
}

.filters-wrapper .main-filter-content > .filter-controls .filter-control-buttons button {
  font-weight: 600;
  font-size: 15px;
}

.filters-wrapper .main-filter-content > .filter-controls .apply-button {
  border-right: 1px solid black;
  padding-right: 10px;
  margin-right: 8px;
}

.filters-wrapper .filters-container {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  background: #F4F4F4;
}

@media (max-width: 767.98px) {
  .filters-wrapper .filters-container {
    padding: 15px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}

.checkbox-radio-filter,
.select-autocomplete-filter,
.date-filter {
  flex-grow: 1;
}

.checkbox-radio-filter h3,
.select-autocomplete-filter h3,
.date-filter h3 {
  font-size: 24px;
  letter-spacing: 0.23px;
  line-height: 28px;
  margin-bottom: 10px;
}

.checkbox-radio-filter button,
.select-autocomplete-filter button,
.date-filter button {
  margin-top: 10px;
}

.checkbox-radio-filter label,
.select-autocomplete-filter label,
.date-filter label {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.checkbox-radio-filter .filter-container .filter-header button,
.select-autocomplete-filter .filter-container .filter-header button,
.date-filter .filter-container .filter-header button {
  width: 100%;
  position: relative;
  text-align: left;
  border: none;
  border-bottom: 3px solid black;
  padding: 0 0 5px 0;
  background: transparent;
  font-family: din-condensed, sans-serif;
  font-size: 2.6rem;
  line-height: 1.2;
}

.checkbox-radio-filter .filter-container .filter-header button:after,
.select-autocomplete-filter .filter-container .filter-header button:after,
.date-filter .filter-container .filter-header button:after {
  position: absolute;
  content: "+";
  right: 0;
  font-size: 35px;
  color: inherit;
  top: -5px;
}

.checkbox-radio-filter .filter-container .filter-header button.open:after,
.select-autocomplete-filter .filter-container .filter-header button.open:after,
.date-filter .filter-container .filter-header button.open:after {
  content: "–";
}

.checkbox-radio-filter .checkbox,
.checkbox-radio-filter .radio,
.select-autocomplete-filter .checkbox,
.select-autocomplete-filter .radio,
.date-filter .checkbox,
.date-filter .radio {
  margin-bottom: 0;
}

.checkbox-radio-filter .checkbox input[type=checkbox],
.checkbox-radio-filter .checkbox input[type=radio],
.checkbox-radio-filter .radio input[type=checkbox],
.checkbox-radio-filter .radio input[type=radio],
.select-autocomplete-filter .checkbox input[type=checkbox],
.select-autocomplete-filter .checkbox input[type=radio],
.select-autocomplete-filter .radio input[type=checkbox],
.select-autocomplete-filter .radio input[type=radio],
.date-filter .checkbox input[type=checkbox],
.date-filter .checkbox input[type=radio],
.date-filter .radio input[type=checkbox],
.date-filter .radio input[type=radio] {
  opacity: 0;
  position: absolute;
}

.checkbox-radio-filter .checkbox input[type=checkbox] + label::after,
.checkbox-radio-filter .checkbox input[type=radio] + label::after,
.checkbox-radio-filter .radio input[type=checkbox] + label::after,
.checkbox-radio-filter .radio input[type=radio] + label::after,
.select-autocomplete-filter .checkbox input[type=checkbox] + label::after,
.select-autocomplete-filter .checkbox input[type=radio] + label::after,
.select-autocomplete-filter .radio input[type=checkbox] + label::after,
.select-autocomplete-filter .radio input[type=radio] + label::after,
.date-filter .checkbox input[type=checkbox] + label::after,
.date-filter .checkbox input[type=radio] + label::after,
.date-filter .radio input[type=checkbox] + label::after,
.date-filter .radio input[type=radio] + label::after {
  content: none;
}

.checkbox-radio-filter .checkbox input[type=checkbox]:checked + label,
.checkbox-radio-filter .checkbox input[type=radio]:checked + label,
.checkbox-radio-filter .radio input[type=checkbox]:checked + label,
.checkbox-radio-filter .radio input[type=radio]:checked + label,
.select-autocomplete-filter .checkbox input[type=checkbox]:checked + label,
.select-autocomplete-filter .checkbox input[type=radio]:checked + label,
.select-autocomplete-filter .radio input[type=checkbox]:checked + label,
.select-autocomplete-filter .radio input[type=radio]:checked + label,
.date-filter .checkbox input[type=checkbox]:checked + label,
.date-filter .checkbox input[type=radio]:checked + label,
.date-filter .radio input[type=checkbox]:checked + label,
.date-filter .radio input[type=radio]:checked + label {
  font-weight: 600;
  background-color: #EFEFEF;
}

.checkbox-radio-filter .checkbox input[type=checkbox]:checked + label::after,
.checkbox-radio-filter .checkbox input[type=radio]:checked + label::after,
.checkbox-radio-filter .radio input[type=checkbox]:checked + label::after,
.checkbox-radio-filter .radio input[type=radio]:checked + label::after,
.select-autocomplete-filter .checkbox input[type=checkbox]:checked + label::after,
.select-autocomplete-filter .checkbox input[type=radio]:checked + label::after,
.select-autocomplete-filter .radio input[type=checkbox]:checked + label::after,
.select-autocomplete-filter .radio input[type=radio]:checked + label::after,
.date-filter .checkbox input[type=checkbox]:checked + label::after,
.date-filter .checkbox input[type=radio]:checked + label::after,
.date-filter .radio input[type=checkbox]:checked + label::after,
.date-filter .radio input[type=radio]:checked + label::after {
  content: "";
}

.checkbox-radio-filter .checkbox input[type=checkbox]:focus + label::before,
.checkbox-radio-filter .checkbox input[type=radio]:focus + label::before,
.checkbox-radio-filter .radio input[type=checkbox]:focus + label::before,
.checkbox-radio-filter .radio input[type=radio]:focus + label::before,
.select-autocomplete-filter .checkbox input[type=checkbox]:focus + label::before,
.select-autocomplete-filter .checkbox input[type=radio]:focus + label::before,
.select-autocomplete-filter .radio input[type=checkbox]:focus + label::before,
.select-autocomplete-filter .radio input[type=radio]:focus + label::before,
.date-filter .checkbox input[type=checkbox]:focus + label::before,
.date-filter .checkbox input[type=radio]:focus + label::before,
.date-filter .radio input[type=checkbox]:focus + label::before,
.date-filter .radio input[type=radio]:focus + label::before {
  outline: grey dashed 1px;
  outline-offset: 2px;
}

.checkbox-radio-filter .checkbox label,
.checkbox-radio-filter .radio label,
.select-autocomplete-filter .checkbox label,
.select-autocomplete-filter .radio label,
.date-filter .checkbox label,
.date-filter .radio label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 20px 0 30px;
  margin-bottom: 0;
}

.checkbox-radio-filter .checkbox label img,
.checkbox-radio-filter .radio label img,
.select-autocomplete-filter .checkbox label img,
.select-autocomplete-filter .radio label img,
.date-filter .checkbox label img,
.date-filter .radio label img {
  margin-right: 5px;
}

.checkbox-radio-filter .checkbox label:after,
.checkbox-radio-filter .checkbox label:before,
.checkbox-radio-filter .radio label:after,
.checkbox-radio-filter .radio label:before,
.select-autocomplete-filter .checkbox label:after,
.select-autocomplete-filter .checkbox label:before,
.select-autocomplete-filter .radio label:after,
.select-autocomplete-filter .radio label:before,
.date-filter .checkbox label:after,
.date-filter .checkbox label:before,
.date-filter .radio label:after,
.date-filter .radio label:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: 13px;
}

.checkbox-radio-filter .checkbox label:before,
.checkbox-radio-filter .radio label:before,
.select-autocomplete-filter .checkbox label:before,
.select-autocomplete-filter .radio label:before,
.date-filter .checkbox label:before,
.date-filter .radio label:before {
  height: 16px;
  width: 16px;
  border: 1px solid;
  background-color: black;
  left: 4px;
}

.checkbox-radio-filter .checkbox label:after,
.checkbox-radio-filter .radio label:after,
.select-autocomplete-filter .checkbox label:after,
.select-autocomplete-filter .radio label:after,
.date-filter .checkbox label:after,
.date-filter .radio label:after {
  height: 5px;
  width: 9px;
  color: white;
  border-left: 2px solid;
  border-bottom: 2px solid;
  left: 7px;
  top: 17px;
  transform: rotate(-45deg);
}

.checkbox-radio-filter .radio label:before,
.select-autocomplete-filter .radio label:before,
.date-filter .radio label:before {
  height: 16px;
  width: 16px;
  border: 1px solid;
  background-color: black;
  left: 4px;
  border-radius: 50%;
}

.checkbox-radio-filter .radio label:after,
.select-autocomplete-filter .radio label:after,
.date-filter .radio label:after {
  height: 8px;
  width: 8px;
  color: white;
  background-color: white;
  border-radius: 50%;
  left: 7px;
  top: 17px;
  border: none;
  transform: rotate(0);
}

@media (max-width: 991.98px) {
  .checkbox-radio-filter {
    flex-basis: 100%;
  }
}

.select-autocomplete-filter {
  flex-basis: 100%;
}

.select-autocomplete-filter .form-group {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 18px;
  margin-bottom: 8px;
}

.select-autocomplete-filter .form-group .autocomplete-container {
  flex-grow: 1;
  position: relative;
}

.select-autocomplete-filter .form-group input[type=text] {
  margin-bottom: 0;
  border: 3px solid black;
  width: 100%;
  border-radius: 0;
  padding: 0 20px;
  height: 60px;
  font-size: 1.8rem;
  line-height: 3.4rem;
}

.select-autocomplete-filter .form-group button {
  flex-shrink: 0;
  margin: 0;
  background: black;
  color: white;
  border: 0;
  padding: 8px 20px;
}

.select-autocomplete-filter .autocomplete-options {
  overflow: scroll;
  position: absolute;
  width: 100%;
  max-height: 50vh;
  min-height: fit-content;
  background: white;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  z-index: 10;
  top: 60px;
}

@media (max-width: 767.98px) {
  .select-autocomplete-filter .autocomplete-options {
    width: calc(100% + 97px);
    max-height: 300px;
  }
}

.select-autocomplete-filter .autocomplete-options ul {
  margin: 0;
  padding: 0;
}

.select-autocomplete-filter .autocomplete-options ul button {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid black;
  background: white;
  color: black;
}

.select-autocomplete-filter .autocomplete-options ul button:hover,
.select-autocomplete-filter .autocomplete-options ul button:focus {
  background: #EFEFEF;
}

.select-autocomplete-filter .autocomplete-options ul li:last-child button {
  border-bottom: none;
}

.select-autocomplete-filter .pill-container {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.select-autocomplete-filter .pill-container .pill {
  background: black;
  color: white;
  border-radius: 500px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 3px 10px 5px 15px;
  font-size: 1.4rem;
}

.select-autocomplete-filter .pill-container .pill button {
  color: white;
  background: none;
  border: none;
  margin: 0;
  font-size: inherit;
}

.date-filter fieldset > div {
  width: 100%;
  margin-top: 10px;
  border-radius: 0;
  border: 2px solid black;
  padding: 15px 25px;
  font-family: sofia-pro, sans-serif;
}

.date-filter .vc-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid grey;
  color: black;
}

.date-filter .vc-weekday {
  color: black;
}

.date-filter .date-selected {
  border-radius: 0 !important;
}

.date-filter .vc-day-content:hover,
.date-filter .vc-day-content:focus {
  background: transparent !important;
}

.filters-section {
  border: 3px solid black;
  padding: 40px 50px 20px 50px;
  background: white;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 50px;
  width: 95%;
}

@media (max-width: 767.98px) {
  .filters-section {
    padding: 20px;
  }
}

.filters-section h2 {
  padding: 0;
}

.cta-container {
  background-color: #F4F4F4;
  padding: 100px 0;
}

@media (max-width: 767.98px) {
  .cta-container {
    padding: 50px 0;
  }
}

.cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  margin: 0 auto;
  width: 95%;
  max-width: 800px;
}

@media (max-width: 767.98px) {
  .cta {
    flex-direction: column;
  }
}

.cta .image-container {
  border: 5px solid black;
  width: 100%;
}

.cta .image-container img {
  width: 100%;
}

@media (min-width: 992px) {
  .cta .image-container {
    flex-basis: 60%;
  }
}

.cta .cta-text {
  padding: 30px;
}

.cta .cta-text h3 {
  color: white;
}

.cta .cta-text p {
  color: white;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

.cta a {
  color: white;
  text-decoration: underline;
  white-space: nowrap;
  position: relative;
  padding-right: 22px;
}

.cta a:after {
  position: absolute;
  content: " ";
  right: 0;
  bottom: 2px;
  width: 13px;
  height: 13px;
  background-image: url("/images/forward-arrow-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.cta a:hover {
  text-decoration: none;
}

.image-carousel .hooper-list {
  background: black;
}

.image-carousel .hooper-track {
  align-items: center;
}

.image-carousel .hooper-slide img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.image-carousel .carousel-control .controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.image-carousel .carousel-control .controls .item-count {
  margin: 0 auto;
  text-align: center;
  font-size: 15px;
}

.image-carousel .carousel-control .controls button {
  width: 35px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.artwork-card .hooper-slide img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  max-height: 260px;
}

.single-artwork .artwork-image-carousel .hooper-slide img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  max-height: 600px;
}

.single-artwork .artwork-image-carousel .controls button img {
  width: 100%;
}

.map {
  position: relative;
}

.map .vue-map-hidden {
  display: block;
}

.map .vue-map-container {
  width: 100%;
  height: 80vh;
}

@media (max-width: 767.98px) {
  .map .vue-map-container {
    height: 400px;
  }
}

.map .geolocation-button {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  bottom: 190px;
  right: 10px;
  display: flex;
}

@media (max-width: 767.98px) {
  .map .geolocation-button {
    top: 170px;
    bottom: auto;
  }
}

.map .geolocation-button img {
  width: 100%;
}

.map .geolocation-button:hover {
  background-color: #f1f1f1;
}

.map .borough-button {
  position: absolute;
  width: 170px;
  padding: 10px;
  border-radius: 0;
  background-color: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  bottom: 23px;
  right: 70px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
}

.map .borough-button p {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  margin: 0 0 10px 0;
  padding: 0;
}

.map .borough-button .states {
  display: flex;
}

.map .borough-button .states button {
  flex-basis: 50%;
  text-align: center;
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 2px 10px;
}

.map .borough-button .states button.active,
.map .borough-button .states button:hover {
  background-color: black;
  color: white;
}

.map .info-content-container {
  position: relative;
  max-height: 80vh;
  overflow-y: scroll;
}

@media (max-width: 767.98px) {
  .map .info-content-container {
    position: relative;
    max-height: none;
  }
}

.map .info-content-container .artwork-card {
  border: none !important;
  margin-bottom: 0 !important;
}

.map #InfoWindow {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  max-width: 350px;
  background-color: white;
  border: 3px solid black;
}

@media (max-width: 767.98px) {
  .map #InfoWindow {
    position: relative;
    height: auto;
    width: 95%;
    margin: 15px auto 0 auto;
    top: auto;
    left: auto;
    max-width: 100%;
  }
}

.map #InfoWindow img {
  max-width: 100%;
}

.map #InfoWindow .close-bar {
  text-align: right;
  background-color: black;
  padding: 5px 10px;
}

.map #InfoWindow .close-bar button {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  line-height: 1;
  color: white;
}

.map #InfoWindow .inner-window {
  position: relative;
  height: auto;
  width: 100%;
}

.map .gm-style-iw .gm-style-iw-d {
  overflow: auto !important;
}

.map .gm-style-iw .gm-style-iw-d::-webkit-scrollbar {
  width: 0;
}

.map .gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  border: 2px solid black;
  border-top: 8px solid black;
  font-family: sofia-pro, sans-serif;
}

.map .gm-style .gm-style-iw-c .gm-style-iw-d {
  position: relative;
  padding: 0;
}

.map .gm-style .gm-style-iw-c .gm-style-iw-d .info-content-container {
  position: relative;
  overflow: hidden;
}

.artwork-cluster-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 5px;
  position: relative;
}

.artwork-cluster-card:hover {
  background: #f4f4f4;
}

.artwork-cluster-card .image {
  background: black;
  width: 110px;
  align-self: stretch;
  flex-shrink: 0;
}

.artwork-cluster-card .image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 75px;
}

.artwork-cluster-card .details-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.artwork-cluster-card .details {
  padding: 10px;
}

.artwork-cluster-card .details .artwork-name,
.artwork-cluster-card .details p {
  margin-bottom: 0;
}

.artwork-cluster-card .details .artwork-name {
  font-size: 17px;
  text-align: left;
}

.artwork-cluster-card .details p {
  font-size: 13px;
}

.artwork-cluster-card .select-button {
  font-size: 20px;
  color: black;
  margin-right: 20px;
  text-decoration: none;
}

.artworks-container .active-filters {
  margin-top: 30px;
  width: 100%;
}

@media (max-width: 767.98px) {
  .artworks-container .active-filters {
    margin-top: 20px;
    padding: 0;
  }
}

.filter-message-container {
  width: 100%;
  flex-basis: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.filter-message-container button {
  flex-shrink: 0;
}

@media (max-width: 767.98px) {
  .filter-message-container {
    flex-direction: column;
  }
}

.filter-message {
  padding-right: 10%;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 30px;
}

@media (max-width: 767.98px) {
  .filter-message {
    padding-right: 0;
    font-size: 18px;
    line-height: 1.4;
  }
}

#map-container {
  position: relative;
}

.loading {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading p {
  background: black;
  color: white;
  padding: 3px 12px 5px 12px;
  font-weight: 600;
  margin: 10px 0 0 0;
  letter-spacing: 1px;
}

.map-intro {
  padding: 0 40px;
}

@media (max-width: 991.98px) {
  .map-intro {
    padding: 0 15px;
  }
}

.map-intro > div {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 991.98px) {
  .map-intro > div {
    flex-direction: column;
  }
}

.map-intro h2 {
  padding-left: 0;
}

.map-intro h3 {
  margin-bottom: 30px;
}

.map-intro p {
  font-size: 2.1rem;
}

@media (max-width: 991.98px) {
  .map-intro p {
    font-size: 1.8rem;
  }
}

.map-intro .plan-route-cta {
  flex-shrink: 0;
  margin-left: 50px;
  border: 4px solid black;
  padding: 30px 50px;
  max-width: 450px;
  width: 100%;
  text-align: center;
}

@media (max-width: 991.98px) {
  .map-intro .plan-route-cta {
    max-width: 100%;
    margin: 20px 0 0 0;
  }
}

.map-intro .plan-route-cta p {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.map-intro .plan-route-cta h3 {
  margin-bottom: 10px;
}

.map-intro .plan-route-cta .small-button {
  display: block;
}

.home-filters-section {
  background: #F4F4F4;
  margin-top: 50px;
  width: 100%;
  padding: 60px 30px;
}

@media (max-width: 767.98px) {
  .home-filters-section {
    padding: 20px;
  }
}

.home-filters-section h2 {
  padding: 0;
}

.home-filters-section .main-filter-content .filters-container {
  grid-template-columns: repeat(1, 1fr);
}

.home-filters-section .main-filter-content .filter-controls {
  display: none;
}

.map-listing .results {
  margin: 40px 0;
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 500;
}

.map-listing .map-listing-container {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.map-listing .map-listing-container .item {
  width: 100%;
  flex-basis: 25%;
  padding: 0 10px 10px 10px;
}

@media (max-width: 1199.98px) {
  .map-listing .map-listing-container .item {
    flex-basis: 33.333%;
  }
}

@media (max-width: 991.98px) {
  .map-listing .map-listing-container .item {
    flex-basis: 50%;
  }
}

@media (max-width: 767.98px) {
  .map-listing .map-listing-container .item {
    flex-basis: 100%;
  }
}

.artwork-card {
  border: 2px solid black;
  border-top: 16px solid black;
  margin-bottom: 30px;
  background: white;
}

.artwork-card .past-artwork {
  padding: 10px;
}

.artwork-card .past-artwork p {
  font-size: 16px;
  font-style: italic;
  line-height: 24px;
  text-align: center;
  margin: 0;
}

.artwork-card .artwork-hub {
  padding: 5px 10px;
  margin-top: -15px;
  background-color: #F4F4F4;
  width: fit-content;
  position: relative;
  z-index: 10;
}

.artwork-card .artwork-hub p {
  font-size: 13px;
  font-weight: bold;
  line-height: 22px;
  margin: 0;
}

.artwork-card.no-image .artwork-hub {
  margin-top: 15px;
}

.artwork-card.multi-image .artwork-hub {
  margin-top: 0;
}

.artwork-card .card-top {
  background: #F4F4F4;
  padding-top: 20px;
}

.artwork-card .artwork-name {
  font-size: 2.1rem;
  padding: 0 20px;
  line-height: 1.2;
  margin-bottom: 5px;
}

.artwork-card .artwork-name a {
  text-decoration: underline;
}

.artwork-card .artwork-name a:hover {
  text-decoration: none;
}

.artwork-card .artist {
  font-size: 1.8rem;
  padding: 0 20px;
}

.artwork-card .address {
  padding: 15px 0;
}

.artwork-card .address img {
  width: 35px;
  margin-right: 5px;
  position: absolute;
  top: 2px;
  left: 15px;
}

.artwork-card .address p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0 15px 0 60px;
  position: relative;
}

.artwork-card .address a.no-style {
  padding-left: 60px;
}

.artwork-card .address button {
  padding: 0 20px 0 60px;
}

.artwork-card .artwork-dates {
  padding: 0 20px;
}

.artwork-card .artwork-dates p {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
  border-bottom: 1px solid black;
  padding: 10px 0;
}

.artwork-card .additional-info-card {
  padding-top: 15px;
  padding-bottom: 10px;
}

.artwork-card .additional-info-card p {
  margin-bottom: 7px;
  padding: 0 20px;
  font-size: 1.6rem;
  line-height: 1.3;
}

.artwork-card .additional-info-card p span {
  font-family: din-condensed, sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

.artwork-card .image {
  height: 270px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.artwork-card .image img {
  max-width: 100%;
  max-height: 100%;
}

.artwork-card img {
  max-width: 100%;
}

.blank-card {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding: 0;
  border: 2px solid black;
  background: #e8e8e8;
  margin-bottom: 80px;
}

.blank-card p {
  padding: 0;
  color: #4F4F4F;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 10px 0;
}

.blank-card a {
  font-size: 18px;
  text-decoration: underline;
}

.blank-card a:hover {
  text-decoration: none;
}

.learn-more-artwork {
  text-align: center;
  background-color: #F4f4f4;
  padding: 15px 0;
}

.learn-more-artwork a {
  text-decoration: underline;
  position: relative;
}

.learn-more-artwork a:after {
  position: absolute;
  right: -15px;
  display: block;
  top: 50%;
  width: 8px;
  transform: translateY(-45%);
  content: url(/images/right-arrow.svg);
  transition: right 0.2s linear;
}

.learn-more-artwork a:hover {
  text-decoration: none;
}

.learn-more-artwork a:hover:after {
  right: -17px;
}

.back-to-top {
  padding: 15px 15px;
  position: fixed;
  border: 2px solid white;
  bottom: 20px;
  right: -2px;
  background-color: black;
  color: white;
  z-index: 10;
  vertical-align: middle;
  display: flex;
}

.map-search-container,
.blog-search-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .map-search-container,
  .blog-search-container {
    flex-direction: column;
  }
}

.search-bar {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .search-bar {
    flex-direction: column;
    align-items: flex-end;
  }
}

.search-bar .reset-button {
  margin: 0 0 0 20px;
}

@media (max-width: 991.98px) {
  .search-bar .reset-button {
    margin: 15px 0 0 0;
  }
}

.search-bar .reset-button:hover {
  text-decoration: none;
}

.search-bar #ArtworkSearch {
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
}

@media (max-width: 991.98px) {
  .search-bar #ArtworkSearch {
    width: 100%;
  }
}

.search-bar #ArtworkSearch label {
  margin-bottom: 10px;
  font-weight: 500;
  font-family: din-condensed, sans-serif;
  font-size: 2.6rem;
  line-height: 1.2;
}

.search-bar #ArtworkSearch input,
.search-bar #ArtworkSearch button[type=submit] {
  height: 60px;
  border: 3px solid black;
}

@media (max-width: 991.98px) {
  .search-bar #ArtworkSearch input,
  .search-bar #ArtworkSearch button[type=submit] {
    height: 55px;
  }
}

.search-bar #ArtworkSearch input[type=text] {
  margin: 0;
  border-radius: 0;
  padding: 0 20px;
  position: relative;
  width: 100%;
  font-size: 1.8rem;
  line-height: 3.4rem;
}

@media (max-width: 991.98px) {
  .search-bar #ArtworkSearch input[type=text] {
    font-size: 1.8rem;
    padding: 0 15px;
  }
}

.search-bar #ArtworkSearch .search-input-group {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.search-bar #ArtworkSearch .search-input-wrapper {
  position: relative;
  flex-grow: 1;
}

.search-bar #ArtworkSearch .search-input-wrapper .clear-search {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .search-bar #ArtworkSearch .search-input-wrapper .clear-search {
    right: 15px;
    transform: translateY(-55%);
  }
}

.search-bar #ArtworkSearch button[type=submit] {
  width: 150px;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 4.6rem;
  padding-right: 30px;
  background-color: black;
  transition: all 0.2s linear;
  position: relative;
  text-align: right;
  padding-bottom: 5px;
  color: white;
}

.search-bar #ArtworkSearch button[type=submit]:before {
  content: " ";
  background-image: url("/images/icon-search-rev.svg");
  position: absolute;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.search-bar #ArtworkSearch button[type=submit]:hover {
  background-color: white;
  color: black;
}

.search-bar #ArtworkSearch button[type=submit]:hover:before {
  background-image: url("/images/icon-search.svg");
}

@media (max-width: 991.98px) {
  .search-bar #ArtworkSearch button[type=submit] {
    font-size: 2rem;
    line-height: 2rem;
    padding-right: 15px;
    width: 130px;
  }

  .search-bar #ArtworkSearch button[type=submit]:before {
    width: 20px;
    height: 20px;
    left: 15px;
  }
}

@media (max-width: 767.98px) {
  .search-bar #ArtworkSearch button[type=submit] {
    width: 150px;
  }
}

.search-container {
  position: relative;
  width: 100%;
}

.search-messages {
  margin-top: 15px;
}

.search-messages p {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.map-legend {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: 2px solid black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.map-legend .legend-header {
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 15px 50px 15px 30px;
  font-size: 2.1rem;
  line-height: 2.6rem;
  margin-bottom: 0;
  letter-spacing: 0.9px;
  text-decoration: none;
  font-family: din-condensed, sans-serif;
  position: relative;
}

.map-legend .legend-header:after {
  content: "+";
  color: inherit;
  font-size: 3rem;
  position: absolute;
  font-family: inherit;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.map-legend .legend-header:hover {
  background-color: #1a1a1a;
}

.map-legend .legend-header.open:after {
  content: "–";
}

.map-legend .legend-items .legend-item input[type=checkbox] {
  opacity: 0;
  position: absolute;
}

.map-legend .legend-items .legend-item label {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px 0 10px 40px;
  margin: 0;
}

.map-legend .legend-items .legend-item label::before,
.map-legend .legend-items .legend-item label::after {
  position: absolute;
  content: "";
  display: inline-block;
}

.map-legend .legend-items .legend-item label::before {
  height: 20px;
  width: 20px;
  background-color: black;
  border: 1px solid;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.map-legend .legend-items .legend-item label::after {
  height: 6px;
  width: 10px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  top: 48%;
  left: 20px;
  transform: translateY(-50%) rotate(-45deg);
}

.map-legend .legend-items .legend-item input[type=checkbox] + label::after {
  content: none;
}

.map-legend .legend-items .legend-item input[type=checkbox]:checked + label::after {
  content: "";
}

.map-legend .legend-items .legend-item input[type=checkbox]:checked + label {
  background-color: #EFEFEF;
}

.map-legend .legend-items .legend-item input[type=checkbox]:focus + label::before {
  outline: black dotted 1px;
}

.hub-artworks {
  background-color: #F4F4F4;
  padding-top: 50px;
  padding-bottom: 50px;
}

.hub-artworks h2 {
  padding-left: 0;
}

.homepage .accordions {
  padding: 30px 0;
}

.homepage .wysiwyg {
  max-width: 1000px;
  margin: 100px auto;
  width: 100%;
}

.homepage .wysiwyg * {
  padding-left: 0;
}

@media (max-width: 767.98px) {
  .homepage .wysiwyg {
    margin: 50px auto;
  }
}

.homepage .hero .hero-title {
  bottom: 15%;
}

.homepage .hero .hero-title .on-placement {
  transform: none;
  width: 60%;
}

@media (max-width: 767.98px) {
  .homepage .hero .hero-title {
    bottom: 0;
  }

  .homepage .hero .hero-title .on-placement {
    width: 70%;
  }
}

.filter-container {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 10px;
}

@media (max-width: 991.98px) {
  .filter-container {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .filter-container {
    margin-left: 0;
  }
}

.filter-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

@media (max-width: 991.98px) {
  .filter-group {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
}

.map-filters {
  width: 100%;
  z-index: 1;
  position: relative;
  margin-top: 10px;
  margin-left: 0;
}

.map-filters .checkbox-radio-filter {
  flex-basis: 33%;
}

@media (max-width: 991.98px) {
  .map-filters .checkbox-radio-filter {
    flex-basis: 100%;
  }
}

.map-controls {
  flex-basis: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media (max-width: 991.98px) {
  .map-controls {
    flex-basis: 50%;
  }
}

@media (max-width: 767.98px) {
  .map-controls {
    flex-basis: 100%;
    flex-direction: column;
  }
}

.artwork-view-button {
  margin-left: 30px;
  flex-basis: 40%;
}

.artwork-view-button p {
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: 500;
}

@media (max-width: 991.98px) {
  .artwork-view-button {
    width: 100%;
    order: 2;
    margin-left: 0;
    margin-bottom: 15px;
    flex-basis: 100%;
  }
}

.states {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.states .toggle-button {
  text-align: center;
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 7px 20px;
  margin: 0 0 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 50px;
  transition: all 0.1s linear;
  box-sizing: border-box;
  width: 100%;
  flex-basis: 50%;
}

.states .toggle-button img {
  height: 25px;
  width: auto;
  margin-right: 10px;
}

.states .toggle-button.active,
.states .toggle-button:hover {
  background-color: #E9E9E9;
  height: 60px;
  font-weight: 600;
  border-width: 4px;
}

@media (max-width: 991.98px) {
  .states .toggle-button {
    width: 100%;
  }
}

.states .toggle-button:first-child {
  margin: 0;
}

.single-artwork .past-artwork-single {
  padding: 10px 20px;
  background: #F4F4F4;
  position: relative;
  margin-top: 50px;
}

.single-artwork .past-artwork-single p {
  font-size: 21px;
  font-style: italic;
  line-height: 32px;
  text-align: center;
  color: black;
  margin: 0;
}

@media (max-width: 991.98px) {
  .single-artwork .past-artwork-single {
    padding: 5px 20px;
    margin-top: 20px;
  }

  .single-artwork .past-artwork-single p {
    font-size: 16px;
  }
}

.single-artwork h1 {
  padding: 0;
  margin: 0;
}

.single-artwork .artist-intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
}

@media (max-width: 767.98px) {
  .single-artwork .artist-intro {
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

.single-artwork .artist-intro .content {
  flex-basis: 70%;
}

@media (max-width: 1199.98px) {
  .single-artwork .artist-intro .content {
    flex-basis: 60%;
  }
}

@media (max-width: 767.98px) {
  .single-artwork .artist-intro .content {
    flex-basis: 100%;
  }
}

.single-artwork .artist-intro .favourite-button-container {
  background-color: #f1f1f1;
  padding: 10px 15px 15px 15px;
}

.single-artwork .artist-name {
  font-size: 28px;
  line-height: 33px;
  font-family: din-condensed, sans-serif;
}

.single-artwork .favourite-button-container {
  padding: 20px;
}

@media (max-width: 767.98px) {
  .single-artwork .favourite-button-container {
    margin-bottom: 20px;
  }
}

.single-artwork .artwork-location {
  position: relative;
  margin: 30px 0;
}

.single-artwork .artwork-location .address {
  position: relative;
  padding: 0 30px;
}

.single-artwork .artwork-location .address img {
  width: 25px;
  position: absolute;
  left: 0;
  top: 2px;
}

.single-artwork .artwork-location .links {
  padding-left: 38px;
}

.single-artwork .artwork-location .links .bar {
  margin: 0 5px;
  display: inline-block;
}

@media (max-width: 1199.98px) {
  .single-artwork .artwork-location .links .bar {
    display: block;
    height: 0;
    opacity: 0;
  }
}

.single-artwork .artwork-location .links a {
  position: relative;
  text-decoration: underline;
}

.single-artwork .artwork-location .links a:hover {
  text-decoration: none;
}

.single-artwork .artwork-location .links .show-on-google:after {
  position: absolute;
  right: -15px;
  display: block;
  bottom: -2px;
  width: 8px;
  content: url(/images/right-arrow.svg);
  transition: right 0.2s linear;
}

.single-artwork .artwork-location .links .show-on-map:before {
  position: absolute;
  left: -15px;
  display: block;
  top: 50%;
  width: 8px;
  transform: translateY(-45%);
  content: url(/images/left-arrow.svg);
  transition: right 0.2s linear;
}

.single-artwork .artwork-image {
  background-color: black;
}

.single-artwork .artwork-image a {
  height: 550px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-artwork .artwork-image img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 767.98px) {
  .single-artwork .artwork-image a {
    height: 450px;
  }
}

.single-artwork .description {
  margin: 70px auto 50px auto;
  padding: 40px;
  background-color: #F4f4f4;
  max-width: 950px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.7;
}

@media (max-width: 767.98px) {
  .single-artwork .description {
    margin: 30px auto;
    padding: 20px;
  }
}

.single-artwork .description h2 {
  font-size: 42px;
  line-height: 50px;
  padding: 0;
}

.single-artwork .description h3,
.single-artwork .description h4 {
  margin-top: 25px;
}

.single-artwork .description p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.7;
}

.single-artwork .description .shop-button {
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.2px;
  line-height: 28px;
  text-align: center;
  margin: 60px auto 0 auto;
  display: block;
  width: 200px;
}

.single-artwork .additional-info {
  margin: 50px auto;
  max-width: 830px;
}

.single-artwork .additional-info h2 {
  font-size: 36px;
  letter-spacing: 0;
  line-height: 44px;
  padding: 0;
}

.single-artwork .additional-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  columns: 2;
}

@media (max-width: 767.98px) {
  .single-artwork .additional-info ul {
    columns: 1;
  }
}

.single-artwork .additional-info ul li {
  border-left: 2px solid black;
  margin: 0;
  padding: 5px 30px;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
}

@media (max-width: 767.98px) {
  .single-artwork .additional-info ul li {
    padding: 5px 15px;
  }
}

.single-artwork .additional-info ul li a {
  text-decoration: underline;
}

.single-artwork .additional-info ul li a:hover {
  text-decoration: none;
}

.single-artwork .additional-info ul li span {
  font-size: 21px;
  line-height: 26px;
  font-family: din-condensed, sans-serif;
}

.single-artwork .social-share {
  border-top: 2px solid black;
  padding-top: 40px;
  margin-top: 100px;
  margin-bottom: 30px;
}

.single-artwork .hub-artworks {
  background: none;
}

@media (max-width: 767.98px) {
  .single-artwork .hub-artworks {
    padding-top: 20px;
  }

  .single-artwork .hub-artworks .favourite-button-container {
    background: none;
    margin-bottom: 0;
  }
}

.single-artwork .load-more {
  background: none;
}

@media (max-width: 767.98px) {
  .single-artwork .load-more {
    padding: 0;
  }
}

@media (max-width: 767.98px) {
  .single-artwork .associated-hub {
    margin-bottom: 20px;
  }
}

.select-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.select-card .card-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: 991.98px) {
  .select-card .details.show-for-mobile {
    flex-basis: 100%;
    margin-bottom: 5px;
  }
}

.select-card .details > * {
  text-align: left;
}

.select-card .details .artwork-name {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 3px;
  text-align: left;
}

@media (max-width: 991.98px) {
  .select-card .details .artwork-name {
    font-size: 21px;
  }
}

.select-card .details .artwork-name a {
  text-decoration: underline;
  font-size: inherit;
}

.select-card .details .artwork-name a:hover {
  text-decoration: none;
}

.select-card .details .artist {
  font-size: 18px;
  line-height: 1.2;
}

@media (max-width: 991.98px) {
  .select-card .details .artist {
    font-size: 15px;
  }
}

.select-card .details .address {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}

@media (max-width: 991.98px) {
  .select-card .details .address {
    margin-top: 0;
  }
}

.select-card .details .address img {
  margin-right: 5px;
}

.select-card .details .address p {
  margin-bottom: 0;
}

.select-card .details .address .artwork-type {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
}

@media (max-width: 991.98px) {
  .select-card .details .address .artwork-type {
    font-size: 15px;
  }
}

.select-card .details .address .artwork-address {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 991.98px) {
  .select-card .details .address .artwork-address {
    font-size: 14px;
  }
}

.select-card .image {
  margin-right: 20px;
  width: 118px;
  height: 118px;
  background-color: black;
  flex-shrink: 0;
}

@media (max-width: 991.98px) {
  .select-card .image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
}

.select-card .image img {
  object-fit: contain;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.select-artworks {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
}

@media (max-width: 991.98px) {
  .select-artworks {
    padding-bottom: 30px;
  }
}

.select-artworks .checkbox {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.select-artworks .checkbox label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 991.98px) {
  .select-artworks .checkbox label {
    align-items: flex-start;
  }
}

.select-artworks .checkbox .check {
  background: white;
  width: 35px;
  height: 35px;
  border: 2px solid black;
  flex-shrink: 0;
  position: relative;
  margin-right: 15px;
}

.select-artworks .checkbox .check:after {
  content: " ";
  background: black;
  width: 35px;
  height: 35px;
  right: 2px;
  top: 2px;
  position: absolute;
  z-index: -1;
}

.select-artworks .checkbox input {
  position: absolute;
  top: 0;
  left: 0;
}

.select-artworks .checkbox input:checked + label {
  background-color: #f9f8f8;
}

.select-artworks .checkbox input:checked + label .check:before {
  z-index: 1;
  content: " ";
  border: 5px solid black;
  border-top: none;
  border-left: none;
  width: 13px;
  height: 22px;
  top: 2px;
  right: 9px;
  position: absolute;
  transform: rotate(45deg);
}

.select-artworks .artwork-options {
  margin-bottom: 50px;
  margin-top: 30px;
}

.select-artworks .artwork-options label {
  border: 2px solid black;
  border-left: 15px solid black;
  padding: 10px 30px 15px 50px;
  width: 100%;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  background-color: white;
}

@media (max-width: 991.98px) {
  .select-artworks .artwork-options label {
    padding: 10px;
  }
}

.select-artworks .artwork-options label .select-card {
  margin-left: 40px;
}

@media (max-width: 991.98px) {
  .select-artworks .artwork-options label .select-card {
    margin-left: 4px;
  }
}

.favourites-listing .favourites-intro {
  display: flex;
  align-items: flex-start;
  background: #EFEFEF;
  padding: 75px 30px;
}

.favourites-listing .favourites-intro h1 {
  padding: 0;
  margin-top: 0;
}

.favourites-listing .favourites-intro .container .info-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media (max-width: 991.98px) {
  .favourites-listing .favourites-intro .container .info-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
  }
}

.favourites-listing .favourites-intro .container .info-container img {
  display: block;
  margin-bottom: 10px;
}

.favourites-listing .favourites-intro .container .info-container h2 {
  padding: 0;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 1;
}

.favourites-listing .favourites-intro .container .info-container p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

.favourites-listing .favourites-intro .container .info-container a {
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid black;
}

.favourites-listing .favourites-intro .container .info-container a:hover {
  text-decoration: none;
}

.favourites-listing .favourites-intro .container .info-container a:last-child {
  border-right: none;
}

.favourites-listing .favourites-intro .container .button-container {
  flex-basis: 27%;
  flex-shrink: 0;
  text-align: right;
}

@media (max-width: 767.98px) {
  .favourites-listing .favourites-intro .container .button-container {
    flex-basis: 100%;
    margin-top: 40px;
    text-align: center;
  }
}

.favourites-listing .favourites-intro .container .button {
  display: inline-block;
}

.favourites-listing .favourite-section-intro {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 50px;
}

@media (max-width: 767.98px) {
  .favourites-listing .favourite-section-intro {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.favourites-listing .favourite-section-intro h2 {
  margin-bottom: 20px;
}

.favourites-listing .favourite-section-intro p {
  margin: 0;
}

.favourites-listing .plan-a-route-link {
  padding: 25px 50px;
  display: flex;
  align-items: center;
  background-color: #F4F4F4;
  margin-left: 40px;
  max-width: 350px;
}

.favourites-listing .plan-a-route-link a {
  font-size: 18px;
}

.favourites-listing .plan-a-route-link p {
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 20px;
}

.favourites-listing .plan-a-route-link div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

@media (max-width: 991.98px) {
  .favourites-listing .plan-a-route-link {
    margin: 30px auto 0 auto;
    padding: 25px;
    width: 100%;
    justify-content: center;
    flex-basis: 40%;
  }
}

.favourites-listing .results {
  padding-left: 30px;
  font-family: sofia-pro, sans-serif;
  font-size: 2.1rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 60px;
}

@media (max-width: 991.98px) {
  .favourites-listing .results {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.favourites-listing .no-results {
  font-family: sofia-pro, sans-serif;
  font-size: 2.1rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 60px;
}

.favourites-listing .map-listing-container {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 991.98px) {
  .favourites-listing .map-listing-container .item {
    flex-basis: 50%;
  }
}

@media (max-width: 767.98px) {
  .favourites-listing .map-listing-container .item {
    flex-basis: 100%;
    padding: 0;
  }
}

.favourites-listing .artwork-card .address button {
  display: none;
}

.favourite-button-container {
  padding: 5px 20px 13px 20px;
  text-align: left;
}

.favourite-button-container button {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  position: relative;
  text-decoration: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.favourite-button-container button span {
  text-decoration: underline;
  line-height: 1.2;
}

.favourite-button-container button img {
  margin-right: 8px;
}

.link-home {
  text-align: center;
  margin: 60px auto;
}

.link-home p,
.link-home h2 {
  font-size: 2.1rem;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  margin-bottom: 10px;
  padding: 0;
  font-family: sofia-pro, sans-serif;
}

@media (max-width: 991.98px) {
  .link-home p,
  .link-home h2 {
    padding: 0 60px;
  }
}

.link-home a {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  text-decoration: underline;
}

.link-home a:hover {
  text-decoration: none;
}

.email-favourites {
  background-color: #F4F4F4;
  padding: 60px 20px;
  margin-top: 40px;
}

.email-favourites p {
  font-size: 2.1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.email-favourites .email-favourites-bar {
  width: 100%;
  max-width: 700px;
  margin: 20px auto 0 auto;
}

.email-favourites .email-favourites-bar .error {
  font-size: 15px;
  color: red;
  position: absolute;
  bottom: -20px;
}

@media (max-width: 991.98px) {
  .email-favourites .email-favourites-bar .error {
    position: relative;
  }
}

.email-favourites .email-favourites-bar form {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .email-favourites .email-favourites-bar form {
    flex-direction: column;
    align-items: center;
  }
}

.email-favourites .email-favourites-bar form .input-group {
  flex-basis: 65%;
}

@media (max-width: 991.98px) {
  .email-favourites .email-favourites-bar form .input-group {
    flex-basis: 100%;
  }
}

.email-favourites .email-favourites-bar form label {
  display: block;
  font-size: 2.1rem;
  line-height: 1.3;
}

.email-favourites .email-favourites-bar form input[type=email] {
  height: 49px;
  border: 2px solid black;
  background-color: white;
  width: 100%;
  padding: 0 10px;
  font-size: 18px;
  border-radius: 0;
  margin-bottom: 10px;
}

.email-favourites .email-favourites-bar form input[type=email].error-input {
  border-color: red;
}

.email-favourites .email-favourites-bar form button[type=submit] {
  font-size: 1.8rem;
  padding: 15px 20px;
  margin-left: 20px;
  height: 49px;
  margin-bottom: 15px;
}

@media (max-width: 991.98px) {
  .email-favourites .email-favourites-bar form button[type=submit] {
    margin-left: 0;
    margin-top: 10px;
    line-height: 1;
  }
}

.email-favourites .send-success {
  max-width: 650px;
  margin: 0 auto;
}

.email-favourites .send-success h2 {
  letter-spacing: 0;
  text-align: left;
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
}

.email-favourites .send-success h2:before {
  background-image: url("/images/Checkmark.svg");
  content: " ";
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: -60px;
}

@media (max-width: 991.98px) {
  .email-favourites .send-success h2:before {
    background-image: none;
  }
}

.email-favourites .send-success p {
  text-align: left;
  padding: 0;
  margin: 0;
  font-weight: 300;
  max-width: unset;
}

.email-favourites .send-success p strong {
  font-weight: 500;
}

.create-a-route-container h1,
.create-a-route-container h2,
.create-a-route-container h3 {
  text-align: center;
  margin-bottom: 10px;
}

.create-a-route-container .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.create-a-route-container .form-group label {
  font-size: 19px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 10px;
}

.create-a-route-container .form-group input,
.create-a-route-container .form-group select {
  border: 2px solid black;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.create-a-route-container .intro {
  text-align: center;
  font-weight: 400;
  margin-bottom: 60px;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 34px;
  font-family: sofia-pro, sans-serif;
}

@media (max-width: 991.98px) {
  .create-a-route-container .intro {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 30px;
  }
}

.create-a-route-container .button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

@media (max-width: 991.98px) {
  .create-a-route-container .button-group {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
}

.create-a-route-container .button-group .back-link {
  margin: 10px 40px;
}

.create-a-route-container .next-button {
  text-align: center;
}

.create-a-route-container .next-button a,
.create-a-route-container .next-button button {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
  text-align: center;
  display: inline-block;
}

.create-a-route-container .next-button a span,
.create-a-route-container .next-button button span {
  display: inline-block;
  background-image: url(/images/right-arrow-white.svg);
  width: 10px;
  height: 12px;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-size: contain;
}

.create-a-route-container .back-link,
.email-route-root .back-link {
  font-size: 18px;
  margin-top: 40px;
}

.create-a-route-container .back-link:before,
.email-route-root .back-link:before {
  top: 40%;
}

.select-location {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
}

.select-location .start-location {
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .select-location .start-location {
    margin-bottom: 25px;
  }
}

.select-location .start-location .error {
  margin-top: 10px;
}

.select-location .location-input-wrapper {
  position: relative;
  width: 100%;
}

.select-location .location-input-wrapper input {
  margin-bottom: 0;
}

.select-location .location-input-wrapper:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 4px;
  right: 4px;
  background: black;
  z-index: -1;
}

.select-location .select {
  max-width: 350px;
  margin: 0 auto;
}

.select-location .select .styled-select {
  width: 100%;
  position: relative;
}

.select-location .select .styled-select:after {
  content: " ";
  background-image: url(/images/back-arrow.svg);
  right: 20px;
  top: 20px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
}

.select-location .select select {
  width: 100%;
  height: 50px;
  cursor: pointer;
  border-radius: 0;
  border: 2px solid black;
  background: white;
  padding: 10px 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.view-route-container {
  border: 2px solid black;
  border-top: 6px solid black;
  display: flex;
  margin-bottom: 60px;
  margin-top: 60px;
  position: relative;
}

.view-route-container > div {
  flex-basis: 50%;
  align-items: stretch;
}

@media (max-width: 1199.98px) {
  .view-route-container {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .view-route-container > div {
    flex-basis: 100%;
    align-items: flex-start;
  }

  .view-route-container .route-map {
    order: 1;
  }

  .view-route-container .route-map .vue-map-container {
    height: 40vh;
  }

  .view-route-container .route-details-container {
    order: 2;
  }
}

.route-details-container {
  border-right: 2px solid black;
}

@media (max-width: 1199.98px) {
  .route-details-container {
    border-right: none;
  }
}

.starting-location {
  margin-top: 5px;
}

.starting-location img {
  display: inline-block;
  vertical-align: text-bottom;
  width: 25px;
}

.starting-location a {
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
}

.starting-location a:hover {
  text-decoration: none;
}

.route-details-heading {
  background-color: #F4F4F4;
  padding: 20px 30px;
}

@media (max-width: 1199.98px) {
  .route-details-heading {
    padding: 15px 20px;
  }
}

.route-details-heading h2 {
  font-size: 42px;
  line-height: 50px;
  text-align: left;
  padding: 0;
}

@media (max-width: 1199.98px) {
  .route-details-heading h2 {
    font-size: 30px;
  }
}

.route-details-heading strong {
  font-family: din-condensed, sans-serif;
  font-weight: 500;
  font-size: 21px;
}

@media (max-width: 1199.98px) {
  .route-details-heading strong {
    font-size: 18px;
  }
}

.route-details-heading .total-route-info {
  display: flex;
}

.route-details-heading .total-route-info > div {
  margin-right: 15px;
}

@media (max-width: 991.98px) {
  .route-details-heading .total-route-info {
    flex-direction: column;
  }
}

.route-details {
  background: white;
  max-height: 70vh;
  overflow: scroll;
  position: relative;
}

.route-map .vue-map-container {
  height: 100%;
}

.route-directions,
.route-order-edit {
  padding: 20px 30px;
}

@media (max-width: 1199.98px) {
  .route-directions,
  .route-order-edit {
    max-height: 100%;
    overflow: auto;
    padding: 20px 20px 20px 10px;
  }
}

.leg {
  display: flex;
  flex-shrink: 0;
}

.leg:last-child .order-number:after {
  height: calc(100% - 95px);
}

.leg .order-number {
  position: relative;
  margin-right: 15px;
  padding-bottom: 95px;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 991.98px) {
  .leg .order-number {
    margin-right: 5px;
  }
}

.leg .order-number:after {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 1px;
  top: 0;
  border-left: 2px dashed black;
  content: " ";
}

.leg .order-number span {
  width: 40px;
  height: 40px;
  color: white;
  background: black;
  display: block;
  text-align: center;
  border-radius: 500px;
  line-height: 36px;
  font-size: 21px;
  font-weight: 500;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .leg .order-number span {
    line-height: 26px;
    font-size: 16px;
    width: 30px;
    height: 30px;
  }
}

.leg > div {
  display: flex;
  flex-direction: column;
}

.leg > div:last-child {
  flex-grow: 1;
}

.leg > div .total-leg {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 7px;
  margin-bottom: 20px;
  padding-top: 20px;
  border-bottom: 3px solid black;
}

.leg > div ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-left: 0;
  margin-bottom: 30px;
}

@media (max-width: 1199.98px) {
  .leg > div ul {
    margin-left: 0;
  }
}

.leg > div ul li {
  position: relative;
  border-bottom: 1px solid lightgrey;
  padding: 14px 0 22px 0;
}

.leg > div ul li p {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0;
}

.leg > div ul li .details {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  position: absolute;
  bottom: -2px;
}

.leg > div ul li .details span {
  background: white;
  padding-right: 10px;
}

.route-order-edit .leg > div:last-child {
  flex-direction: row;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid black;
}

.route-order-edit .leg .order-number {
  padding-bottom: 82px;
}

.route-order-edit .arrow-buttons {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}

.route-order-edit .arrow-buttons button {
  background: none;
  border: none;
  font-size: 25px;
}

@media (max-width: 991.98px) {
  .route-order-edit .arrow-buttons {
    margin-right: 8px;
  }

  .route-order-edit .arrow-buttons button {
    font-size: 18px;
  }
}

.route-instructions {
  background: black;
  color: white;
  padding: 20px 30px;
  position: sticky;
  bottom: 0;
  z-index: 2;
}

@media (max-width: 1199.98px) {
  .route-instructions {
    padding: 10px 20px;
    z-index: 10;
  }
}

.route-instructions .finish-instructions,
.route-instructions .order-instructions {
  display: flex;
  align-items: center;
}

@media (max-width: 1199.98px) {
  .route-instructions .finish-instructions,
  .route-instructions .order-instructions {
    flex-direction: column;
  }

  .route-instructions .finish-instructions > div,
  .route-instructions .finish-instructions > p,
  .route-instructions .finish-instructions > button,
  .route-instructions .order-instructions > div,
  .route-instructions .order-instructions > p,
  .route-instructions .order-instructions > button {
    padding-bottom: 20px;
    text-align: center;
  }
}

.route-instructions .finish-instructions p,
.route-instructions .order-instructions p {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 0;
}

@media (max-width: 1199.98px) {
  .route-instructions .finish-instructions p,
  .route-instructions .order-instructions p {
    font-size: 14px;
  }
}

.route-instructions .finish-instructions strong,
.route-instructions .order-instructions strong {
  font-weight: 600;
}

.route-instructions .finish-instructions .buttons,
.route-instructions .order-instructions .buttons {
  flex-shrink: 0;
}

.route-instructions .finish-instructions button,
.route-instructions .order-instructions button {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 28px;
  margin-left: 15px;
}

.route-instructions .finish-instructions .styled-button,
.route-instructions .order-instructions .styled-button {
  padding: 6px 20px;
  font-size: 18px;
  line-height: 28px;
  margin-left: 20px;
  font-weight: 600;
  display: inline-block;
}

.route-instructions .finish-instructions .finished-button,
.route-instructions .order-instructions .finished-button {
  padding-left: 38px;
  position: relative;
  text-transform: uppercase;
}

.route-instructions .finish-instructions .finished-button:before,
.route-instructions .order-instructions .finished-button:before {
  content: " ";
  border-right: 4px solid black;
  border-bottom: 4px solid black;
  width: 9px;
  height: 15px;
  position: absolute;
  top: 10px;
  left: 18px;
  background: transparent;
  transform: rotate(37deg);
}

.route-instructions .finish-instructions .no-style,
.route-instructions .order-instructions .no-style {
  color: white;
}

.email-route-root h1 {
  text-align: center;
  padding-left: 0;
}

.email-route-root h2 {
  padding-left: 0;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .email-route-root .email-favourites {
    padding: 30px 20px;
    margin-top: 0;
  }
}

.email-route-root .route-email-instructions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 900px;
  margin: 60px auto 0 auto;
}

.email-route-root .route-email-instructions .back-link {
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
}

.email-route-root .route-email-instructions .back-link:before {
  top: 40%;
}

.email-route-root .route-email-instructions .phone-image {
  margin-left: 40px;
}

.email-route-root .route-email-instructions .phone-image img {
  width: 150px;
  height: auto;
}

@media (max-width: 1199.98px) {
  .email-route-root .route-email-instructions .phone-image {
    display: none;
  }
}

.read-only-route .route-map {
  height: 70vh;
  max-height: 600px;
  width: 100%;
}

.read-only-route .directions-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 30px;
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .read-only-route .directions-heading {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
}

.read-only-route .directions-heading a {
  margin-bottom: 5px;
  text-decoration: underline;
}

.read-only-route .directions-heading a:hover {
  text-decoration: none;
}

.read-only-route h2 {
  margin-top: 30px;
  margin-bottom: 0;
  line-height: 1;
  padding-left: 0;
}

.read-only-route .route-details-heading {
  border: 2px solid black;
  border-bottom: none;
  margin-top: 20px;
}

.read-only-route .route-details {
  border: 2px solid black;
  margin-bottom: 30px;
  border-top: none;
}

.read-only-route .route-directions {
  max-height: fit-content;
}

.plan-a-route-intro {
  margin-bottom: 80px;
}

.plan-a-route-intro ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.plan-a-route-intro ul li {
  display: flex;
  text-align: left;
  margin-bottom: 30px;
  padding: 25px;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 767.98px) {
  .plan-a-route-intro ul li {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .plan-a-route-intro ul li div {
    margin-bottom: 10px;
  }
}

.plan-a-route-intro ul li > div:first-child {
  display: flex;
  padding-right: 30px;
}

@media (max-width: 767.98px) {
  .plan-a-route-intro ul li > div:first-child {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.plan-a-route-intro ul li p,
.plan-a-route-intro ul li h3 {
  text-align: left;
}

.plan-a-route-intro ul li .step-count {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  color: white;
  background: black;
  border-radius: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 21px;
  flex-shrink: 0;
  line-height: 1;
}

.plan-a-route-intro ul li .step-count span {
  transform: translateY(-2px);
}

.plan-a-route-intro ul li:nth-child(even) {
  background: #F4F4F4;
}

@media (min-width: 576px) {
  .events-page .info-block {
    padding-left: 30px;
  }
}

.events-page .info-block .content {
  padding-left: 30px;
}

.events-page .info-block .content p a {
  text-decoration: underline;
}

.events-page .info-block .content p a:hover {
  text-decoration: none;
}

.featured-event,
.this-week-events {
  margin-bottom: 60px;
}

@media (max-width: 991.98px) {
  .featured-event,
  .this-week-events {
    margin-bottom: 40px;
  }
}

.no-items {
  margin-bottom: 40px;
  padding-left: 30px;
}

.no-items p {
  font-size: 21px;
}

.event-card {
  border: 3px solid black;
  border-top: 10px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
}

.event-card .event-card-image {
  position: relative;
}

.event-card .event-card-image img {
  width: 100%;
}

.event-card .favourite-button-container {
  padding: 10px 0 0 0;
}

.event-card-details,
.event-card-intro {
  padding: 15px 20px 20px 20px;
}

.event-program-partner {
  background-color: #F4F4F4;
}

.event-program-partner p {
  background: white;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  line-height: 22px;
  width: fit-content;
  margin-bottom: -15px;
  transform: translateY(-15px);
}

.past-event {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 10px 13px 10px;
}

.past-event p {
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
  margin: 0;
}

.event-card-intro {
  background-color: #F4F4F4;
}

.event-card-content .event-name {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 0;
}

.event-card-content .event-name a {
  text-decoration: underline;
  font-size: inherit;
}

.event-card-content .event-name a:hover {
  text-decoration: none;
}

.event-card-content .event-times,
.event-card-content .event-address {
  position: relative;
}

.event-card-content .event-times p,
.event-card-content .event-address p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
}

.event-card-content .event-description {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.6rem;
}

.calendar-link {
  margin-left: 15px;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 20px;
}

.calendar-link:before {
  content: "+";
  position: absolute;
  left: -15px;
}

.event-categories {
  border-top: 1px solid grey;
  padding-top: 10px;
}

.event-categories ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.event-categories ul li {
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
}

.diamond-icon {
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  border: 2px solid black;
  background-color: white;
  margin-right: 10px;
}

.event-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px;
}

.event-card {
  margin: 0 15px 30px 15px;
  flex-basis: calc(100% / 3 - 30px);
}

@media (max-width: 991.98px) {
  .event-card {
    flex-basis: calc(100% / 2 - 30px);
  }
}

@media (max-width: 767.98px) {
  .event-card {
    flex-basis: calc(100% - 30px);
  }
}

.featured-event {
  background-color: #F4F4F4;
  padding: 40px 30px 80px 30px;
}

.featured-event h2 {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .featured-event {
    padding: 20px 0;
  }

  .featured-event h2 {
    padding-left: 15px;
  }
}

.featured-event .event-program-partner {
  margin-bottom: 10px;
  transform: translateX(-10px);
}

.featured-event .event-program-partner p {
  transform: none;
  margin: 0;
}

.featured-event .event-categories ul {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.featured-event .event-categories li {
  background: white;
  display: flex;
  padding: 3px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.featured-event .event-categories {
  border-top: 0;
}

.featured-event .event-card {
  flex-direction: row;
  border: none;
  padding: 40px 0 0 0;
  margin-bottom: 0;
}

.featured-event .event-card .event-card-image {
  flex-basis: 50%;
}

.featured-event .event-card .event-card-intro,
.featured-event .event-card .event-card-details {
  padding: 0;
  width: 100%;
}

.featured-event .event-card .event-card-intro {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid grey;
}

.featured-event .event-card .event-card-content {
  flex-basis: 50%;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.featured-event .event-card .event-card-content .event-card-text {
  padding: 0;
}

.featured-event .event-card .event-card-content .event-name {
  font-size: 36px;
  line-height: 44px;
}

@media (max-width: 991.98px) {
  .featured-event .event-card {
    flex-direction: column;
    padding: 20px 0;
    flex-basis: 100%;
  }

  .featured-event .event-card .event-card-image {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .featured-event .event-card .event-card-content {
    flex-basis: 100%;
    padding-left: 0;
  }
}

.single-event .past-event-single {
  padding: 10px 20px;
  background: #F4F4F4;
  position: relative;
}

.single-event .past-event-single p {
  font-size: 21px;
  font-style: italic;
  line-height: 32px;
  text-align: center;
  color: black;
}

@media (max-width: 991.98px) {
  .single-event .past-event-single {
    padding: 5px 20px;
  }

  .single-event .past-event-single p {
    font-size: 16px;
  }
}

.single-event .event-categories {
  border: none;
}

.single-event .event-categories ul {
  display: flex;
  flex-wrap: wrap;
}

.single-event .event-categories li {
  margin-right: 15px;
  margin-bottom: 8px;
}

.single-event .event-categories li a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.single-event .heading-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .single-event .heading-section {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}

.single-event .heading-section h1 {
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .single-event .heading-section h1 {
    margin-bottom: 10px;
  }
}

.single-event .heading-section .program-partner {
  font-size: 21px;
  font-weight: bold;
  line-height: 32px;
}

.single-event .heading-section .favourite-button-wrapper {
  flex-shrink: 0;
  margin-left: 30px;
}

.single-event .heading-section .favourite-button-container {
  background-color: #f1f1f1;
  padding: 10px 15px 15px 15px;
  margin-top: 20px;
}

.single-event .event-attendance-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

@media (max-width: 991.98px) {
  .single-event .event-attendance-details {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}

.single-event .event-attendance-details .details {
  padding: 30px;
  background-color: #f1f1f1;
  flex-basis: 55%;
}

@media (max-width: 991.98px) {
  .single-event .event-attendance-details .details {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.single-event .event-attendance-details .details p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}

.single-event .event-attendance-details .details .event-address {
  margin-bottom: 20px;
}

.single-event .event-attendance-details .details .calendar-link,
.single-event .event-attendance-details .details .google-link {
  margin: 0;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 300;
}

.single-event .event-attendance-details .details .calendar-link {
  margin-left: 13px;
}

.single-event .event-attendance-details .how-to-attend {
  border-left: 3px solid black;
  padding-left: 30px;
  flex-basis: 40%;
}

@media (max-width: 991.98px) {
  .single-event .event-attendance-details .how-to-attend {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.single-event .event-attendance-details .how-to-attend h2 {
  font-size: 28px;
  letter-spacing: 0;
  line-height: 33px;
  padding: 0;
  margin-bottom: 10px;
}

.single-event .event-image {
  width: 100%;
}

.single-event .event-content {
  width: 100%;
  border-bottom: 4px solid black;
  margin-bottom: 30px;
}

.single-event .event-content .wysiwyg {
  max-width: 890px;
  margin: 50px auto;
  background-color: #F4F4F4;
  padding: 50px;
}

.single-event .event-content .wysiwyg .button {
  display: table;
  text-align: center;
  margin: 20px auto;
  font-size: 18px;
  color: white;
  background-color: black;
  border: 1px solid white;
}

.single-event .event-content .wysiwyg .button:after {
  border: 1px solid black;
}

.single-event .event-content .wysiwyg p {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 20px;
}

.single-event .event-content .wysiwyg h2 {
  margin-bottom: 20px;
  padding-left: 0;
}

.single-event .event-content .sponsors h2 {
  border: none;
}

.social-share {
  margin-bottom: 60px;
  text-align: center;
}

.social-share h3 {
  font-size: 2.4rem;
  line-height: 2.9rem;
  text-align: center;
}

.social-share p {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
}

.social-share .social-icons {
  display: flex;
  justify-content: center;
}

.social-share .social-icons a {
  margin: 5px;
}

.social-share .social-icons a img {
  width: 40px;
}

.related-events {
  margin-bottom: 50px;
}

.related-events h4,
.related-events h3 {
  margin-bottom: 20px;
}

.covid-cta {
  padding: 30px;
  background-color: black;
  max-width: 1000px;
  margin: 0 auto 80px auto;
  position: relative;
}

@media (max-width: 767.98px) {
  .covid-cta {
    margin-bottom: 40px;
  }
}

.covid-cta:before {
  content: " ";
  background-image: url("/images/info.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -15px;
  left: -15px;
}

.covid-cta p {
  color: white;
  margin-bottom: 0;
}

.covid-cta p a {
  color: white;
  text-decoration: underline;
  font-weight: 500;
}

.covid-cta p a:hover {
  text-decoration: none;
}

.event-filters {
  margin-bottom: 50px;
}

.event-filters h2 {
  padding: 0;
}

.event-filters .results-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
}

@media (max-width: 767.98px) {
  .event-filters .results-container {
    flex-direction: column;
  }
}

.event-filters .show-past-input label {
  position: relative;
  padding-left: 30px;
  font-weight: 600;
  cursor: pointer;
}

.event-filters .show-past-input label:before {
  width: 24px;
  height: 23px;
  border: 3px solid black;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: " ";
}

.event-filters .show-past-input input {
  position: absolute;
  left: -10000px;
}

.event-filters .show-past-input input:checked + label:after {
  width: 8px;
  height: 13px;
  border-right: 3px solid black;
  border-bottom: 3px solid black;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(8px, -56%) rotate(33deg);
  content: " ";
}

.event-filters .results {
  font-size: 18px;
  font-weight: 500;
}

.event-filters .filters-wrapper .main-filter-content {
  border: none;
}

.event-filters .filters-container > div {
  flex-basis: 50%;
}

@media (max-width: 991.98px) {
  .event-filters .filters-container > div {
    flex-basis: 100%;
  }
}

.page .wysiwyg,
.page .text-with-sidebar > .content {
  margin-bottom: 80px;
  max-width: 100%;
}

.page .wysiwyg p,
.page .text-with-sidebar > .content p {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
}

.page .wysiwyg h2,
.page .wysiwyg h3,
.page .wysiwyg h4,
.page .wysiwyg h5,
.page .wysiwyg .artwork-cluster-card .details .artwork-name,
.artwork-cluster-card .details .page .wysiwyg .artwork-name,
.page .wysiwyg h6,
.page .text-with-sidebar > .content h2,
.page .text-with-sidebar > .content h3,
.page .text-with-sidebar > .content h4,
.page .text-with-sidebar > .content h5,
.page .text-with-sidebar > .content .artwork-cluster-card .details .artwork-name,
.artwork-cluster-card .details .page .text-with-sidebar > .content .artwork-name,
.page .text-with-sidebar > .content h6 {
  margin-top: 40px;
  padding-left: 30px;
}

@media (max-width: 991.98px) {
  .page .wysiwyg,
  .page .text-with-sidebar > .content {
    margin-bottom: 30px;
    max-width: 100%;
  }

  .page .wysiwyg p,
  .page .text-with-sidebar > .content p {
    padding-left: 0;
    padding-right: 0;
  }

  .page .wysiwyg h2,
  .page .wysiwyg h3,
  .page .wysiwyg h4,
  .page .wysiwyg h5,
  .page .wysiwyg .artwork-cluster-card .details .artwork-name,
  .artwork-cluster-card .details .page .wysiwyg .artwork-name,
  .page .wysiwyg h6,
  .page .text-with-sidebar > .content h2,
  .page .text-with-sidebar > .content h3,
  .page .text-with-sidebar > .content h4,
  .page .text-with-sidebar > .content h5,
  .page .text-with-sidebar > .content .artwork-cluster-card .details .artwork-name,
  .artwork-cluster-card .details .page .text-with-sidebar > .content .artwork-name,
  .page .text-with-sidebar > .content h6 {
    padding-left: 0;
  }
}

.sponsors {
  margin-bottom: 60px;
}

.sponsors h2 {
  border-bottom: 5px solid black;
  margin-bottom: 40px;
  padding-bottom: 10px;
}

.sponsors ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 10px;
}

@media (max-width: 767.98px) {
  .sponsors ul {
    padding: 0;
  }
}

.sponsors ul li {
  flex-basis: 33.333%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 10px;
  margin-bottom: 25px;
}

@media (max-width: 767.98px) {
  .sponsors ul li {
    flex-basis: 50%;
  }
}

.sponsors ul li a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sponsors ul li a .image-container {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 40px;
}

@media (max-width: 991.98px) {
  .sponsors ul li a .image-container {
    padding: 0 20px;
  }
}

@media (max-width: 767.98px) {
  .sponsors ul li a .image-container {
    padding: 0;
  }
}

.sponsors ul li a .image-container img {
  max-height: 150px;
  max-width: 100%;
  width: 100%;
}

.sponsors ul li a .sponsor-name {
  align-self: center;
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-style: italic;
}

.wysiwyg p {
  font-size: 21px;
  font-weight: 300;
  line-height: 34px;
  margin-bottom: 30px;
}

.wysiwyg p:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .wysiwyg p {
    font-size: 18px;
    line-height: 28px;
  }
}

.wysiwyg p a {
  text-decoration: underline;
  font-weight: 500;
}

.wysiwyg p a:hover {
  text-decoration: none;
}

.full-width-image {
  margin-bottom: 50px;
}

.full-width-image img {
  width: 100%;
  max-width: 100%;
}

.text-with-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 50px auto;
}

@media (max-width: 767.98px) {
  .text-with-sidebar {
    flex-direction: column;
    margin: 20px auto 50px auto;
  }
}

.text-with-sidebar .content {
  flex-basis: 65%;
}

@media (max-width: 767.98px) {
  .text-with-sidebar .content {
    margin-bottom: 20px;
  }
}

.text-with-sidebar .content p {
  font-size: 21px;
  line-height: 34px;
  font-weight: 300;
}

@media (max-width: 767.98px) {
  .text-with-sidebar .content p {
    font-size: 18px;
    line-height: 29px;
  }
}

.text-with-sidebar aside {
  flex-basis: 30%;
  background-color: #F4F4F4;
  padding: 24px 34px;
}

@media (max-width: 767.98px) {
  .text-with-sidebar aside {
    width: 100%;
  }
}

.text-with-sidebar aside p {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.info-block {
  margin: 50px auto;
}

.info-block .content {
  padding-left: 40px;
  border-left: 3px solid black;
  width: 100%;
}

.info-block .content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.info-block .content p:last-child {
  margin-bottom: 0;
}

.gallery {
  background-color: #F4f4f4;
  padding: 100px 0 70px 0;
  margin: 80px auto;
}

@media (max-width: 767.98px) {
  .gallery {
    padding: 30px 0;
    margin: 30px auto;
  }
}

.vue-gallery {
  display: flex;
}

.thumbnail-nav {
  flex-basis: 15%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  .thumbnail-nav {
    display: none;
  }
}

.thumbnail-nav button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  margin-bottom: 15px;
  border: 3px solid transparent;
}

.thumbnail-nav button img {
  max-width: 150px;
  max-height: 120px;
}

@media (max-width: 767.98px) {
  .thumbnail-nav button img {
    max-width: 15%;
    max-height: 50px;
  }
}

.thumbnail-nav button.active {
  border: 3px solid black;
}

.main-image {
  padding: 30px;
  width: 85%;
}

@media (max-width: 767.98px) {
  .main-image {
    width: 100%;
    padding: 0;
  }
}

.main-image .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  background-color: black;
}

@media (max-width: 767.98px) {
  .main-image .image-container {
    height: 350px;
  }
}

.main-image .image-container img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.image-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
}

.image-info p {
  margin-bottom: 0;
}

.image-info .current-count {
  padding: 0 15px;
  font-size: 18px;
  line-height: 22px;
  font-family: din-condensed, sans-serif;
}

.image-info .left-controls,
.image-info .right-controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image-info .left-controls button,
.image-info .right-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
  border: 2px solid transparent;
}

.image-info .left-controls button .arrow,
.image-info .right-controls button .arrow {
  width: 18px;
}

.image-info .caption {
  padding: 0 20px;
  flex-grow: 1;
  width: auto;
}

.image-info .caption p {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
}

.arrow {
  width: 25px;
}

.accordions {
  margin: 50px auto;
}

.accordions .accordion-item {
  margin-bottom: 20px;
}

.accordions .accordion-header {
  margin-bottom: 15px;
}

.accordions .accordion-header h3 {
  font-size: 36px;
  line-height: 44px;
  text-align: left;
  width: 100%;
  border-bottom: 2px solid black;
}

@media (max-width: 991.98px) {
  .accordions .accordion-header h3 {
    font-size: 28px;
    line-height: 1.3;
  }
}

.accordions .accordion-header button {
  border: 0;
  background: none;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
}

.accordions .accordion-header button span {
  display: block;
  font-size: 40px;
  padding-left: 30px;
}

.accordions .accordion-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.accordions .accordion-content div {
  padding-right: 10%;
}

.accordions .accordion-content .close-button {
  align-self: flex-end;
  margin-left: auto;
  margin-top: 25px;
  width: auto;
}

.accordions .accordion-content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.accordions .accordion-content p a {
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-decoration: underline;
}

.accordions .accordion-content p a:hover {
  text-decoration: none;
}

.curator-section {
  margin: 100px auto 70px auto;
  border-bottom: 1px solid black;
  padding-bottom: 70px;
}

.curator-section .inner-section {
  max-width: 100%;
  margin: 0 auto;
}

.curator-statement h2 {
  padding: 0;
}

.curator-statement p {
  font-size: 21px;
  font-weight: 300;
  line-height: 34px;
}

@media (max-width: 767.98px) {
  .curator-statement p {
    font-size: 18px;
    line-height: 29px;
  }
}

.curator {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 1199.98px) {
  .curator {
    align-items: flex-start;
  }
}

@media (max-width: 767.98px) {
  .curator {
    flex-direction: column;
  }
}

.curator .image-container {
  flex-basis: 35%;
}

@media (max-width: 767.98px) {
  .curator .image-container {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.curator .image-container img {
  width: 100%;
  max-width: 100%;
}

.curator h3 {
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 30px;
}

.curator .curator-details {
  padding: 0 40px;
  flex-basis: 65%;
}

@media (max-width: 767.98px) {
  .curator .curator-details {
    padding: 0;
  }
}

.curator .curator-details .tag {
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0;
}

.curator .curator-details .bio p {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 25px;
}

.item-carousel {
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 991.98px) {
  .item-carousel {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.item-carousel .container {
  max-width: 1340px;
}

@media (max-width: 1199.98px) {
  .item-carousel .container {
    max-width: 980px;
  }
}

@media (max-width: 991.98px) {
  .item-carousel .container {
    max-width: 740px;
  }
}

@media (max-width: 767.98px) {
  .item-carousel .container {
    max-width: 560px;
  }
}

.item-carousel .intro {
  padding-right: 40px;
}

.item-carousel .carousel-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .item-carousel .carousel-control {
    padding: 0 20px;
  }
}

@media (max-width: 767.98px) {
  .item-carousel .carousel-control {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}

.item-carousel .carousel-control .controls {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.item-carousel .carousel-control .controls button {
  margin-bottom: 0;
}

.item-carousel .carousel-control .controls p {
  font-size: 24px;
  line-height: 28px;
  font-family: din-condensed, sans-serif;
  margin-right: 10px;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .item-carousel .carousel-control .controls {
    margin-top: 15px;
    justify-content: center;
  }
}

.item-carousel .carousel-control h2 {
  padding: 0;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .item-carousel .carousel-control h2 {
    margin-bottom: 10px;
  }
}

.item-carousel .carousel-control button {
  border: none;
  border-radius: 0;
  margin: 0 0 10px 0;
  padding: 0;
}

.item-carousel .carousel-control button:first-child {
  margin-right: 10px;
}

.item-carousel .carousel-control .heading-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

@media (max-width: 767.98px) {
  .item-carousel .carousel-control .heading-group {
    justify-content: center;
    align-items: center;
  }
}

.item-carousel .carousel-control .heading-group h2 {
  padding: 0;
  margin-right: 20px;
}

@media (max-width: 767.98px) {
  .item-carousel .carousel-control .heading-group h2 {
    margin-right: 0;
    font-size: 32px;
  }
}

.item-carousel .carousel-control .heading-group button {
  border: 2px solid black;
  margin-right: 10px;
  margin-bottom: 0;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 500;
  background: white;
}

.item-carousel .carousel-control .heading-group button.active {
  color: white;
  background: black;
}

.item-carousel .artwork-card,
.item-carousel .event-card {
  margin: 0 15px 30px 15px;
  background: white;
}

@media (max-width: 767.98px) {
  .item-carousel .artwork-card,
  .item-carousel .event-card {
    margin: 0 0 30px 0;
  }
}

.item-carousel .artwork-card .address button {
  display: none;
}

.hide-on-desktop {
  display: flex !important;
}

@media (min-width: 992px) {
  .hide-on-desktop {
    display: none !important;
  }
}

.alternating-layout .alternating-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  position: relative;
}

.alternating-layout .alternating-block span[id] {
  position: absolute;
  top: -130px;
}

@media (max-width: 991.98px) {
  .alternating-layout .alternating-block {
    flex-direction: column;
    margin-bottom: 40px;
  }
}

.alternating-layout .alternating-block .image-container {
  flex-basis: 55%;
}

@media (max-width: 991.98px) {
  .alternating-layout .alternating-block .image-container {
    flex-basis: 100%;
  }
}

.alternating-layout .alternating-block .image-container img {
  width: 100%;
}

.alternating-layout .alternating-block .content {
  flex-basis: 45%;
  padding: 40px;
}

@media (max-width: 991.98px) {
  .alternating-layout .alternating-block .content {
    flex-basis: 100%;
    padding: 20px;
    width: 100%;
  }
}

.alternating-layout .alternating-block .content h2 {
  padding: 0;
  margin-bottom: 15px;
}

.alternating-layout .alternating-block .content .tour-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.alternating-layout .alternating-block .content .tour-info p {
  margin-bottom: 10px;
}

.alternating-layout .alternating-block .content .tour-info .ward {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.3;
  margin-right: 13px;
}

.alternating-layout .alternating-block .content .tour-info .sponsor {
  padding: 5px 10px;
  background: #F4F4F4;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 400;
}

.alternating-layout .alternating-block .content .tour-info .sponsor span {
  font-family: din-condensed, sans-serif;
}

.alternating-layout .alternating-block .content .curator-name {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.alternating-layout .alternating-block .content .curator-name span {
  font-family: din-condensed, sans-serif;
  font-size: 21px;
  line-height: 26px;
  margin-right: 5px;
}

.alternating-layout .alternating-block .content .excerpt {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 25px;
}

.alternating-layout .alternating-block .content a {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.alternating-layout .alternating-block .content a:hover {
  text-decoration: none;
}

.alternating-layout .alternating-block:nth-child(even) .image-container {
  order: 2;
}

@media (max-width: 991.98px) {
  .alternating-layout .alternating-block:nth-child(even) .image-container {
    order: 1;
  }
}

.alternating-layout .alternating-block:nth-child(even) .content {
  order: 1;
}

@media (max-width: 991.98px) {
  .alternating-layout .alternating-block:nth-child(even) .content {
    order: 2;
  }
}

.flex-cta .cta {
  align-items: flex-end;
  padding: 30px;
}

.flex-cta .cta .cta-text {
  padding: 0 30px 0 0;
}

.flex-cta .cta .cta-text p:last-child {
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .flex-cta .cta .cta-text {
    margin-bottom: 30px;
    width: 100%;
  }
}

.hero {
  width: 100%;
  margin: 0 auto 70px;
  max-width: 2000px;
  position: relative;
}

.hero video,
.hero img {
  width: 100%;
  max-width: 100%;
}

.hero.hub-hero img {
  height: 437px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 767.98px) {
  .hero.hub-hero img {
    height: 250px;
  }
}

.hero .hero-video {
  position: relative;
  width: 100%;
  padding-top: 42.9%;
  background: rgba(128, 128, 128, 0.5);
  overflow: hidden;
}

.hero .hero-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.hero .hero-video .video-controls {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.hero .hero-video .video-controls button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 650px) {
  .hero .hero-video .video-controls {
    top: 15px;
    right: 15px;
  }

  .hero .hero-video .video-controls button {
    width: 30px;
  }
}

.hero .hero-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
}

.hero .hero-title .on-placement {
  transform: translateY(20px);
}

.hero .hero-title .on-placement h1 {
  margin: 0;
  color: white;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 1px;
  display: inline;
  white-space: pre-wrap;
  padding: 5px 10px 5px 0;
  background-color: black;
  box-shadow: -16px 0 0 black;
}

@media (max-width: 767.98px) {
  .hero .hero-title .on-placement h1 {
    font-size: 30px;
  }
}

.hub-breadcrumb {
  position: absolute;
  margin: 0 auto;
  top: 20px;
  left: 0;
  right: 0;
  padding: 0 20px;
}

@media (max-width: 991.98px) {
  .hub-breadcrumb {
    padding: 0 10px;
    top: 10px;
  }
}

.hub-breadcrumb .inner-breadcrumb {
  background: black;
  width: fit-content;
  padding: 3px 10px 8px 10px;
  color: white;
}

.hub-breadcrumb .inner-breadcrumb img {
  width: 7px;
  height: 7px;
  display: inline-block;
  margin: 0 3px;
}

.hub-breadcrumb .inner-breadcrumb a {
  color: white;
  font-size: 14px;
  line-height: 1;
  text-decoration: underline;
}

.hub-breadcrumb .inner-breadcrumb a:hover {
  text-decoration: none;
}

.embed-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.embed-container .embed {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.embed-container .embed iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.matterport-showcase iframe {
  min-height: 240px;
  margin: 16px 0;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .matterport-showcase iframe {
    min-height: 360px;
  }
}

@media screen and (min-width: 992px) {
  .matterport-showcase iframe {
    height: 600px;
    max-width: 1068px;
    margin: 0 auto 15px;
  }
}

.transcript-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .transcript-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.transcript-container h2 {
  padding-left: 0;
  margin-bottom: 15px;
}

.self-hosted-video {
  position: relative;
}

.self-hosted-video video {
  width: 100%;
  max-width: 100%;
}

.social-accounts {
  margin-bottom: 20px;
}

.social-accounts .account {
  margin-bottom: 5px;
}

.social-accounts .account img {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.social-accounts .account a {
  text-decoration: underline;
  font-size: 18px;
  line-height: 1;
}

.social-accounts .account a:hover {
  text-decoration: none;
}

.hubs-listing .block {
  padding: 50px 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.hubs-listing .block span[id] {
  position: absolute;
  top: -130px;
}

@media (max-width: 991.98px) {
  .hubs-listing .block {
    flex-direction: column;
    padding: 30px 20px;
  }
}

.hubs-listing .block .image-container {
  flex-basis: 40%;
}

@media (max-width: 991.98px) {
  .hubs-listing .block .image-container {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.hubs-listing .block .image-container img {
  width: 100%;
}

.hubs-listing .block .content {
  flex-basis: 60%;
  padding: 0 0 0 50px;
}

@media (max-width: 991.98px) {
  .hubs-listing .block .content {
    flex-basis: 100%;
    padding: 0;
    width: 100%;
  }
}

.hubs-listing .block .content h2 {
  padding: 0;
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 1.2;
}

.hubs-listing .block .content .hub-location {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.hubs-listing .block .content .hub-location img {
  margin-right: 8px;
}

.hubs-listing .block .content .excerpt {
  font-weight: 300;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 22px;
}

.hubs-listing .block .content .hub-links span {
  padding: 0 10px;
}

.hubs-listing .block .content .popup-dates {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.hubs-listing .block .content .popup-dates .dashed-circle {
  width: 18.5px;
  height: 18.5px;
  border-radius: 500px;
  border: 2px dashed black;
  background: #EDEDED;
  display: inline-block;
  margin-right: 8px;
}

.hubs-listing .block .content .popup-dates span {
  margin-right: 8px;
  font-weight: bold;
}

.hubs-listing .block .content a {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.hubs-listing .block .content a:hover {
  text-decoration: none;
}

.hubs-listing .block:nth-child(odd) {
  background: #F4F4F4;
}

.curator-name {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.curator-name span {
  font-family: din-condensed, sans-serif;
  font-size: 21px;
  line-height: 26px;
  margin-right: 5px;
}

.popups-listing-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.popups-listing-container .popup-listing-intro {
  margin-bottom: 50px;
  border-top: 2px solid black;
  padding: 50px 30px 0 30px;
}

.popups-listing-container .popup-listing-intro h2 {
  padding: 0;
}

.hub-navigation {
  background: #F4F4F4;
  padding: 50px 20px;
}

@media (max-width: 991.98px) {
  .hub-navigation {
    padding: 20px;
  }
}

.hub-navigation h2 {
  text-align: center;
  width: 100%;
}

.hub-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.hub-navigation ul li {
  position: relative;
  background: white;
  border: 2px solid black;
  flex-basis: calc(33.333% - 40px);
  margin: 0 20px;
}

@media (max-width: 991.98px) {
  .hub-navigation ul li {
    flex-basis: calc(100% - 40px);
    margin-bottom: 30px;
  }
}

.hub-navigation ul li p {
  padding: 0 20px 15px 20px;
  margin: 0;
}

.hub-navigation ul li h3 {
  background: black;
  color: white;
  border: 2px solid white;
  padding: 5px 5px 8px 5px;
  text-align: center;
  transform: translate(-3px, -8px);
}

.hub-navigation ul li h3 a {
  color: white;
  font-family: sofia-pro, sans-serif;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.2px;
  line-height: 28px;
  text-align: center;
}

.hub-navigation ul li h3 a img {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 2px;
}

.hub-navigation ul li h3 a:after {
  content: " ";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.exhibition-callout {
  padding: 50px 20px;
}

@media (max-width: 991.98px) {
  .exhibition-callout {
    padding: 20px;
  }
}

.exhibition-callout .exhibition-details {
  display: flex;
  background: #F4F4F4;
}

@media (max-width: 1199.98px) {
  .exhibition-callout .exhibition-details {
    flex-direction: column;
  }
}

.exhibition-callout .exhibition-details .image-container {
  flex-basis: 55%;
  flex-shrink: 0;
}

@media (max-width: 1199.98px) {
  .exhibition-callout .exhibition-details .image-container {
    flex-basis: 100%;
  }
}

.exhibition-callout .exhibition-details .image-container img {
  width: 100%;
}

.exhibition-callout .exhibition-details .details {
  padding: 45px;
}

@media (max-width: 767.98px) {
  .exhibition-callout .exhibition-details .details {
    padding: 20px;
  }
}

.exhibition-callout .exhibition-details .details h3 {
  background: black;
  color: white;
  padding: 7px 25px 7px 25px;
  width: fit-content;
  font-size: 48px;
  line-height: 58px;
  transform: translateX(-90px);
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .exhibition-callout .exhibition-details .details h3 {
    transform: translate(-40px, -40px);
    margin-bottom: 0;
  }
}

.exhibition-callout .exhibition-details .details .excerpt {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 30px;
}

.exhibition-callout .exhibition-details .details .button {
  display: inline-block;
}

.exhibition-callout .exhibition-details .details .button img {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 2px;
}

.whats-on {
  background: #F4F4F4;
  padding: 50px 20px;
  margin: 20px auto;
}

.whats-on h2:first-child {
  text-align: center;
  margin-bottom: 10px;
}

.whats-on .item-carousel {
  padding-bottom: 25px;
  padding-top: 20px;
}

.whats-on .item-carousel h2 {
  font-size: 36px;
  line-height: 44px;
}

.programming-partners {
  margin: 50px auto;
}

@media (max-width: 767.98px) {
  .programming-partners {
    margin: 25px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
    align-items: flex-start;
  }
}

.programming-partners-container {
  max-width: 1100px;
  margin: 50px auto;
  overflow: auto;
}

.programming-partners-container .intro {
  padding: 0 30px;
  max-width: 800px;
}

@media (max-width: 767.98px) {
  .programming-partners-container .intro {
    padding: 0;
  }
}

.programming-partners-container .intro h2 {
  padding: 0;
}

.programming-partner {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

@media (max-width: 767.98px) {
  .programming-partner {
    flex-direction: column;
    margin-bottom: 25px;
    align-items: flex-start;
  }
}

.programming-partner .image-container {
  flex-basis: 30%;
}

@media (max-width: 767.98px) {
  .programming-partner .image-container {
    flex-basis: 100%;
  }
}

.programming-partner .image-container img {
  width: 100%;
}

.programming-partner h3 {
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 10px;
}

@media (max-width: 767.98px) {
  .programming-partner h3 {
    font-size: 25px;
  }
}

.programming-partner h3 a {
  text-decoration: underline;
}

.programming-partner h3 a:hover {
  text-decoration: none;
}

.programming-partner p {
  font-size: 16px;
  line-height: 22px;
}

.programming-partner .dates {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}

.programming-partner .content {
  padding: 0 30px;
  flex-basis: 70%;
}

@media (max-width: 767.98px) {
  .programming-partner .content {
    flex-basis: 100%;
    padding: 15px 0;
  }
}

.programming-partner:last-child {
  margin-bottom: 0;
}

.hub-info {
  margin: 50px auto;
}

.hub-info .location-image {
  width: 100%;
}

.hub-info .location-image img {
  width: 100%;
}

.hub-info .details {
  display: grid;
  padding: 30px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

@media (max-width: 991.98px) {
  .hub-info .details {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px 0;
  }
}

.hub-info .details aside {
  padding: 20px;
  background: #F4F4F4;
}

.hub-info .details aside p {
  font-size: 15px;
  line-height: 22px;
}

.hub-info .details .location div {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.hub-info .details .location a {
  display: block;
  margin-top: 10px;
  text-decoration: underline;
}

.hub-info .details .location a:hover {
  text-decoration: none;
}

.hub-info .details .location img {
  display: inline-block;
  margin-right: 8px;
}

.hub-info .details .location p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.hub-info .details .hours ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hub-info .details .hours ul li {
  font-size: 15px;
  line-height: 26px;
}

.hub-info .details .hours ul li span {
  display: inline-block;
  width: 30%;
  font-weight: 500;
}

.sub-navigation {
  background: #F4F4F4;
  padding: 50px 30px;
}

.sub-navigation .container {
  text-align: center;
}

.sub-navigation h2 {
  padding: 10px 20px;
  text-align: center;
  width: fit-content;
  background: black;
  color: white;
  display: block;
  margin: 0 auto;
}

.sub-navigation ul {
  display: flex;
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.sub-navigation ul li {
  padding: 0 20px;
  margin: 0;
  border-right: 1px solid grey;
}

.sub-navigation ul li a {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
}

.sub-navigation ul li a:hover {
  text-decoration: none;
}

.sub-navigation ul li:last-child {
  border: none;
}

.shopify-container {
  margin-bottom: 50px;
}

.flex-content .press-feed {
  background: #F4F4F4;
  padding: 40px 0;
}

.flex-content .press-feed h2 {
  padding: 0;
  margin-bottom: 20px;
}

.flex-content .press-feed .intro-container {
  margin-bottom: 40px;
}

.flex-content .press-feed .press-link {
  padding: 15px;
}

.flex-content .press-feed .press-link:last-child {
  margin-bottom: 0;
}

.flex-content .see-all-link {
  margin-top: 30px;
  text-decoration: underline;
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}

.flex-content .see-all-link:hover {
  text-decoration: none;
}

.gallery-lightbox {
  margin: 50px auto;
}

.gallery-lightbox .intro h2,
.gallery-lightbox .intro h3,
.gallery-lightbox .intro h4 {
  padding: 0;
}

.gallery-images-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin: 40px auto;
}

.gallery-images-wrapper .image-button {
  padding-bottom: 20%;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black;
  background-position: center center;
  width: 100%;
  flex-basis: calc(20% - 10px);
  border: 3px solid white;
  transition: all 0.2s linear;
}

.gallery-images-wrapper .image-button:hover {
  border-color: black;
}

@media (max-width: 991.98px) {
  .gallery-images-wrapper .image-button {
    flex-basis: calc(33% - 10px);
    padding-bottom: 33%;
  }
}

@media (max-width: 767.98px) {
  .gallery-images-wrapper .image-button {
    flex-basis: calc(50% - 10px);
    padding-bottom: 50%;
  }
}

.art-for-sale-listing {
  margin-bottom: 100px;
}

.art-for-sale #activeArtForSaleCategories-container fieldset {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  align-items: flex-start;
}

@media (max-width: 991.98px) {
  .art-for-sale #activeArtForSaleCategories-container fieldset {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .art-for-sale #activeArtForSaleCategories-container fieldset {
    grid-template-columns: repeat(1, 1fr);
  }
}

.art-for-sale-card {
  display: flex;
  margin-bottom: 20px;
  padding: 25px;
  position: relative;
}

@media (max-width: 767.98px) {
  .art-for-sale-card {
    flex-direction: column;
  }
}

.art-for-sale-card .store-card-image {
  width: 200px;
  height: 200px;
  margin-right: 25px;
  flex-shrink: 0;
}

@media (max-width: 767.98px) {
  .art-for-sale-card .store-card-image {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }
}

.art-for-sale-card .store-card-image img {
  width: 100%;
}

.art-for-sale-card .details {
  padding-right: 110px;
}

@media (max-width: 767.98px) {
  .art-for-sale-card .details {
    padding-right: 0;
  }
}

.art-for-sale-card .details h3 {
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 5px;
}

.art-for-sale-card .details h4 {
  font-size: 18px;
  line-height: 24px;
}

.art-for-sale-card .details p {
  font-size: 16px;
  line-height: 22px;
}

.art-for-sale-card .details a {
  position: absolute;
  right: 25px;
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .art-for-sale-card .details a {
    position: relative;
    align-items: center;
    justify-content: flex-start;
    right: unset;
    top: unset;
    margin-top: 20px;
  }
}

.art-for-sale-card .details a img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}

.art-for-sale-card .details ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.art-for-sale-card .details ul li {
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  padding: 5px 10px;
  background: #E2E2E2;
}

.art-for-sale-card:nth-child(2n) {
  background: #F4F4F4;
}

.art-for-sale-pagination,
.pagination {
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  width: 100%;
  height: 56px;
}

.art-for-sale-pagination .pagination-button,
.pagination .pagination-button {
  position: absolute;
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0 20px;
}

.art-for-sale-pagination .pagination-button:hover,
.pagination .pagination-button:hover {
  text-decoration: none;
}

.art-for-sale-pagination .prev-button,
.pagination .prev-button {
  left: 0;
}

.art-for-sale-pagination .prev-button:before,
.pagination .prev-button:before {
  content: url("/images/left-arrow.svg");
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  top: 2px;
}

.art-for-sale-pagination .next-button,
.pagination .next-button {
  right: 0;
}

.art-for-sale-pagination .next-button:after,
.pagination .next-button:after {
  content: url("/images/right-arrow.svg");
  width: 10px;
  height: 10px;
  position: absolute;
  right: 0;
  top: 2px;
}

.art-for-sale-pagination ul,
.pagination ul {
  list-style-type: none;
  position: absolute;
  inset: auto;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  width: fit-content;
}

.art-for-sale-pagination ul li button,
.art-for-sale-pagination ul li a,
.pagination ul li button,
.pagination ul li a {
  background: transparent;
  border: none;
  width: 30px;
  height: 30px;
  line-height: 1;
  text-align: center;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.art-for-sale-pagination ul li button.active,
.art-for-sale-pagination ul li a.active,
.pagination ul li button.active,
.pagination ul li a.active {
  border: 2px solid black;
}

.art-for-sale-filter-message-container {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  align-items: flex-start;
}

.sort-select {
  display: flex;
  align-items: center;
  margin-left: 40px;
  flex-shrink: 0;
}

.sort-select select {
  padding: 5px 35px 5px 10px;
  border: 2px solid black;
  border-radius: 0;
  appearance: none;
  position: relative;
  background: transparent;
}

.sort-select .select-wrapper {
  position: relative;
}

.sort-select .select-wrapper:after {
  content: url("/images/down-arrow.svg");
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 12%;
}

.sort-select label {
  margin-right: 15px;
}

.resource-library {
  margin: 50px auto 80px auto;
  padding: 0 30px;
}

@media (max-width: 991.98px) {
  .resource-library {
    padding: 0;
    margin: 30px auto 40px auto;
  }
}

.resource-library .styled-button {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 14px 30px 16px 30px;
}

.resource-library .no-items {
  padding-left: 0;
}

.resource-library .filters-section {
  border: none;
  padding: 0;
  margin: 0 0 30px 0;
  width: 100%;
  background: transparent;
}

.resource-library .on-demand-filter-message-container {
  margin-top: 20px;
}

@media (max-width: 767.98px) {
  .resource-library .filters-container {
    flex-direction: column;
  }
}

.resource-library .filters-container .checkbox-radio-filter {
  flex-basis: 50%;
}

@media (max-width: 767.98px) {
  .resource-library .filters-container .checkbox-radio-filter {
    flex-basis: 100%;
    width: 100%;
  }
}

.single-resource .credit {
  font-style: italic;
  color: #6c6c6c;
  padding-left: 30px;
}

@media (max-width: 991.98px) {
  .single-resource .credit {
    padding-left: 0;
  }
}

.single-resource .description {
  padding-left: 30px;
  max-width: 1000px;
}

.single-resource .description h2,
.single-resource .description h3,
.single-resource .description h4 {
  padding: 0;
}

@media (max-width: 991.98px) {
  .single-resource .description {
    padding-left: 0;
  }
}

.single-resource .self-hosted-document a {
  display: inline-flex;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .single-resource .self-hosted-document a {
    margin-left: 0;
  }
}

.resource-listing-container {
  width: 100%;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  align-items: flex-start;
}

@media (max-width: 991.98px) {
  .resource-listing-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .resource-listing-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.resource-carousel {
  background-color: #F1F1F1;
}

.resource-carousel .resource-card {
  margin: 0 10px;
}

.resource-card {
  border: 3px solid black;
  border-top: 12px solid black;
  padding-bottom: 20px;
  margin-bottom: 30px;
  background-color: white;
}

.resource-card h3 {
  font-size: 2rem;
}

.resource-card a {
  text-decoration: underline;
}

.resource-card a:hover {
  text-decoration: none;
}

.resource-card .credit {
  font-style: italic;
  padding: 0 20px;
  color: grey;
  margin: 10px 0 0 0;
  font-size: 15px;
}

.resource-card .embed-container {
  margin: 0;
}

.resource-card .self-hosted-audio {
  padding: 0 20px;
  margin-top: 20px;
}

.resource-card .self-hosted-audio audio {
  width: 100%;
}

.resource-card .type {
  font-family: din-condensed, sans-serif;
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 22px;
  margin-bottom: 10px;
  display: block;
}

.resource-card .type span {
  background-color: #F4F4F4;
  padding: 7px 20px;
  display: inline-block;
}

.resource-card .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background: black;
  height: 255px;
}

.resource-card .image-container img {
  max-width: 100%;
  max-height: 100%;
}

.resource-card h2 {
  font-size: 28px;
  letter-spacing: 0;
  line-height: 34px;
  padding: 0 20px;
  margin: 0 0 10px 0;
}

.resource-card .excerpt {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  padding: 0 20px;
  margin-bottom: 0;
}

.resource-card.without-image .type {
  transform: none;
  margin-top: 15px;
}

.external-link {
  position: relative;
}

.external-link:after {
  position: absolute;
  content: url("/images/external-alt.svg");
  right: -28px;
  width: 18px;
  height: 18px;
}

.download-link {
  position: relative;
}

.download-link:after {
  position: absolute;
  content: url("/images/download-document.svg");
  right: -28px;
  width: 18px;
  height: 18px;
}

.press-link {
  padding: 30px 30px;
  list-style-type: none;
}

@media (max-width: 767.98px) {
  .press-link {
    padding: 20px 15px;
  }
}

.press-link .press-title {
  padding: 0;
}

.press-link div {
  padding-left: 70px;
  position: relative;
}

.press-link div a {
  text-decoration: underline;
}

.press-link div a:hover {
  text-decoration: none;
}

.press-link div:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  display: block;
  content: " ";
}

.press-link div.video:before {
  background-image: url("/images/video.svg");
}

.press-link div.document:before,
.press-link div.article:before {
  background-image: url("/images/document.svg");
}

.press-link div.audio:before {
  background-image: url("/images/audio.svg");
}

.press-link div.press-release:before {
  background-image: url("/images/press-release.svg");
}

.press-link div .press-date {
  margin-bottom: 5px;
}

.flex-content .press-link:nth-child(even),
.press-links .press-link:nth-child(even) {
  background-color: #F4F4F4;
}

.flex-content .press-link:last-child,
.press-links .press-link:last-child {
  margin-bottom: 80px;
}

@media (max-width: 767.98px) {
  .press-link-container {
    padding: 0;
  }
}

.press-links .flex-content {
  margin-bottom: 50px;
}

.associated-hub {
  margin-bottom: 50px;
  margin-top: 70px;
  background-color: #F4F4F4;
  padding: 0;
}

.single-hub .sponsors h2,
.single-exhibition .sponsors h2 {
  border: none;
}

.single-hub .resource-carousel,
.single-exhibition .resource-carousel {
  background: transparent;
}

.single-hub .embed-container h2,
.single-exhibition .embed-container h2 {
  text-align: center;
}

.single-hub .wysiwyg,
.single-exhibition .wysiwyg {
  max-width: 900px;
  margin-top: 80px;
  margin-bottom: 70px;
}

.single-hub .wysiwyg h2,
.single-exhibition .wysiwyg h2 {
  padding: 0;
}

.single-hub .text-with-sidebar h2,
.single-exhibition .text-with-sidebar h2 {
  padding-left: 0;
}

.single-hub .self-hosted-video,
.single-exhibition .self-hosted-video {
  margin-bottom: 50px;
}

.single-hub .self-hosted-video:before,
.single-exhibition .self-hosted-video:before {
  content: " ";
  height: 25%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #F4F4F4;
  z-index: -1;
}

.single-hub .social-share,
.single-exhibition .social-share {
  border-bottom: 4px solid black;
  padding-bottom: 50px;
}

.hubs .wysiwyg {
  margin-bottom: 50px;
  padding-left: 30px;
  max-width: 1000px;
}

@media (max-width: 991.98px) {
  .hubs .wysiwyg {
    margin-bottom: 50px;
    padding-left: 0;
  }
}

.hubs .wysiwyg h2 {
  padding-left: 0;
}

.hubs .info-block {
  padding-left: 50px;
  margin-top: 0;
}

@media (max-width: 991.98px) {
  .hubs .info-block {
    padding-left: 10px;
  }
}

.hubs .info-block .content {
  max-width: 1000px;
}

@media (max-width: 991.98px) {
  .hubs .info-block .content {
    padding-left: 20px;
  }
}

.blog-categories {
  background: #F4F4F4;
  color: black;
  padding: 5px 15px 3px 15px;
  display: inline-block;
  font-size: 18px;
  letter-spacing: 0.3px;
  font-family: din-condensed, sans-serif;
}

.blog-details {
  padding-top: 20px;
}

.blog-details .blog-details-text {
  padding: 20px;
}

.blog-details .blog-details-text .excerpt {
  font-size: 18px;
}

.blog-details .blog-details-text .author,
.blog-details .blog-details-text .date {
  font-size: 16px;
  font-weight: 300;
}

.blog-details .blog-details-text .author span,
.blog-details .blog-details-text .date span {
  font-family: din-condensed, sans-serif;
}

.blog-card {
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 20px;
}

.blog-card a {
  color: white;
  text-decoration: underline;
}

.blog-card a:hover {
  text-decoration: none;
}

.blog-card h2 {
  padding-left: 0;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: 0;
  margin-bottom: 15px;
}

.blog-card .blog-image {
  border: 5px solid black;
}

.blog-card .blog-image img {
  width: 100%;
}

.blog-card.featured {
  flex-direction: row;
  width: calc(100% - 20px);
  margin: 40px auto 60px auto;
}

.blog-card.featured .blog-image {
  flex-basis: 60%;
  border: none;
}

.blog-card.featured .blog-details {
  flex-basis: 40%;
}

.blog-card.featured .blog-details .blog-details-text {
  padding: 35px 50px 40px 50px;
}

@media (max-width: 991.98px) {
  .blog-card.featured {
    flex-direction: column;
  }

  .blog-card.featured .blog-details .blog-details-text {
    padding: 20px;
  }
}

.blog-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}

.blog-listing .blog-card {
  flex-basis: calc(33.333333% - 20px);
  margin: 10px 10px 20px 10px;
}

@media (max-width: 991.98px) {
  .blog-listing .blog-card {
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 767.98px) {
  .blog-listing .blog-card {
    flex-basis: calc(100% - 20px);
  }
}

.blog-filter-message-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.sort-buttons {
  margin-left: 30px;
  flex-basis: 40%;
}

.sort-buttons p {
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: 500;
}

@media (max-width: 991.98px) {
  .sort-buttons {
    width: 100%;
    order: 2;
    margin-left: 0;
    margin-bottom: 15px;
    flex-basis: 100%;
  }
}

.blog .back-link {
  margin-top: 50px;
}

.blog .blog-details {
  background-color: black;
  color: white;
  transform: translateY(-80px);
  width: 80%;
  margin: 0 auto -80px auto;
}

.blog .blog-details p {
  margin-bottom: 10px;
}

.blog .blog-details h1 {
  padding: 0;
  margin: 0 0 30px 0;
}

.blog .blog-details .blog-details-text {
  padding: 25px 50px;
}

.blog .blog-details .blog-details-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.blog .blog-details .blog-details-flex div {
  flex-basis: 50%;
}

.blog .blog-details .author,
.blog .blog-details .date {
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
}

.blog .blog-details .author span,
.blog .blog-details .date span {
  font-size: 21px;
  letter-spacing: 0.25px;
}

.blog .blog-details .credit {
  font-size: 15px;
  line-height: 23px;
  text-align: right;
}

.blog .image-container {
  width: 100%;
}

.blog .image-container img {
  width: 100%;
}

.blog .author-section {
  width: 80%;
  margin: 0 auto 50px auto;
}

.blog .author-section .curator {
  margin-top: 0;
}

.blog .flex-content {
  width: 75%;
  margin: 0 auto 80px auto;
}

.blog .flex-content .wysiwyg {
  margin: 50px auto;
}

.blog .flex-content .wysiwyg p,
.blog .flex-content .wysiwyg ol,
.blog .flex-content .wysiwyg ul,
.blog .flex-content .wysiwyg h2,
.blog .flex-content .wysiwyg h3,
.blog .flex-content .wysiwyg h4,
.blog .flex-content .wysiwyg h5,
.blog .flex-content .wysiwyg .artwork-cluster-card .details .artwork-name,
.artwork-cluster-card .details .blog .flex-content .wysiwyg .artwork-name,
.blog .flex-content .wysiwyg h6 {
  width: 95%;
  padding: 0;
  margin: 0 auto 30px auto;
}

.blog .flex-content .wysiwyg h2,
.blog .flex-content .wysiwyg h3,
.blog .flex-content .wysiwyg h4,
.blog .flex-content .wysiwyg h5,
.blog .flex-content .wysiwyg .artwork-cluster-card .details .artwork-name,
.artwork-cluster-card .details .blog .flex-content .wysiwyg .artwork-name,
.blog .flex-content .wysiwyg h6 {
  margin-top: 50px;
}

.blog .flex-content .wysiwyg blockquote {
  border-left: 4px solid black;
}

.blog .flex-content .wysiwyg blockquote p {
  font-weight: 500;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 1.7;
}

.blog .flex-content p,
.blog .flex-content li {
  font-size: 18px;
  line-height: 1.8;
}

.single-tour h1,
.single-tour h2 {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 20px;
}

.single-tour .tour-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 50px;
}

@media (max-width: 991.98px) {
  .single-tour .tour-header {
    flex-direction: column;
    margin-top: 40px;
  }
}

.single-tour .tour-header p {
  margin-bottom: 5px;
}

.single-tour .tour-header .tour-sponsor {
  margin-bottom: 30px;
}

.single-tour .tour-header .details {
  flex-basis: 55%;
}

.single-tour .tour-header .location {
  padding-left: 35px;
  position: relative;
}

.single-tour .tour-header .location img {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
}

.single-tour .tour-header .neighbourhood {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
}

.single-tour .tour-header .tour-link {
  background: #F4F4F4;
  text-align: center;
  width: 100%;
  padding: 35px 40px 50px 40px;
  border-top: 5px solid black;
  flex-basis: 40%;
}

@media (max-width: 991.98px) {
  .single-tour .tour-header .tour-link {
    margin-top: 45px;
  }
}

.single-tour .tour-header .tour-link a {
  margin: 0 5px;
  text-decoration: underline;
}

.single-tour .tour-header .tour-link a:hover {
  text-decoration: none;
}

.single-tour .tour-header strong {
  font-family: din-condensed, sans-serif;
  font-size: 21px;
}

.single-tour .about-tour {
  display: flex;
  margin-bottom: 60px;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .single-tour .about-tour {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.single-tour .about-tour > div {
  flex-basis: 50%;
  flex-shrink: 1;
}

.single-tour .about-tour > div img {
  max-width: 100%;
}

.single-tour .about-tour .tour-description {
  padding: 40px;
}

@media (max-width: 991.98px) {
  .single-tour .about-tour .tour-description {
    padding: 20px 10px;
  }
}

.single-tour .social-share {
  margin-top: 50px;
  border-top: 4px solid black;
  padding-top: 40px;
}

.tour-card {
  border: 2px solid black;
  border-top: 12px solid black;
  width: 100%;
}

.tour-card img {
  max-width: 100%;
  width: 100%;
}

.tour-card .tour-card-content {
  padding: 20px;
  position: relative;
}

.tour-card .tour-name {
  padding-top: 15px;
}

.tour-card .tour-name a {
  text-decoration: underline;
}

.tour-card .tour-name a:hover {
  text-decoration: none;
}

.tour-card .tour-sponsor {
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 15px;
}

.tour-card .tour-sponsor span {
  font-family: din-condensed, sans-serif;
  font-size: 17px;
}

.tour-card .neighbourhood {
  background: #f4f4f4;
  position: absolute;
  top: -20px;
  left: 0;
  padding: 5px 20px 7px 20px;
}

.tour-card .neighbourhood p {
  margin: 0;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.tour-listing {
  margin: 50px auto;
}

.tour-listing .tour-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
}

@media (max-width: 991.98px) {
  .tour-listing .tour-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .tour-listing .tour-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

.tours-page h1 {
  padding: 0;
}

.tours-page .tour-header {
  max-width: 900px;
}

.tours-page .tour-header p {
  font-size: 21px;
}

.tours-page .tour-filter-message-container {
  margin-bottom: 30px;
}

.tours-page .filters-wrapper {
  margin-bottom: 30px;
}

.tours-page .filters-wrapper .filters-container {
  grid-template-columns: repeat(1, 1fr);
}

.tours-page .filters-wrapper .filter-container {
  padding: 20px 40px;
}

@media (max-width: 991.98px) {
  .tours-page .filters-wrapper .filter-container {
    padding: 15px;
  }
}

.tours-page .filters-wrapper .filter-controls {
  display: none;
}

.tour-footer {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 0;
  background: #f4f4f4;
}

.tour-footer h2 {
  padding-left: 0;
}

.tour-footer .download-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 50px;
  margin-top: 50px;
  grid-gap: 60px;
}

@media (max-width: 991.98px) {
  .tour-footer .download-buttons {
    display: flex;
    grid-gap: 20px;
  }
}

@media (max-width: 767.98px) {
  .tour-footer .download-buttons {
    flex-direction: column;
  }
}

.tour-footer .download-buttons a {
  display: inline-block;
  overflow: auto;
}

.tour-footer .download-buttons a img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

@media (max-width: 991.98px) {
  .tour-footer .download-buttons a {
    flex-basis: 33%;
  }
}

@media (max-width: 767.98px) {
  .tour-footer .download-buttons a {
    text-align: center;
  }

  .tour-footer .download-buttons a img {
    max-width: 50%;
  }
}

.tour-footer p a {
  text-decoration: underline;
}

.error-screen {
  background: black;
  color: white;
  padding: 0;
}

.error-screen #content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.error-screen #content h1,
.error-screen #content h2,
.error-screen #content h3,
.error-screen #content h4 {
  padding: 0;
  margin-bottom: 20px;
  margin-top: 30px;
}

