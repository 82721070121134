.text-with-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 50px auto;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin: 20px auto 50px auto;
  }
  .content {
    flex-basis: 65%;
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
    p {
      font-size: 21px;
      line-height: 34px;
      font-weight: $light-weight;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
  aside {
    flex-basis: 30%;
    background-color: #F4F4F4;
    padding: 24px 34px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    p {
      font-size: 18px;
      font-weight: $light-weight;
      line-height: 28px;
    }
  }
}